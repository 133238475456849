// App.js
import React, { Component } from 'react';
import { Route, Redirect, withRouter, Link, Switch, BrowserRouter as Router } from 'react-router-dom'
import Modal from 'react-modal';
import { connect } from "react-redux";
import axios from 'axios';
//react reveal is for animations
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';
import Flash from 'react-reveal/Flash';
import Pulse from 'react-reveal/Pulse';
import Shake from 'react-reveal/Shake';
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import Spin from 'react-reveal/Spin';
import Wobble from 'react-reveal/Wobble';

// importing actions for react-redux
import {save_user_account_to_store, save_un_done_questions_to_store,  save_done_questions_id_to_store,
          change_should_show_question_setup_page, save_user_profile_to_store, save_database_questions_id_to_store,
          save_performance_summary_to_store, change_has_watched_tutorial_video_status,
          set_convert_account_to_permanent_page_data } from './Actions';


import { school_district_list, school_list, city_list, year, subject, performance_data  } from './extra_script';          

//React-Toastify is a better alternative for react-notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CKEditor from 'ckeditor4-react';
// the import below is for google analythics
import ReactGA from 'react-ga';
// the import above is for google analythics

// the code below is for attaching google ads conversion to the header part of my html to monitor my ads performance
import {Helmet} from "react-helmet";
// the code below is for attaching google ads conversion to the header part of my html to monitor my ads performance

// the import below is for pdf viewer to view pdf   https://pdfjs.express/blog/how-to-build-pdf-viewer-cordova-pdfjs

import PdfJsViewer from './PdfJsViewer'


const version = '1.1.1'
//const base_url= "http://localhost:8000/api/";

var base_url= base_url= "http://localhost:8000/api/";


let google_analytics = null

let app_is_in_background = false
let pdf_has_finished_downloading = [false,'']

if(window.location.hostname!=='localhost'){
 
       base_url= "https://backend.learnershub.co.za/api/";

         //the react ga is for google analytics tracking
        ReactGA.initialize('UA-184169787-1',{ debug:false, gaOptions: { siteSpeedSampleRate: 100} },)
        //reactga above is for google analytics tracking

       google_analytics = (path)=>{ ReactGA.pageview(path)}


      
}






let check_cordova = false
let cordova_file_prefix =''
if(typeof window.cordova != "undefined"){
          cordova_file_prefix = "/android_asset/www/"
          check_cordova = true


          
         //the react ga is for google analytics tracking
        ReactGA.initialize('UA-184169787-1',{ debug:false, gaOptions: { siteSpeedSampleRate: 100} },)
        //reactga above is for google analytics tracking

       google_analytics = (path)=>{ ReactGA.pageview(path)}


       //inserted to know when app has been put to background to stop measuring time on site

       document.addEventListener("pause", onPause, false);
       document.addEventListener("resume", onResume, false);


       function onPause() {
                // Handle the resume event
                app_is_in_background = true
              
        }
       function onResume() {
                // Handle the resume event
                app_is_in_background = false
               
        }
         

}

toast.configure({
  autoClose: 3000,
  draggable: false,
  //etc you get the idea
});


let customStyles = {}
if(window.innerWidth<=500){ 
  customStyles = {content : { width:'100%', height:'94%', transform : 'translate(-11%, 0%)'}};
}

if(window.innerWidth>=500){ 
  customStyles = {content : { width:'100%', height:'90%', transform : 'translate(-6.5%, -1%)'}};
}

if(window.innerWidth>=900){ 
  customStyles = {};
}






class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {

                    user_is_logged_in:false,
                    email_is_confirmed: true,                               
                    get_question_from_database_control:true,
                    internet_is_available : true,
                    count:0,
                    internet_test_url:'https://api.ipify.org/?format=json',
                    
                    maintenance_status: false,
                    update_mobile_app:false,

                 
                   
                                        
                };
  }

  componentDidMount() {
     
       setInterval(this.one_second_tasks, 1000);
       setInterval( this.two_seconds_tasks,3000)
       setInterval(this.ten_seconds_task,10000)
       setInterval(this.thirty_seconds_taks, 30000);
       setInterval(this.one_minute_tasks, 60000);
       setInterval(this.five_minute_tasks, 300000);
       setInterval(this.ten_minutes_tasks, 600000);

      
       
       this.check_and_set_performance_local_storage_value()
       this.get_version_from_db()
       
       this.props.save_performance_summary_to_store({performance_data})
       localStorage.setItem("realtime_question_performance",JSON.stringify([]));
       if(localStorage.getItem("has_watched_tutorial_video") === 'true'){ this.props.change_has_watched_tutorial_video_status(true) }
      
      
  }


 
  one_second_tasks=()=>{


    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background ){return}


    this.check_email_confirmation()
    this.get_all_questions_id_from_db()
    this.get_questions_from_db_and_save_to_store()
    this.get_user_account_and_profile_details()
    this.check_login();
  }

  two_seconds_tasks=()=>{

    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background  ){return}

       

  }

  ten_seconds_task=()=>{

    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background ){return}

    this.submit_question_performance_to_db()
    this.populate_realtime_question_performance()
    this.get_performance_summary_from_db_and_save_to_store()
    this.check_internet() 
    this.check_last_login_date()
   
  }

  thirty_seconds_taks=()=>{

    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background ){return}

   
    if(this.state.maintenance_status===true){this.check_maintenance_status()}

    if( this.state.update_mobile_app ===true){this. get_version_from_db()}

    
    
  }

  
  one_minute_tasks=()=>{
    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background  ){return}

    this.save_time_on_app_to_db()

   

  

    
    
  }

  five_minute_tasks=()=>{
      //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background ){return}

    
    this.get_version_from_db()
   
    

  }

  ten_minutes_tasks=()=>{

    //stops processes running on mobile when app is mimimised to background
    if(app_is_in_background){return}


    this.set_and_show_convert_account_to_permanent_page()

  }

  save_time_on_app_to_db=()=>{
     
    if(localStorage.login_token === undefined){ return}

    if(!document.hasFocus()){return}// to logging time whn users are doing something else while window is open on desktop

         
          let url= base_url + 'save-time-on-app'

          const token = localStorage.getItem("login_token");
                        
          let token_header= { Authorization :  'Token '+token}     

         

            axios({
              method: 'get',
              url:url,
              headers:token_header
                       
            })
            
            .then(res => {                          
                               
                                  
                                                              
              })

            .catch((error)=> {

                                  
                                                              
            })

    


  }


  check_internet=()=>{

    this.setState({count: this.state.count +1})

                      let url= base_url + 'check-internet'

                        axios({
                          method: 'get',
                          url: this.state.internet_test_url,
                          timeout:9000
                          
                        })
                        
                        .then(res => {                          
                                                  this.setState({internet_is_available: true});     
                                                  this.setState({internet_test_url: 'https://api.ipify.org/?format=json'}) 
                                                                           
                          })

                        .catch((error)=> {

                                        

                                        if(this.state.internet_test_url === url ){
                                          this.setState({internet_is_available: false});
                                        }  


                                        if(this.state.internet_test_url === 'https://api.ipify.org/?format=json'){
                                                this.setState({internet_test_url: url}); 
                                         }
                                              
                                                                          
                        })


          
          
        
  }
        
  set_and_show_convert_account_to_permanent_page=()=>{

             if(this.state.user_is_logged_in ===true ){

                        if (this.props.user_account.platform === "anonymous"){

                            this.props.set_convert_account_to_permanent_page_data(true)

                        }
                  
              }


  }          
                
  
  get_version_from_db=()=>{

   
    //if(check_cordova === true){return null}

    let url= base_url + 'get-current-student-app-version-from-db'
  
    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
    

    axios({
      method: 'get',
      url: url,
      headers:token_header,
     
    })
    
      .then(res => {
                                     
              if(version!==res.data && check_cordova !== true){
               
                        alert("Your Browser currently has an old version, Please press ok to reload a new version from the Server. Thanks");
                                
                        window.location.reload(true)

              }

              if(version!==res.data && check_cordova === true){


                        this.setState({update_mobile_app: true});     


              }
           

      })

      .catch((error)=> {
              // handle error
             
            
      })


  }

  

  check_maintenance_status=()=>{

       if(this.state.internet_is_available === false){return null}


    let url= base_url + 'maintenance-status'
    
    
    axios({
      method: 'get',
      url: url,
      validateStatus: () => true
    })
    
      .then(res => {

          
           
                                     
            if (res.status !== 200 && this.state.internet_is_available === true){ 

                 
                        this.setState({maintenance_status:true})
                        localStorage.removeItem("login_token")
                        this.props.save_user_account_to_store(null)
                        this.props.save_user_profile_to_store(null)
                        
            }
            else{ 

             
              this.setState({maintenance_status:false})
            }

      })

      .catch((error)=> {
       
           if (error.request  && this.state.internet_is_available === true){
                   
                        this.setState({maintenance_status:true})
                         localStorage.removeItem("login_token")
                        this.props.save_user_account_to_store(null)
                        this.props.save_user_profile_to_store(null)
           }
            
      })


  }
  
  check_last_login_date=()=>{

    if(this.state.user_is_logged_in ===false ){return}
        
          var now = new Date();
          var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          var startOfDayTimestamp = startOfDay / 1000; 
          
         
          let last_login_day_timestamp =  Date.parse(this.props.user_account.last_login)/1000;
          
         

          if(startOfDayTimestamp>last_login_day_timestamp ){

          
            this.set_last_login_date_to_today_in_db()
            
          }
   
 
  }


  set_last_login_date_to_today_in_db=()=>{

    let url= base_url + 'update-user-last-login-date-to-today'
  
    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
    

    axios({
      method: 'get',
      url: url,
      headers:token_header,
     
    })
    
      .then(res => {
        //I am removeing Z that always come with django database time
         let response = res.data.replace ("Z",'')
         let user_account = this.props.user_account
         user_account.last_login = response
         this.props.save_user_account_to_store(user_account)                      
             
      })

      .catch((error)=> {
              // handle error
             
            
      })


  }

  populate_realtime_question_performance=()=>{

    let question_performance = JSON.parse(localStorage.getItem("question_performance"));
    let realtime_question_performance = JSON.parse(localStorage.getItem("realtime_question_performance"));
   

      if(question_performance.length >1 && realtime_question_performance.length === 0 ){
            let last_performance = question_performance.pop()
            localStorage.realtime_question_performance = JSON.stringify(question_performance)
            localStorage.question_performance = JSON.stringify([last_performance])
       }



  }

  submit_question_performance_to_db=()=>{
              
                let realtime_question_performance = JSON.parse(localStorage.getItem("realtime_question_performance"));
                if(realtime_question_performance.length === 0){return}

                let url= base_url + 'add-new-question-performance'
              
                const token = localStorage.getItem("login_token");
                
                let token_header= { Authorization :  'Token '+token}     
                
                

                axios({
                  method: 'post',
                  url: url,
                  data:realtime_question_performance,
                  headers:token_header,
                  timeout: 15000
                })
                
                  .then(res => {
                                                 
                    localStorage.realtime_question_performance = JSON.stringify([])
                       

                  })

                  .catch((error)=> {
                          // handle error
                         
                        
                  })
                





  }

  check_and_set_performance_local_storage_value=()=>{

    //the json.strringify is needed  to set array in local storage since local storage only suppots srings
     
        if(localStorage.question_performance === undefined){
                   localStorage.question_performance =  JSON.stringify([])
                       
          }

          if(localStorage.realtime_question_performance === undefined){
            localStorage.realtime_question_performance =  JSON.stringify([])
                
          }


  }

  get_questions_from_db_and_save_to_store=()=>{

        if(this.state.user_is_logged_in!==true){ return}

        if(typeof this.props.user_account === 'object' && this.props.user_account !== null){

                if(this.props.un_done_questions.length<3 && this.state.get_question_from_database_control && (this.props.database_questions_id[0]!=='pending_response') && (this.props.database_questions_id.length!==0)){

                          // this code below is to stop multiple api calls till the first response is gotten
                          this.setState({get_question_from_database_control:false});
                          
                          var available_questions_id = [...this.props.database_questions_id]

                          var done_questions_id= [...this.props.done_questions_id]

                          var un_done_questions= [...this.props.un_done_questions]

                          
                          for (let x = 0; x < available_questions_id.length; x++) {
                                  
                                  for (let i = 0; i < done_questions_id.length; i++) {

                                        if(done_questions_id[i]===available_questions_id[x]){

                                              available_questions_id.splice(x,1)

                                        }

                                  }


                                  for (let y = 0; y < un_done_questions.length; y++) {

                                          if(un_done_questions[y].id===available_questions_id[x]){
                                            
                                              available_questions_id.splice(x,1)

                                          }

                                  }

                          }

                          let b = Math.floor(Math.random() * available_questions_id.length)
                         
                          let needed_question_id = available_questions_id[b]

                            let url= base_url + 'get-questions-from-db'
                        
                            const token = localStorage.getItem("login_token");
                            
                            let token_header= { Authorization :  'Token '+token}     
                            
                          
                            axios({
                              method: 'post',
                              url: url,
                              data:[needed_question_id,],  
                              //data:['1550',],                      
                              headers:token_header
                            })
                            
                              .then(res => {
                                        
                                    // this code below is to reset the value back

                                   
                                     
                                    let current_un_done_questions = [...this.props.un_done_questions, res.data]                                 
                                    this.props.save_un_done_questions_to_store(current_un_done_questions)
                                    this.setState({get_question_from_database_control:true});
                                    
            
                              })
            
                            .catch((error)=> {
                                  // this code below is to reset the value back
                                  this.setState({get_question_from_database_control:true}); 
                                  
                                
                                
                            })


                }  

        }

  }

  get_performance_summary_from_db_and_save_to_store=()=>{

                        if(localStorage.login_token === undefined){ return}

                        let url= base_url + 'get-performance-summary-from-db'
                    
                        const token = localStorage.getItem("login_token");
                        
                        let token_header= { Authorization :  'Token '+token}     
                        
                      
                        axios({
                          method: 'get',
                          url: url,                                              
                          headers:token_header
                        })
                        
                          .then(res => {
                                    
                                this.props.save_performance_summary_to_store(res.data)
                               
                          })
        
                        .catch((error)=> {
                            
                              
                              
                            
                        })


              

    }

  check_email_confirmation=()=>{
    if(this.state.user_is_logged_in==true && this.props.user_account !== null && this.props.user_account.platform!== 'anonymous'){
            if(this.props.user_account.email_confirm == 'false'){
               // this.setState({email_is_confirmed:false});
            }
            if(this.props.user_account.email_confirm === 'true' && this.state.email_is_confirmed === false){
              this.setState({email_is_confirmed:true});
            }      
     }
  }

  get_user_account_and_profile_details=()=>{
             
      if (this.props.user_account === null && localStorage.login_token !== undefined){
                //the code below is to stop multiple request till the response of this one is gotten
                this.props.save_user_account_to_store('pending_server_response')
       
                let url= base_url + 'user-account-and-profile-info'
              
                const token = localStorage.getItem("login_token");
                
                let token_header= { Authorization :  'Token '+token}     
                
              
                axios({
                  method: 'get',
                  url: url,
                  data:'',
                  headers:token_header
                })
                
                  .then(res => {
                                                 
                      
                        if(res.data[0].platform==='learner'||res.data[0].platform=== 'anonymous'){
                                  this.props.save_user_account_to_store(res.data[0])
                                  this.props.save_user_profile_to_store(res.data[1])
                                  this.get_performance_summary_from_db_and_save_to_store()
                                 
                        }
                        else{
                                  alert("Yes, you have an account with Learnershub, but you are trying to login to the wrong platform")
                                  this.props.save_user_account_to_store(null)
                                  this.props.save_user_profile_to_store(null)
                                  localStorage.removeItem("login_token");
                                  
                        }
                         

                  })

                  .catch((error)=> {
                          // handle error

                          alert("OOOOPS, Something went wrong")
                          this.props.save_user_account_to_store(null)
                          this.props.save_user_profile_to_store(null)
                          localStorage.removeItem("login_token");
                          //this.props.save_user_account_to_store(null)
                        
                        
                  })
      }            


  }

  check_login=()=>{
            
            if (localStorage.login_token !== undefined && (typeof(this.props.user_account) === 'object' && this.props.user_account !== null)){
              
                  this.setState({user_is_logged_in: true});
                 
                
            }
            else{
              this.setState({user_is_logged_in: false}); 
            
            }
                
  }

  get_all_questions_id_from_db=()=>{

        if(this.state.user_is_logged_in!==true){ return}

        if(typeof this.props.user_account === 'object' && this.props.user_account !== null){

                if(this.props.database_questions_id.length===0){

                            // this code below is to stop multiple api calls till the first response is gotten
                           
                            this.props.save_database_questions_id_to_store(['pending_response'])
                            let url= base_url + 'get-all-questions-id-from-db'
                        
                            const token = localStorage.getItem("login_token");
                            
                            let token_header= { Authorization :  'Token '+token}     
                            
                          
                            axios({
                              method: 'get',
                              url: url,
                              data:'',
                              headers:token_header
                            })
                            
                              .then(res => {
                                
                                          if(res.data.length < 1 ){
                                                  alert('Oops, We do not currently have questions that fit into your filter at the moment. '+ 
                                                      'We are contantly adding questions to our system and we are sure to make such questions '+
                                                      'available soon. Please re-adjust your filter on the Question Setup page to get other questions. Thank you')
                                            }    
                                            
                                            else{
                                            
                                                     this.props.save_database_questions_id_to_store(res.data)

                                            }
            
                              })
            
                              .catch((error)=> {
                                  // this code below is to reset the alue if response fails
                                  this.props.save_database_questions_id_to_store([])
                                
                                
                              })

                            


                }



        }



  }
 
  has_watched_tutorial_video=()=>{

    localStorage.setItem("has_watched_tutorial_video", true);
    this.props.change_has_watched_tutorial_video_status(true) 

  }

  logout=()=>{

    localStorage.removeItem("login_token");
    setTimeout(()=>{
                this.props.save_user_account_to_store(null);
                this.props.save_user_profile_to_store(null);
                this.props.save_performance_summary_to_store({performance_data})}
                ,1000)
                
  
  
   }
  render() {

          let no_internet_page = null
          let update_mobile_app = null
          let current_page = 
                                    <Modal style={customStyles} isOpen={!this.state.user_is_logged_in} contentLabel="Minimal Modal Example">
                                         
                                          <Switch >
                                                 
                                                  <Route exact path="/register-new-account" component={RegisterNewAccount} />                                                
                                                  <Route exact path="/user-login" component={Login} />
                                                  <Route exact path="/" component={UserChoice} />
                                                  <Route path="" component={UserChoice} />

                                          </Switch>
                                                                   
                                    </Modal>
            
              

            
          if(localStorage.login_token !== undefined){

            current_page =  <Modal style={customStyles} isOpen={!this.state.user_is_logged_in} contentLabel="Minimal Modal Example">
                                        <Loading_page/>
                            </Modal>


            
          }


          if(this.state.user_is_logged_in===true  && this.props.user_account!==null){

                    let convert_account = null
                    if(this.props.user_account.platform !=="learner"){

                            convert_account = <Modal style={customStyles} isOpen={this.props.show_convert_account_page} contentLabel="Minimal Modal Example">
                                                        <button  type="button" onClick={()=>{ this.props.set_convert_account_to_permanent_page_data(false)}} style={{float:'right'}}>Exit</button>
                                                        <ConnectConvertAccount/>

                                              </Modal>


                     }

                    current_page = <div>

                                          <Modal style={customStyles} isOpen={this.props.should_show_question_setup_page} contentLabel="Minimal Modal Example">
                                                <button  style={{float:'right'}} onClick={_=>this.props.change_should_show_question_setup_page(false)}>Exit</button>
                                                <ConnectQuestionSetup/>
                                        
                                        </Modal>

                                          <Modal style={customStyles} isOpen={!this.state.email_is_confirmed} contentLabel="Minimal Modal Example">
                                          <button  type="button" onClick={()=>{ this.logout()}} style={{float:'right'}}>Sign Out</button>
                                                    <ConnectConfirm_email/>
                                                
                                        </Modal>

                                          <Modal style={customStyles} isOpen={!this.props.has_watched_tutorial_video} contentLabel="Minimal Modal Example">
                                                  <button  type="button" onClick={this.has_watched_tutorial_video} style={{float:'right'}}>Exit</button>
                                                  <Tutorial_video/>
                                      
                                          </Modal>
                                          
                                          
                                          <Switch >

                                                <Route exact path="/past-questions-centre" component={ConnectPastQuestionsCentre} />
                                                <Route exact path="/past_pdf_centre" component={PdfCentre} />
                                               
                                              

                                                <Route exact path="/pdf_centre-accounting" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-agricultural_science" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-business_studies" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-economics" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-english_fal" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-english_hl" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-geography" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-history" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-hospitality_studies" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-information_technology" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-information_technology" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-life_sciences" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-mathematics" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-physical_sciences" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-tourism" component={PdfCentreSubject} />
                                                <Route exact path="/pdf_centre-visual_art" component={PdfCentreSubject} />









                                                <Route exact path="/learning_centre" component={LearningCentre} />
                                                <Route exact path="/learning_centre-mathematics" component={ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-mathematics_literacy" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-life_sciences" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-physical_sciences" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-business_studies" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-accounting" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-economics" component={ ConnectSubjectTopics} />
                                                <Route exact path="/learning_centre-agricultural_science" component={ ConnectSubjectTopics} />
                                                
                                                <Route exact path="/pdf" component={PdfShower } />
                                                <Route exact path="/profile_page" component={ConnectProfile_page} />    
                                                <Route exact path="/contact_us" component={ConnectContact_us_page} /> 
                                                <Route exact path="/view_all_news" component={View_all_news} /> 
                                                <Route exact path="/android_asset/www/index.html" component={ConnectDashboard} /> 
                                                <Route exact path="/dashboard" component={ConnectDashboard} /> 
                                                <Route component={ConnectDashboard} />                                     
                                         </Switch>    
              
                 
                
                                        <Footer/>

                                          {convert_account}


                           </div>


          }

          if(this.state.maintenance_status===true){
                   
                   
                    current_page = <div>      
                                          <Modal style={customStyles} isOpen={this.state.maintenance_status} contentLabel="Minimal Modal Example">
                                           
                                           <Site_maintenance/>
                              
                                          </Modal>

                                  </div>


          }

         

          if(this.state.internet_is_available === false){
                   
                   
                    no_internet_page = <div>      
                                          <Modal style={customStyles} isOpen={true} contentLabel="Minimal Modal Example">
                                          
                                                <NoInternet/>
                              
                                          </Modal>

                                   </div>


          }

          if(this.state.update_mobile_app === true && this.state.maintenance_status===false ){
                   
                   
            update_mobile_app = <div>      
                                  <Modal style={customStyles} isOpen={true} contentLabel="Minimal Modal Example">
                                  
                                        <UpdateMobileApp/>
                      
                                  </Modal>

                          </div>


          }

         
    



    return (

              <div>
                          
                          {this.state.time}
                         
                          <ConnectHeaderSection/>
                          {current_page}    
                          {no_internet_page}
                          {update_mobile_app}

                         

              </div>
     

    )
  }

}


class PdfCentre extends Component {

  constructor(props) {
    super(props);
    this.state = {
                      icons : {
                                  exit_accounting:true,
                                  exit_agricultural_science: true,
                                  exit_business_studies:true,
                                  exit_economics :true,
                                  exit_english_fal:true,
                                  exit_english_hl:true,
                                  exit_geography:true,
                                  exit_history:true,
                                  exit_hospitality_studies:true,
                                  exit_information_technology:true,
                                  exit_tourism:true,
                                  exit_visual_art:true,
                                  exit_mathematics:true,
                                  exit_mathematical_literacy :true,
                                  exit_life_sciences :true,
                                  exit_physical_sciences:true,
                                  
                                 
                                 
                                  
                                  
                      }

                };
  }

  componentDidMount(){

            if(google_analytics !== null){google_analytics(window.location.pathname)}

            //if(window.location.hostname!=='localhost'){ 
                        // the code below is to trigger analytics to count this page
            //         ReactGA.pageview(window.location.pathname);
                      
              
            
            //}
            
 
    
  }
  

  exit_subject_choice_page =(controller)=>{
   
    let x;
    
    let current_state = this.state.icons
              for (x in current_state){
              
                              if(controller === x){
                               
                                
                                         continue
                                         
                                }
                                else{

                                 current_state[x]= false;

                                }
                                
                  }
                  this.setState({icons:current_state});
                    

                  setTimeout(()=>{this.setState({take_user_choice_away:false})  },700)
                            
                                  if(this.state.icons.exit_accounting ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-accounting") },1200)    }    
                                  if(this.state.icons.exit_agricultural_science ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-agricultural_science") },1200)    }    
                                  if(this.state.icons.exit_business_studies ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-business_studies") },1200)    }   
                                  if(this.state.icons.exit_economics ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-economics") },1200)    }  
                                  
                                  
                                  if(this.state.icons.exit_english_fal ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-english_fal") },1200)    }    
                                  if(this.state.icons.exit_english_hl ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-english_hl") },1200)    }    
                                  if(this.state.icons.exit_geography ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-geography") },1200)    }   
                                  if(this.state.icons.exit_history ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-history") },1200)    }  
                                  if(this.state.icons.exit_hospitality_studies ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-hospitality_studies") },1200)    }   
                                  if(this.state.icons.exit_information_technology ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-information_technology") },1200)    }  






                                  if(this.state.icons.exit_life_sciences ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-life_sciences") },1200)    } 
                                  if(this.state.icons.exit_mathematical_literacy ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-mathematical_literacy") },1200)    }   
                                  if(this.state.icons.exit_mathematics ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-mathematics") },1200)    }                                      
                                  if(this.state.icons.exit_physical_sciences ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-physical_sciences") },1200)    }
                                  
                                  
                                  if(this.state.icons.exit_tourism ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-tourism") },1200)    }   
                                  if(this.state.icons.exit_visual_art ===true){    setTimeout(()=>{this.props.history.push("/pdf_centre-visual_art") },1200)    }  

                                  
                                
                                 
                                
                  
                


                  
   }

  get_subject_list_page=()=>{

    let page_style =          {}  
    let h1_style =             {}
    let inner_div_style =       {}
    let p_style =            {}
    let logo_style =            {}

    let p_font_size = '100'
    let inner_div_style_margin_top = "6%"
    let logo_style_margin_top = "7%"
    let container_div_style = {}
    let image_width ='50%'
   
            

    if (window.innerWidth / window.innerHeight > 1  ){ 

      

                    p_font_size = '100'
                    inner_div_style_margin_top = "6%"
                    logo_style_margin_top = "7%"

                    
                  
                    if(window.innerWidth / window.innerHeight>1.8){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>1.9){
                      p_font_size =  '170%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }


                    if(window.innerWidth / window.innerHeight>2){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>2.1){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "1%"
                      logo_style_margin_top = "2%"
                    }

                    h1_style =    {fontSize:'150%', color:'green', }
                    page_style = {width:'auto', marginLeft:'9%',  marginTop:'2%',   color:'green', overflow:"auto" }                  
                    inner_div_style = {width:'30%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, }
                      p_style =            {fontSize:p_font_size}
                    logo_style = {marginLeft:"35%", marginTop:logo_style_margin_top, width:'30%'}
                   
                    
    }

    if (window.innerHeight / window.innerWidth >1  ){


          
         
          let p_font_size = '130%'
          let inner_div_style_margin_top = "0%"
          let logo_style_margin_top = "0%"



          h1_style =    {fontSize:'150%', color:'green', }
          page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
          inner_div_style = {width:'100%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top }
            p_style =            {fontSize:p_font_size}
          logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}



          if (window.innerWidth >500 ){

            p_font_size = '150%'
            inner_div_style_margin_top = "0%"
            logo_style_margin_top = "0%"
  

            h1_style =    {fontSize:'150%', color:'green', }
            page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
            inner_div_style = {width:'50%',  borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, marginLeft:'25%' }
              p_style =            {fontSize:p_font_size}
            logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}





          }


     }

                 
        


                      return                  <Bounce left>
                                <div style={{padding:'0% 3% 3% 3%', overflow:'auto'}}>
                                        <h1 style={{color:'green'}}>Past Matric Papers</h1>
                                        
                                        <hr/>
                
                                              <div  style={page_style}>
                                                
                                        
                                                        <Zoom  opposite when={this.state.icons.exit_accounting}>
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_accounting') }} >
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={0} animate_data={
                                                                    <img src={cordova_file_prefix + "accounting.png"} style={{width:image_width,  }}></img>

                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                    <p style={p_style} ><b>Accounting</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_agricultural_science}>
                                                           
                                                              <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_agricultural_science') }}>
                                                                <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={1000} animate_data={
                                                                        <img src={cordova_file_prefix + "agricultural_science.png"} style={{width:image_width,}}></img>
                                                                        
                                                               
                                                                }>
                                                                      
                                                              </ContinouslyAnimate>
                                                              <p style={p_style}><b>Agricultural Sciences</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_business_studies}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_business_studies') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={2000} animate_data={
                                                                    <img src={cordova_file_prefix + "business-studies.png"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Business Studies</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_economics}>
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_economics') }} >
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={3000} animate_data={
                                                                    <img src={cordova_file_prefix + "economics.png"} style={{width:image_width,  }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                    <p style={p_style} ><b>Economics</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_english_fal}>
                                                          
                                                                <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_english_fal') }}>
                                                                  <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={4000} animate_data={
                                                                        <img src={cordova_file_prefix + "english_fal.jpg"} style={{width:image_width,}}></img>
                                                                      }>
                                                                      
                                                                      </ContinouslyAnimate>
    
                                                                        <p style={p_style}><b>English First Additional Language</b></p>
                                                                </div>
                                                           
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_english_hl}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_english_hl') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={5000} animate_data={
                                                                    <img src={cordova_file_prefix + "english_hl.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>English Home Language</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_geography}>
                                                         
                                                                <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_geography') }}>
                                                                <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={6000} animate_data={
                                                                        <img src={cordova_file_prefix + "geography.jpg"} style={{width:image_width,}}></img>
                                                                      }>
                                                                      
                                                                      </ContinouslyAnimate>
                                                                        <p style={p_style}><b>Geography</b></p>
                                                                </div>
                                                           
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_history}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_history') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "history.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>History</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_hospitality_studies}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_hospitality_studies') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "hospitality_studies.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Hospitality Studies</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_information_technology}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_information_technology') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "information_technology.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Information Technology</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_life_sciences}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_life_sciences') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "life-sciences.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Life Sciences</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_mathematical_literacy}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_mathematical_literacy') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "mathematics-literacy.png"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Mathematical Literacy</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_mathematics}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_mathematics') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "mathematics.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Mathematics</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_pyhisical_sciences}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_pyhisical_sciences') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "physical-sciences.png"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Physical Sciences</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_tourism}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_tourism') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "tourism.png"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Tourism</b></p>
                                                            </div>
                                                        </Zoom>
                                                        
                                                        <Zoom  opposite when={this.state.icons.exit_visual_art}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_visual_art') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "visual_art.jpg"} style={{width:image_width, }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Visual Art</b></p>
                                                            </div>
                                                        </Zoom>
                                          
                                          
                                          
                                          
                                                        
                                          
                                              </div> 

                                              <button  style={{ width:'20%', fontSize:'100%', minWidth:"200px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{this.props.history.push("/dashboard")}}  >Back to Dashboard</button>  
                                                            
                              </div>
                </Bounce>





  }

 
  render() {

      let current_page = this.get_subject_list_page()

    

      return (
              <div>
                  
                      {current_page}

                      <Helmet>
                                              <title>Learn With Videos</title>  
                                            
                                             
                                            

                     </Helmet>

                   

              </div>




      );
  }
}

class PdfCentreSubject extends Component {

  constructor(props) {
    super(props);
    this.state = {

                    
                                     subject_name : '',

                                    
                                     

                                      pdf_list:[],

                                display_pdf_shower : false,
                                
                                button_disabled: false,
                                current_pdf_year :  "",
                                current_subject_key:"",
                                response_profile_data : ""
                                    

                };
  }

  componentDidMount(){

          this.set_data_to_state() //react ga for analythics is fired inside this component
          

  }

  set_data_to_state=()=>{


    let subject_path = window.location.pathname
    let subject_name = ''
   
    let current_subject_key = ""

    let pdf_list = []

    let pdf_base_url = 'https://learner.learnershub.co.za/past_pdf' 

  
    if(subject_path=== "/pdf_centre-accounting"  ){ 


                   
      subject_name = 'Accounting'
    
      current_subject_key = "accounting"

      pdf_list = [
                      "2008",
                      "2009",
                      "2010",
                      "2011",
                      "2012",
                      "2013",
                      "2014",
                      "2015",
                      "2016",
                      "2017",
                      "2018",
                      "2019",
                      "2020",
                  

      ]


    } 

    if(subject_path=== "/pdf_centre-agricultural_science"  ){ 
 
      subject_name = 'Agricultural Sciences'
    
      current_subject_key = "agricultural_sciences"

      pdf_list = [
                       
                        "2009",   
                        "2011",                  
                        "2012",
                        "2013",
                        "2014",
                        "2015",
                        "2016",
                        "2017",
                        "2018",
                        "2019",
                        "2020",               

                  ]
    
    }

    if(subject_path=== "/pdf_centre-business_studies"  ){ 

      subject_name ='Business Studies'
     

      current_subject_key = "business_studies"

      pdf_list = [
              "2008",
              "2009",
              "2010",
              "2011",
              "2012",
              "2013",
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
              "2019",
              "2020",

]


    }

    if(subject_path=== "/pdf_centre-economics"  ){ 

      subject_name ='Economics'
   

      current_subject_key = "economics"


      pdf_list = [
              "2008",
              "2009",
              "2011",
             
              "2012",
              "2013",
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
              "2019",
              "2020",
    
]

    }

    if(subject_path=== "/pdf_centre-english_fal"  ){ 

      subject_name ='English First Additional Language'
   

      current_subject_key = "english_fal"


      pdf_list = [
                    "2008",
                    "2009",
                    '2011',
                 
                    "2012",
                    "2013",
                    "2014",
                    "2015",
                    "2016",
                    "2017",
                    "2018",
                    "2019",
                    "2020",
                

]

    }

    if(subject_path=== "/pdf_centre-english_hl"  ){ 

      subject_name ='English Home Language'
   

      current_subject_key = "english_hl"

      pdf_list = [
                  "2008",
                  '2011',
                 
                  "2012",
                  "2013",
                  "2014",
                  "2015",
                  "2016",
                  "2017",
                  "2018",
                  "2019",
                  "2020",
    

]

    }

    if(subject_path=== "/pdf_centre-geography"  ){ 

      subject_name ='Geography'
   

      current_subject_key = "geography"

      pdf_list = [
        "2008",
        "2009",
       
       
        "2012",
      
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
    

]

    }

    if(subject_path=== "/pdf_centre-history"  ){ 

      subject_name ='History'
   

      current_subject_key = "history"

      pdf_list = [
                    "2008",
                    "2009",
                   
                    "2011",
                    "2012",
                    "2013",
                    "2014",
                    "2015",
                    "2016",
                    "2017",
                    "2018",
                    "2019",
                    "2020",
    

      ]




    }

    if(subject_path=== "/pdf_centre-hospitality_studies"  ){ 

      subject_name ='Hospitality Studies'
   

      current_subject_key = "hospitality_studies"

      pdf_list = [
        "2008",
       
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 

    }

    if(subject_path=== "/pdf_centre-information_technology"  ){ 

      subject_name ='Information Technology'
   

      current_subject_key = "information_technology"

      pdf_list = [
        "2008",
        "2009",
       
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 


    }

    if(subject_path=== "/pdf_centre-life_sciences"  ){ 

      subject_name ='Life Sciences'
   

      current_subject_key = "life_sciences"

      pdf_list = [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 


    }

    if(subject_path=== "/pdf_centre-mathematics_literacy"  ){ 

     
      subject_name = 'Mathematical Literacy'
      
      current_subject_key = "mathematical_literacy"

      pdf_list = [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 


    }

    if(subject_path=== "/pdf_centre-mathematics"  ){

        
                                subject_name = 'Mathemathics'
                               
                                   current_subject_key = "mathematics"

                                   pdf_list = [
                                    "2008",
                                    "2009",
                                    "2010",
                                    "2011",
                                    "2012",
                                    "2013",
                                    "2014",
                                    "2015",
                                    "2016",
                                    "2017",
                                    "2018",
                                    "2019",
                                    "2020",
                            
                            
                                  ] 
                          

    }

    if(subject_path=== "/pdf_centre-physical_sciences"  ){


                              subject_name = 'Physical Sciences'
                            

                              current_subject_key = "physical_sciences"

                              pdf_list = [
                                "2008",
                                "2009",
                                "2010",
                                "2011",
                                "2012",
                                "2013",
                                "2014",
                                "2015",
                                "2016",
                                "2017",
                                "2018",
                                "2019",
                                "2020",
                        
                        
                              ] 
                        
                              
         
    }

    if(subject_path=== "/pdf_centre-tourism"  ){


      subject_name = 'Tourism'
    

      current_subject_key = "tourism"

      pdf_list = [
        "2008",
        "2009",
       
        "2011",
        "2012",
        "2013",
       
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 

      

    }

    if(subject_path=== "/pdf_centre-visual_art"  ){


      subject_name = 'Visual Art'


      current_subject_key = "visual_art"

      pdf_list = [
        "2008",
        "2009",
       
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",


      ] 

      

    }

    

   

    

    

    this.setState({subject_name:subject_name});
   
    this.setState({current_subject_key:current_subject_key});

    this.setState({pdf_list : pdf_list });
   


    
    if(google_analytics !== null){google_analytics(window.location.pathname)}


    //  if(window.location.hostname!=='localhost'){ 
                    // the code below is to trigger analytics to count this page
             //    ReactGA.pageview(window.location.pathname );
                  
         
       
    //   }
    


  }

  set_current_pdf_data_to_state_and_show=(item)=>{

    this.setState({
      
                      current_pdf_year : item,
                      display_pdf_shower : true

    
                   });


  }

  get_pdf_list_page=()=>{

    let page_style =          {}  
    let h1_style =             {}
    let inner_div_style =       {}
    let p_style =            {}
    let logo_style =            {}

    let p_font_size = '100'
    let inner_div_style_margin_top = "6%"
    let logo_style_margin_top = "7%"
    let container_div_style = {}
    
   
            

    if (window.innerWidth / window.innerHeight > 1  ){ 

      

                    p_font_size = '100%'
                    inner_div_style_margin_top = "6%"
                    logo_style_margin_top = "7%"

                    
                  
                    if(window.innerWidth / window.innerHeight>1.8){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>1.9){
                      p_font_size =  '170%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }


                    if(window.innerWidth / window.innerHeight>2){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>2.1){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "1%"
                      logo_style_margin_top = "2%"
                    }

                    h1_style =    {fontSize:'150%', color:'green', }
                    page_style = {width:'auto', marginLeft:'9%',  marginTop:'2%',   color:'green', overflow:"auto" }                  
                    inner_div_style = {width:'30%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, }
                      p_style =            {fontSize:p_font_size}
                    logo_style = {marginLeft:"35%", marginTop:logo_style_margin_top, width:'30%'}
                   
                    
    }

    if (window.innerHeight / window.innerWidth >1  ){


          
         
          let p_font_size = '100%'
          let inner_div_style_margin_top = "0%"
          let logo_style_margin_top = "0%"



          h1_style =    {fontSize:'150%', color:'green', }
          page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
          inner_div_style = {width:'100%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top }
            p_style =            {fontSize:p_font_size}
          logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}



          if (window.innerWidth >500 ){

            p_font_size = '150%'
            inner_div_style_margin_top = "0%"
            logo_style_margin_top = "0%"
  

            h1_style =    {fontSize:'150%', color:'green', }
            page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
            inner_div_style = {width:'50%',  borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, marginLeft:'25%' }
              p_style =            {fontSize:p_font_size}
            logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}





          }


     }

                 
          
    //alert(window.innerHeight)


         
       



  return                  <Bounce left>
                                <div style={{padding:'0% 3% 3% 3%', overflow:"auto"}}>

                
                                              <div  style={page_style}>

                                                <h3>{"Past Matric Papers : " + this.state.subject_name}</h3>
                                               
                                                <hr/>
                                                
                                                        {this.state.pdf_list.map(item =>
                                                      
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{ this.set_current_pdf_data_to_state_and_show(item);}} >
                                                            <ContinouslyAnimate animation_type={'pulse'} animation_interval={Math.ceil(Math.random()*50000)} animation_delay={0} animate_data={
                                                                    <div style={{width:'85%', height :'80px', backgroundColor:"green" ,color:'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
                                                                                    <p style={p_style} ><b>{this.state.subject_name + " " +item }</b></p>  
                                                                     </div>

                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                   
                                                            </div>
                                                      
                                                        )}

                                                   
                                                        
                                          
                                              </div> 
                                              <hr/>                                                 
                                              <button  style={{margin:'auto', width:'20%', fontSize:'100%', minWidth:"200px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{this.props.history.push("/past_pdf_centre")}}  >Back to Subjects</button>
                                          
                                                
                                                            
                              </div>
                </Bounce>





  }

  


  

  display_pdf_shower=()=>{



        return <div style={{margin:'0px 0px 120px 0px'}}>
                      <PdfShower subject={this.state.current_subject_key} year={this.state.current_pdf_year} />
                      <hr/>
                      <button  style={{margin:'auto', width:'15%', fontSize:'100%', minWidth:"150px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{this.setState({ display_pdf_shower:false, current_pdf_year :''  })}}  > Back to List </button>

              </div>
  }


  


  
 
  render() {

      let current_page = this.get_pdf_list_page()

      if(this.state.display_pdf_shower ===true){
        current_page = this.display_pdf_shower()
      }
     


      return (
              <div>
                  
                      {current_page}
                      
                      <Helmet>
                                              <title> Past Matric Papers - {this.state.subject_name} </title>  
                                            
                                             
                                            

                      </Helmet>

              </div>




      );
  }
}

class PdfShower extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   pdf_path :'',
                   display_pdf : false

                };
  }

  componentDidMount() {


              let base_url = ""
              let pdf_path=''

              pdf_has_finished_downloading[0]= false
              pdf_has_finished_downloading[1]= ''


             
           

              if(check_cordova){


                      base_url = window.cordova.file.externalDataDirectory

                      pdf_path = base_url + this.props.subject + '/' + this.props.subject + '_' + this.props.year + '.pdf'
                     
                 
                      this.check_if_file_not_exist_and_download(pdf_path)            
              }

              else{

                      base_url = "../../"

                      pdf_path = base_url + 'past_pdf/' + this.props.subject + '/' + this.props.subject + '_' + this.props.year + '.pdf'


                      pdf_has_finished_downloading[0]= true
                      pdf_has_finished_downloading[1]= pdf_path


              }

  }

 

  check_if_file_not_exist_and_download=(pdf_path)=>{

            // path is the full absolute path to the file.
          pdf_has_finished_downloading[1] = pdf_path


          window.resolveLocalFileSystemURL(pdf_path, fileExists, fileDoesNotExist)
             
          function fileExists(fileEntry){
                  
                
                  //set_pdf_status()
                  pdf_has_finished_downloading[0] = true
                 

                  console.log("inside file exist : " + pdf_has_finished_downloading)
                  
          }

          function fileDoesNotExist(pdf_path){
              
              download_file(pdf_path, folder, download_url)

              pdf_has_finished_downloading[0] = false
              console.log("inside file not exist : " + pdf_has_finished_downloading)
             
              
          }

        //  let set_pdf_status=()=>{
                   
           // this.setState({   pdf_path : pdf_path,  display_pdf : true });

        //  } 

           



             
          //folder path and download url are set here as the value of this.props.subject cannot the gotten in a non arrow function
          let folder = this.props.subject  
          let download_url = "https://learner.learnershub.co.za/past_pdf/" + this.props.subject + '/' + this.props.subject + '_' + this.props.year + '.pdf'


               function   download_file(pdf_path, folder, download_url){
                                   

                                   let dl = new window.download();

                                


                                   function DownloaderError(err) {
                                       
                                        
                                   }

                                  function DownloaderSuccess() {
                                           
                                      pdf_has_finished_downloading[0] = true   
                                      console.log("inside download success function : " + pdf_has_finished_downloading[0])  
                                             
                                  }


                                 


                                 dl.Initialize({
                                                          fileSystem : window.cordova.file.externalDataDirectory,
                                                          folder: folder,
                                                          unzip: false,
                                                          remove: false,
                                                          timeout: 0,
                                                          success: DownloaderSuccess,
                                                          error: DownloaderError,
                                                        
                                                    });

                                                   
                                    
                                                                                              

                                  dl.Get(download_url);
                                                    
                                                    
                                
                                               
                                
                        

             }


  }



  show_pdf_page=()=>{


                if(pdf_has_finished_downloading[0]===true){



                        return  <PdfJsViewer height='100vh' pdf_path={pdf_has_finished_downloading[1]} cordova={check_cordova}/>

                }

                if(pdf_has_finished_downloading[0]===false){

                        return <div style={{MarginTop:'25%'}}>
                        
                                    <ContinouslyAnimate animation_type={'flash'} animation_interval={3000} animation_delay={0} animate_data={<h3 style = {{marginTop:'10%', textAlign:'center', color:'red'}}>Downloading   .....  This Might Take a Minute</h3>}>

                                      </ContinouslyAnimate>
                                      <p style={{marginTop:'2%', color :'green', textAlign:'center',}}>This is your first time viewing this paper, We need to download it to your device. You won't need to wait next time you try to view this paper</p>
                            </div>


                }
          
  }

 

    render() {

       let pdf_page = null

       pdf_page = this.show_pdf_page()
           
        
        return (
                    
                <div >

                  {pdf_page}     
                   
                                                                
                       
                                          
                  
                </div>

                    
                   
            );

    }

  }

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
                    current_page:'login',                   
                    button_disabled:false,
                    take_user_login_page_away:false,
                    current_forgot_password_block:'request',

                    password_reset_form_data:{
                              password_username:'',
                              password_token:'',
                              new_password:'',
                              confirm_new_password:'',
                    },
                    

                    login_form_data:{
                          username:'',
                          password:''
                    },
                    
                    
                    
                    reset_password_validation_data :{

                      
                      password:null,
                      confirm_password:null,
                      token:null
                  }  


                };
  }

  componentDidMount(){


    if(google_analytics !== null){google_analytics(window.location.pathname)}

    //if(window.location.hostname!=='localhost'){ 
                 // the code below is to trigger analytics to count this page
        //      ReactGA.pageview(window.location.pathname);
               
      
    
   // }
    
 
    
  }
  
  
  reset_password_form_validation=(clicked)=>{
     
    if (this.state.password_reset_form_data.password_token!=='' && this.state.password_reset_form_data.password_token.length!==6 ){
      this.setState(state => (state.reset_password_validation_data.token ='Token must be 6 digits', state)) 
      
    }
    else{
      this.setState(state => (state.reset_password_validation_data.token =null, state)) 
    }


    if (this.state.password_reset_form_data.new_password!=='' && this.state.password_reset_form_data.new_password.length <6 ){
      this.setState(state => (state.reset_password_validation_data.password ='Password must be minimum of 6 characters', state)) 
      
    }
    else{
      this.setState(state => (state.reset_password_validation_data.password =null, state)) 
    }

    if (this.state.password_reset_form_data.confirm_new_password!==this.state.password_reset_form_data.new_password && this.state.password_reset_form_data.confirm_new_password!==''){
      this.setState(state => (state.reset_password_validation_data.confirm_password ='Password and confirm password field must be the same', state)) 
      
    }
    else{
      this.setState(state => (state.reset_password_validation_data.confirm_password =null, state)) 
    }
 

    if(clicked==true){
          let new_form_data= this.state.password_reset_form_data;
  
          let key;
          for (key in new_form_data){

                if(new_form_data[key]===''){
                    alert('Please fill all fields')
                    return false
                } 
          }

          new_form_data= this.state.reset_password_validation_data;
          for (key in new_form_data){

                if(new_form_data[key]!==null){
                    alert('Please correct all the errors in red')
                    return false
                } 
          }

          return true

    }

}

  request_password_token=()=>{
    this.setState({button_disabled: true});        

    let url= base_url + 'request-forgot-password-token-and-reset'
    
    axios({
      method: 'post',
      url: url,
      data:[this.state.password_reset_form_data.password_username]
    })
    
      .then(res => {    
        this.setState({current_forgot_password_block:'reset'});
        this.setState({button_disabled: false});
            alert("Token has been sent to your email, Please check both your inbox and spam folders") 
           

      })

      .catch((error)=> {
        
          this.setState({button_disabled: false});
           
         
          alert("Invalid Username")
        
      })
     


  }

  reset_password=()=>{
            
    if(this. reset_password_form_validation(true)){

                this.setState({button_disabled: true});
                let url= base_url + 'request-forgot-password-token-and-reset'
                
                axios({
                  method: 'put',
                  url: url,
                  data:[this.state.password_reset_form_data.password_token, this.state.password_reset_form_data.new_password]
                })
                
                  .then(res => {    
                    this.setState({current_forgot_password_block:'request'});
                    this.setState({current_page:'login'});
                    this.setState({button_disabled: false});
                        alert("Password changed succesfully. Please login with new password") 
                      

                  })

                  .catch((error)=> {
                    
                      this.setState({button_disabled: false});                    
                      alert("Password reset token is invalid ")
                    
                  })
        }          


  }


  handle_login_Change=(event)=> {
   
    let new_form_data= this.state.login_form_data;
    
            let key;
            for (key in new_form_data){

                  if(key===event.target.id){
                        new_form_data[key]=event.target.value;
                        this.setState({login_form_data: new_form_data});
                        
                  } 
            }


  }


  handle_password_reset_Change=(event)=> {
   
    let new_form_data= this.state.password_reset_form_data;
    
            let key;
            for (key in new_form_data){

                  if(key===event.target.id){
                        new_form_data[key]=event.target.value;
                        this.setState({password_reset_form_data: new_form_data});
                        
                  } 
            }
            
            this.reset_password_form_validation()
  }



  handle_login=(event)=> {
    
            event.preventDefault()
            this.setState({ button_disabled: true })        
            let url = base_url;
                url= url + 'customised-get-login-token'
          
          
            axios({
              method: 'post',
              url: url,
              data:this.state.login_form_data,
              
            })
            
              .then(res => {

                              setTimeout( ()=>{                
                                                localStorage.login_token= res.data.token
                                                this.exit_login_page('dashboard')
                                        }, 2000)
                                          
                  
              })

              .catch((error)=> {

                      setTimeout( ()=>{   
                                            // handle error
                                            if(error.response.status === 400){alert("Wrong Username/Password combination, Please type a correct Username and Password")}
                                            else{alert("Something went wrong. Please try again")}
                                            this.setState({ button_disabled: false }) 
                                      } ,2000)     
                                          
                      
              })

  }

  exit_login_page=(controller)=>{

            if(controller==="dashboard"){

                          this.setState({take_user_login_page_away:true})

                          setTimeout(()=>{this.props.history.push("/dashboard")  },300)

            }

            else{
                    this.setState({take_user_login_page_away:true})

                    setTimeout(()=>{this.props.history.push("")  },300)
                  }



  }


  get_login_page =()=>{


    let button_text = "Sign In"

    if(this.state.button_disabled === true){

        button_text = <ContinouslyAnimate animation_type={"zoom"} animation_interval={1000} animate_data={'Loading       '}></ContinouslyAnimate>

    }
    

    let login_page=  <div class="account-container" style={{width:'25%', minWidth:'320px' }}>
	
                              <div class="content clearfix">
                                
                                <form action="#" method="post">
                                
                                  <h1>Member Login</h1>		
                                  
                                  <div class="login-fields">
                                    
                                    <p>Please provide your details</p>
                                    
                                    <div class="field">
                                      <label for="username">Username</label>
                                      <input type="text" id="username" style={{width:'100%'}} value="" placeholder="Username or Email " class="login username-field" value={this.state.login_form_data.username} onChange={this.handle_login_Change} />
                                    </div>
                                    
                                    <div class="field">
                                      <label for="password">Password:</label>
                                      <input type="password" id="password" style={{width:'100%'}} value="" placeholder="Password" class="login password-field"value={this.state.login_form_data.password} onChange={this.handle_login_Change} />
                                    </div>
                                    
                                  </div>
                                  
                                  <div class="login-actions">
                                    
                                    <span class="login-checkbox">
                                      <input id="Field" name="Field" type="checkbox" class="field login-checkbox" value="First Choice" tabindex="4" />
                                      <label class="choice" for="Field">Keep me signed in</label>
                                    </span>
                                              
                                    <button class="button btn btn-success btn-large" onClick={this.handle_login} disabled={this.state.button_disable} >{button_text}</button>
                                    
                                  </div> 
                                  
                                  
                                  
                                </form>
                                
                              </div> 
                              <div class="login-extra">
                                <p style={{margin:'5%'}}><a href="#" onClick={()=>{this.setState({current_page:'forgot_password'});}} >Forgot Password</a></p>
                                <p style={{margin:'5%'}}>If you want to create an account or have accessto the platform without creating account,  <a href="#" onClick={()=>{this.exit_login_page()}}>Click Here</a></p>
                                
                                </div> 
   
                        </div> 


          return login_page
    
  }

 
  get_password_token_request_block=()=>{

    let request_token = "Request Token"
    if(this.state.button_disabled === true){
           request_token = <ContinouslyAnimate animation_type={"wobble"} animation_interval={1000} animate_data={"Sending Token"}/>

    }
          
    let password_token_request_block=   <div>
                                                  <p> Enter your Learnershub Username below to request a reset token to be sent to your email</p>
                                                  <input type="text" id="password_username" name="password_username"  placeholder="Enter Your Username" class="login" onChange={this.handle_password_reset_Change} />
                                                  <br/>
                                                 
                                                  <button onClick={this. request_password_token} disabled={this.state.button_disabled}>{request_token}</button>
                                              
                                        </div>
         
        return password_token_request_block
  }

  get_forgot_password_page=()=>{

          let current_forgot_password_block                                
          if(this.state.current_forgot_password_block=='request'){
                 current_forgot_password_block= this.get_password_token_request_block()
          }  

          if(this.state.current_forgot_password_block=='reset'){
                     current_forgot_password_block=this.get_password_reset_block()
          } 




          let forgot_password_page=  <div class="account-container" style={{width:'25%', minWidth:'320px' }}>
	
                                           <div class="content clearfix">
                                                    <h3> Password Recovery Page</h3>
                                                    <hr/>
                                                    {current_forgot_password_block}
                                                    <div>
                                                          Already have an account? Login <a href="#" onClick={()=>{this.setState({current_page:'login'});}}>here</a>
                                                    </div>

                                             </div>       
        
                                      </div>

                    return forgot_password_page

  }

  get_password_reset_block=()=>{


    let reset_password_text = "Reset Password"
    if(this.state.button_disabled === true){
           reset_password_text = <ContinouslyAnimate animation_type={"wobble"} animation_interval={1000} animate_data={"Resetting Your Password"}/>

    }

                            let password_reset_block = <div>
                                                        <p> We need to confirm that you are the owner of this username. So we have sent a password reset token to the
                                                        email address registered with the username. Please check your email and enter the received token below (Please check your spam folder 
                                                        if you cannot find the email in your inbox)</p>
                                                      
                                                        <label for="Password_token">Enter Token:</label>
                                                        <input type="number" id="password_token" name="password_token"  placeholder="Type the token here" class="login" onChange={this.handle_password_reset_Change} />
                                                        <span style={{color: "red"}}>{this.state.reset_password_validation_data.token}</span>




                                                        <p>If you do not enter the correct token sent to you, your password reset will fail</p>
                                                        <br/>
                                                        <label for="new_password">Enter New Password:</label>
                                                        <input type="text" id="new_password" name="new_password"  placeholder="Enter New Password" class="login" onChange={this.handle_password_reset_Change} />
                                                        <span style={{color: "red"}}>{this.state.reset_password_validation_data.password}</span>
                                                        <br/>
                                                        <label for="Password_token">Confirm New Password:</label>
                                                        <input type="text" id="confirm_new_password" name="confirm_new_password"  placeholder="Confirm New Password" class="login" onChange={this.handle_password_reset_Change} />
                                                        <br/><span style={{color: "red"}}>{this.state.reset_password_validation_data.confirm_password}</span>
                                                        <br/>
                                                        <button onClick={this.reset_password} disabled={this.state.button_disabled}>{reset_password_text}</button> 
                                                    </div> 


                          return password_reset_block                         

  }


  render() {

                          
   
     let current_page   
                                 
     if(this.state.current_page=='login'){
            current_page=this.get_login_page()
     }            
     
     

    if(this.state.current_page=='forgot_password'){
      current_page=this.get_forgot_password_page()
      }  


      return (
        <Roll left>
              <Zoom opposite when={!this.state.take_user_login_page_away}>
                <div>
                        {current_page}

                      
                          {// the helmet component is used to trigger google ads tracking conversion when this component is reached by an advert user//
                          }
                          <Helmet>
                                  <title>Login to Your Account</title>  
                                
                                  <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-476261592/PF4pCJiy_ewBENjZjOMB', 'value': 0.3, 'currency': 'ZAR' });"}`}</script>
                                

                          </Helmet>

                          {// the helmet component is used to trigger google ads tracking conversion when this component is reached by an advert user
                          }
                  </div>   
                </Zoom>
          </Roll>
      );
  }
}

class RegisterNewAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
                        take_signup_page_away: false,
                        current_page : 'form_page',
                        button_disabled: false,
                        
                          signup_form_data:{
                                  username:'',
                                  first_name:'',
                                  last_name:'',
                                  email:'',
                                  password:'',
                                  confirm_password:'',
                                  platform:'learner'
                          },
                          
                      signup_validation_data :{

                                  short_password:null,
                                  short_username:null,
                                  confirm_password:null,
                                  confirm_email:null

                      }  ,
                                    };
                      }

                     
componentDidMount(){



                      if(google_analytics !== null){google_analytics(window.location.pathname)}
                       // if(window.location.hostname!=='localhost'){ 
                                     // the code below is to trigger analytics to count this page
                            //      ReactGA.pageview(window.location.pathname);
                                   
                          
                        
                      //  }
                        
                     
                        
                      }
                      
  handle_signup=(event)=> {   
        event.preventDefault()
        if(this.signup_form_validation(true)){

                  this.setState({ button_disabled: true })        
                  let url = base_url;
                      url= url + 'create-new-user'
                
                  const token = localStorage.getItem("token");
                  let token_header= {};
                  if(token){
                      token_header= { Authorization :  'Token '+token}     
                  }
                  
                  axios({
                    method: 'post',
                    url: url,
                    data:this.state.signup_form_data,
                    headers:token_header
                  })
                  
                    .then(res => {

                          setTimeout(()=>{
                                              this.setState({ button_disabled: false })
                                              
                                              this.setState({current_page:'success_page'})
                                         }
                                         , 5000)

                             
                    })

                    .catch((error)=> {

                           setTimeout(()=>{

                                                // handle error
                                              
                                                if(error.response.status === 409){alert(error.response.data)}
                                                else{alert("Something went wrong. Please try again")}
                                                this.setState({ button_disabled : false }) 
                                           }
                                           ,5000)     
                      
                    })
            }
  }


  handle_signup_Change=(event)=> {
   
    let new_form_data= this.state.signup_form_data;
    
            let key;
            for (key in new_form_data){

                  if(key===event.target.id){
                        new_form_data[key]=event.target.value;
                        this.setState({login_form_data: new_form_data});
                        
                  } 
            }
           
            this.signup_form_validation()
           


  }

  signup_form_validation=(clicked)=>{
     
    if (this.state.signup_form_data.username!=='' && this.state.signup_form_data.username.length <6 ){
      this.setState(state => (state.signup_validation_data.short_username ='Username must be more than 5 characters', state)) 
      
    }
    else{
      this.setState(state => (state.signup_validation_data.short_username = null, state))
    }


    if (this.state.signup_form_data.password!=='' && this.state.signup_form_data.password.length <6 ){
      this.setState(state => (state.signup_validation_data.short_password ='Password must be more than 5 characters', state)) 
      
    }
    else{
      this.setState(state => (state.signup_validation_data.short_password =null, state))
    }

    if (this.state.signup_form_data.confirm_password!=='' && this.state.signup_form_data.confirm_password!==this.state.signup_form_data.password){
      this.setState(state => (state.signup_validation_data.confirm_password ='Password and confirm passord field must be the same', state)) 
      
    }
    else{
      this.setState(state => (state.signup_validation_data.confirm_password =null, state))
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.signup_form_data.email) == false && this.state.signup_form_data.email!==''){
      this.setState(state => (state.signup_validation_data.confirm_email ='This email address is not valid', state)) 
      
    }
    else{
      this.setState(state => (state.signup_validation_data.confirm_email =null, state))
    }

    if(clicked==true){
          let new_form_data= this.state.signup_form_data;
  
          let key;
          for (key in new_form_data){

                if(new_form_data[key]===''){
                    alert('Please fill all fields')
                    return false
                } 
          }

          new_form_data= this.state.signup_validation_data;
          for (key in new_form_data){

                if(new_form_data[key]!==null){
                    alert('Please correct all the errors in red')
                    return false
                } 
          }

          return true

    }

  }

  get_signup_page=()=>{


          let register_text = "Register"
          if(this.state.button_disabled === true){
            register_text = <ContinouslyAnimate animation_type={"tada"} animation_interval={1000} animate_data={"Loading       "}/>    

          }
          let signup_page= <div class="account-container register" style={{width:'25%', minWidth:'320px' }}>

                                  <div class="content clearfix">
                                    
                                    <form action="#" method="post">
                                    
                                      <h1>Signup Now</h1>			
                                      
                                      <div class="login-fields">
                                        
                                        <p>Create your account:</p>

                                        <div class="field">
                                        
                                          <label for="username">Username:</label>
                                          <input type="text" key='signup_username' id="username" style={{width:'100%'}} value={this.state.signup_form_data.username} placeholder="Username" class="login" onChange={this.handle_signup_Change} required/>
                                          <p style={{color: "red"}}>{this.state.signup_validation_data.short_username}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="firstname">First Name:</label>
                                          <input type="text" key='sign_upfirst_name' id="first_name" style={{width:'100%'}} value={this.state.signup_form_data.first_name} placeholder="First Name" class="login" onChange={this.handle_signup_Change} />
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="lastname">Last Name:</label>	
                                          <input type="text" key="signup_lastname" id="last_name" style={{width:'100%'}} value={this.state.signup_form_data.last_name} placeholder="Last Name" class="login" onChange={this.handle_signup_Change} />
                                        </div> 
                                        
                                        
                                        <div class="field">
                                          <label for="email">Email Address:</label>
                                          <input type="email" key='signup_email' id="email" style={{width:'100%'}} value={this.state.signup_form_data.email} placeholder="Email" class="login" onChange={this.handle_signup_Change} />
                                          <p style={{color: "red"}}>{this.state.signup_validation_data.confirm_email}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="password">Password:</label>
                                          <input id="password" type="password" id="password" style={{width:'100%'}} value={this.state.signup_form_data.password} placeholder="Password" class="login" onChange={this.handle_signup_Change} />
                                          <p style={{color: "red"}}>{this.state.signup_validation_data.short_password}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="confirm_password">Confirm Password:</label>
                                          <input type="password" id="confirm_password" style={{width:'100%'}} value={this.state.signup_form_data.confirm_password} placeholder="Confirm Password" class="login" onChange={this.handle_signup_Change} />
                                          <p style={{color: "red"}}>{this.state.signup_validation_data.confirm_password}</p>
                                        </div> 
                                        
                                      </div> 
                                      
                                      <div class="login-actions">
                                        
                                      
                                          <p class="choice" for="Field">By clicking the 'Register' button, it means you have agreed with our <a href = "https://learnershub.co.za/privacy.html" target="blank"  > Privacy Policy, </a> <a href = "https://learnershub.co.za/terms.html" target="blank"  >Terms & Conditions.</a></p>
                                        
                                                  
                                        <button class="button btn btn-primary btn-large" onClick={this.handle_signup} >{register_text}</button>
                                        
                                      </div> 
                                      
                                    </form>
                                    
                                  </div> 
                                  
                                  <div class="login-extra">
                                  <p style={{margin:'5%'}}>Already have an account? or you want access without registering? <a href="#" onClick={()=>{this.exit_signup_page()}}>Click Here</a></p>
                                 
                                </div> 


                                </div> 

          return signup_page

  }

  exit_signup_page=(controller)=>{

    if(controller ==="login"){

            this.setState({take_signup_page_away:true})

            setTimeout(()=>{this.props.history.push("/user-login")  },300)


    }

    else{
        this.setState({take_signup_page_away:true})

        setTimeout(()=>{this.props.history.push("")  },300)


    }  



  }

  get_registration_success_block=()=>{

    let registration_success_block= 
    
                                    <Bounce left>
    
                                            <div  >





                                                <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                                    
                                                    <div style={{marginTop:'10%'}}>
                                                      <h2 style={{color:'red'}}> Hurray !!!!!  Your Account Has Been Created Succesfully</h2>
  
                                                      <img src={cordova_file_prefix + "go.jpg"} style={{width:'50%',  }}></img>
                                                    
                                                    <p style={{color:'green', fontSize:'100%', marginTop:'5%'}}>Please click on the login button below to login with your newly created
                                                    Username/Email and password </p>	
                                                                                             
  
                                                    
                                                    <button  style={{margin:'0% 0 5% 20%', width:'60%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={()=>{this.exit_signup_page('login')}} type="button" >Login to Account</button>
                                                    </div>     
                                                </div>
	
                                            </div>        
                                      </Bounce>
                                       

        return registration_success_block




  }
 
  render() {

    let current_page = this.get_signup_page()
    
    if (this.state.current_page === 'success_page'){

        current_page = this.get_registration_success_block()

    }
    

      return (
        <Roll right>
                    <Zoom opposite when={!this.state.take_signup_page_away}>
                                <div >
                                    {current_page}

                                  
                                     
                                     
                                  
                                
                                </div>
                       </Zoom>


                         {// the helmet component is used to trigger google ads tracking conversion when this component is reached by an advert user//
                                      }
                        <Helmet>
                                              <title>Register New Account</title>  
                                            
                                              <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-476261592/PF4pCJiy_ewBENjZjOMB', 'value': 0.3, 'currency': 'ZAR' });"}`}</script>
                                            

                       </Helmet>



          </Roll>



      );
  }
}

class ConvertAccount extends Component {

  constructor(props) {
    super(props);
            this.state = {
                                        take_conversion_page_away: false,
                                        current_page : 'form_page',
                                        button_disabled: false,
                                        reload_user_account:false,
                                
                                        conversion_form_data:{
                                                  username:'',
                                                  first_name:'',
                                                  last_name:'',
                                                  email:'',
                                                  password:'',
                                                  confirm_password:'',
                                                  platform:'learner'
                                          },
                          
                                          conversion_validation_data :{

                                                      short_password:null,
                                                      short_username:null,
                                                      confirm_password:null,
                                                      confirm_email:null

                                          }  ,

                          }
      }                                 
                           
  componentDidMount(){

              if(google_analytics !== null){google_analytics("convert-to-permanent-account-page")}
              //if(window.location.hostname!=='localhost'){ 
                          // the code below is to trigger analytics to count this page
                    //    ReactGA.pageview("convert-to-permanent-account-page");
                        
                
              
             // }
              
     
        
   }               

 componentWillUnmount() {

        if( this.state.reload_user_account ==true){

                 this.props.save_user_account_to_store(null)

        }
                        
 }                    

  handle_conversion=(event)=> {   
        event.preventDefault()
        if(this.conversion_form_validation(true)){

                  this.setState({ button_disabled: true })        
                  let url = base_url;
                      url= url + 'convert-temporary-account-to-permanent-account'
                
                  const token = localStorage.getItem("login_token");
                
                  let token_header= { Authorization :  'Token '+token}   
               
                  axios({
                    method: 'post',
                    url: url,
                    data:this.state.conversion_form_data,
                    headers:token_header
                  })
                  
                    .then(res => {

                          setTimeout(()=>{
                                              this.setState({ button_disabled: false })
                                              
                                              this.setState({current_page:'success_page'})
                                              this.setState({reload_user_account:true})


                                         }
                                         , 2000)
                                         
                             
                    })

                    .catch((error)=> {

                           setTimeout(()=>{

                                                // handle error
                                              
                                                if(error.response.status === 409){alert(error.response.data)}
                                                else{alert("Something went wrong. Please try again")}
                                                this.setState({ button_disabled : false }) 
                                           }
                                           ,2000)     
                      
                    })
            }
  }


  handle_conversion_Change=(event)=> {
   
    let new_form_data= this.state.conversion_form_data;
    
            let key;
            for (key in new_form_data){

                  if(key===event.target.id){
                        new_form_data[key]=event.target.value;
                        this.setState({login_form_data: new_form_data});
                        
                  } 
            }
           
            this.conversion_form_validation()
           


  }

  conversion_form_validation=(clicked)=>{
     
    if (this.state.conversion_form_data.username!=='' && this.state.conversion_form_data.username.length <6 ){
      this.setState(state => (state.conversion_validation_data.short_username ='Username must be more than 5 characters', state)) 
      
    }
    else{
      this.setState(state => (state.conversion_validation_data.short_username = null, state))
    }


    if (this.state.conversion_form_data.password!=='' && this.state.conversion_form_data.password.length <6 ){
      this.setState(state => (state.conversion_validation_data.short_password ='Password must be more than 5 characters', state)) 
      
    }
    else{
      this.setState(state => (state.conversion_validation_data.short_password =null, state))
    }

    if (this.state.conversion_form_data.confirm_password!=='' && this.state.conversion_form_data.confirm_password!==this.state.conversion_form_data.password){
      this.setState(state => (state.conversion_validation_data.confirm_password ='Password and confirm passord field must be the same', state)) 
      
    }
    else{
      this.setState(state => (state.conversion_validation_data.confirm_password =null, state))
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.conversion_form_data.email) == false && this.state.conversion_form_data.email!==''){
      this.setState(state => (state.conversion_validation_data.confirm_email ='This email address is not valid', state)) 
      
    }
    else{
      this.setState(state => (state.conversion_validation_data.confirm_email =null, state))
    }

    if(clicked==true){
          let new_form_data= this.state.conversion_form_data;
  
          let key;
          for (key in new_form_data){

                if(new_form_data[key]===''){
                    alert('Please fill all fields')
                    return false
                } 
          }

          new_form_data= this.state.conversion_validation_data;
          for (key in new_form_data){

                if(new_form_data[key]!==null){
                    alert('Please correct all the errors in red')
                    return false
                } 
          }

          return true

    }

  }

  get_convert_account_page=()=>{


          let convert_text = "Convert Your Account"
          if(this.state.button_disabled === true){
            convert_text = <ContinouslyAnimate animation_type={"tada"} animation_interval={1000} animate_data={"Converting Your Acccount"}/>    

          }
          let signup_page= <div class="account-container register" style={{width:'25%', minWidth:'320px' }}>

                                  <div class="content clearfix">
                                    
                                    <form action="#" method="post">
                                    
                                      <h2 style={{color:'red'}}>You May Lose This Account !!!</h2>			
                                      
                                      <div class="login-fields">
                                        
                                        <p style={{color:'green'}}>You are using a temporary account to access Learnershub. To avoid losing your account and all the 
                                        data associated with it including question performance and profile information or to become eligible for our 
                                          premium service, Please convert your account to a permanent account for free. All you have to do is fill in your details below.
                                        </p>

                                        <div class="field">
                                        
                                          <label for="username">Username:</label>
                                          <input type="text" key='signup_username' id="username" style={{width:'100%'}} value={this.state.conversion_form_data.username} placeholder="Username" class="login" onChange={this.handle_conversion_Change} required/>
                                          <p style={{color: "red"}}>{this.state.conversion_validation_data.short_username}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="firstname">First Name:</label>
                                          <input type="text" key='sign_upfirst_name' id="first_name" style={{width:'100%'}} value={this.state.conversion_form_data.first_name} placeholder="First Name" class="login" onChange={this.handle_conversion_Change} />
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="lastname">Last Name:</label>	
                                          <input type="text" key="signup_lastname" id="last_name" style={{width:'100%'}} value={this.state.conversion_form_data.last_name} placeholder="Last Name" class="login" onChange={this.handle_conversion_Change} />
                                        </div> 
                                        
                                        
                                        <div class="field">
                                          <label for="email">Email Address:</label>
                                          <input type="email" key='signup_email' id="email" style={{width:'100%'}} value={this.state.conversion_form_data.email} placeholder="Email" class="login" onChange={this.handle_conversion_Change} />
                                          <p style={{color: "red"}}>{this.state.conversion_validation_data.confirm_email}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="password">Password:</label>
                                          <input id="password" type="password" id="password" style={{width:'100%'}} value={this.state.conversion_form_data.password} placeholder="Password" class="login" onChange={this.handle_conversion_Change} />
                                          <p style={{color: "red"}}>{this.state.conversion_validation_data.short_password}</p>
                                        </div> 
                                        
                                        <div class="field">
                                          <label for="confirm_password">Confirm Password:</label>
                                          <input type="password" id="confirm_password" style={{width:'100%'}} value={this.state.conversion_form_data.confirm_password} placeholder="Confirm Password" class="login" onChange={this.handle_conversion_Change} />
                                          <p style={{color: "red"}}>{this.state.conversion_validation_data.confirm_password}</p>
                                        </div> 
                                        
                                      </div> 
                                      
                                      <div class="login-actions">
                                      
                                                  
                                        <button class="button btn btn-primary btn-large" onClick={this.handle_conversion} >{convert_text}</button>
                                        
                                      </div> 
                                      
                                    </form>
                                    
                                  </div> 
                                  
                               


                                </div> 

          return signup_page

  }

  exit_signup_page=(controller)=>{

    if(controller ==="login"){

            this.setState({take_conversion_page_away:true})

            setTimeout(()=>{this.props.history.push("/user-login")  },300)


    }

    else{
        this.setState({take_conversion_page_away:true})

        setTimeout(()=>{this.props.history.push("")  },300)


    }  



  }

  

  get_conversion_success_block=()=>{

    let conversion_success_block= 
    
                                    <Bounce left>
    
                                            <div  >





                                                <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                                    
                                                    <div style={{marginTop:'10%'}}>
                                                      <h2 style={{color:'red'}}> Hurray !!!!!  Your Account Has Been Converted Succesfully</h2>
  
                                                      <img src={cordova_file_prefix + "go.jpg"} style={{width:'50%',  }}></img>
                                                    
                                                    <p style={{color:'green', fontSize:'100%', marginTop:'5%'}}>Your Account has been converted to a permanent account succesfully, Please note the Username/Email
                                                        and password you used as you will need it when next you want to login </p>	
                                                                                             
                                                      <p style={{color:'red'}}>You can now click the exit button at the top right of the page to continue learning</p>
                                                    
                                                   
                                                    </div>     
                                                </div>
	
                                            </div>        
                                      </Bounce>
                                       
        return conversion_success_block




  }
 
  render() {

    let current_page = this.get_convert_account_page()
    
    if (this.state.current_page === 'success_page'){

        current_page = this.get_conversion_success_block()
       
    }
    

      return (
        <Roll right>
                    <Zoom opposite when={!this.state.take_conversion_page_away}>
                                <div >
                                    {current_page}

                                  
                                     

                                                               
                                
                                </div>
                       </Zoom>
          </Roll>



      );
  }
}


class LearningCentre extends Component {

  constructor(props) {
    super(props);
    this.state = {
                      icons : {

                                  exit_mathematics:true,
                                  exit_mathematics_literacy :true,
                                  exit_life_sciences :true,
                                  exit_physical_sciences:true,
                                  exit_business_studies:true,
                                  exit_accounting:true,
                                  exit_economics :true,
                                  exit_agricultural_science: true
                      }

                };
  }

  componentDidMount(){

            if(google_analytics !== null){google_analytics(window.location.pathname)}

            //if(window.location.hostname!=='localhost'){ 
                        // the code below is to trigger analytics to count this page
            //         ReactGA.pageview(window.location.pathname);
                      
              
            
            //}
            
 
    
  }
  

  exit_subject_choice_page =(controller)=>{
   
    let x;
    
    let current_state = this.state.icons
              for (x in current_state){
              
                              if(controller === x){
                               
                                
                                         continue
                                         
                                }
                                else{

                                 current_state[x]= false;

                                }
                                
                  }
                  this.setState({icons:current_state});
                          

                  setTimeout(()=>{this.setState({take_user_choice_away:false})  },700)


                  if(this.state.icons.exit_mathematics ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-mathematics") },1200)    }    
                  if(this.state.icons.exit_mathematics_literacy ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-mathematics_literacy") },1200)    }    
                  if(this.state.icons.exit_life_sciences ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-life_sciences") },1200)    }    
                  if(this.state.icons.exit_physical_sciences ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-physical_sciences") },1200)    }    
                  if(this.state.icons.exit_business_studies ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-business_studies") },1200)    }    
                  if(this.state.icons.exit_accounting ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-accounting") },1200)    }    
                  if(this.state.icons.exit_economics ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-economics") },1200)    }    
                  if(this.state.icons.exit_agricultural_science ===true){    setTimeout(()=>{this.props.history.push("/learning_centre-agricultural_science") },1200)    }    
                 
                  
                


                  
   }

  get_subject_list_page=()=>{

    let page_style =          {}  
    let h1_style =             {}
    let inner_div_style =       {}
    let p_style =            {}
    let logo_style =            {}

    let p_font_size = '100'
    let inner_div_style_margin_top = "6%"
    let logo_style_margin_top = "7%"
    let container_div_style = {}
    
   
            

    if (window.innerWidth / window.innerHeight > 1  ){ 

      

                    p_font_size = '100'
                    inner_div_style_margin_top = "6%"
                    logo_style_margin_top = "7%"

                    
                  
                    if(window.innerWidth / window.innerHeight>1.8){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>1.9){
                      p_font_size =  '170%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }


                    if(window.innerWidth / window.innerHeight>2){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>2.1){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "1%"
                      logo_style_margin_top = "2%"
                    }

                    h1_style =    {fontSize:'150%', color:'green', }
                    page_style = {width:'auto', marginLeft:'9%',  marginTop:'2%',   color:'green', overflow:"auto" }                  
                    inner_div_style = {width:'30%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, }
                      p_style =            {fontSize:p_font_size}
                    logo_style = {marginLeft:"35%", marginTop:logo_style_margin_top, width:'30%'}
                   
                    
    }

    if (window.innerHeight / window.innerWidth >1  ){


          
         
          let p_font_size = '130%'
          let inner_div_style_margin_top = "0%"
          let logo_style_margin_top = "0%"



          h1_style =    {fontSize:'150%', color:'green', }
          page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
          inner_div_style = {width:'100%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top }
            p_style =            {fontSize:p_font_size}
          logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}



          if (window.innerWidth >500 ){

            p_font_size = '150%'
            inner_div_style_margin_top = "0%"
            logo_style_margin_top = "0%"
  

            h1_style =    {fontSize:'150%', color:'green', }
            page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
            inner_div_style = {width:'50%',  borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, marginLeft:'25%' }
              p_style =            {fontSize:p_font_size}
            logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}





          }


     }

                 
        


                      return                  <Bounce up>
                                <div style={{padding:'0% 3% 3% 3%', overflow:'auto'}}>
                                        <h2 style={{color:'green'}}>Learning With Videos</h2>
                                        
                                        <hr/>
                
                                              <div  style={page_style}>
                                                
                                        
                                                        <Zoom  opposite when={this.state.icons.exit_mathematics}>
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_mathematics') }} >
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={0} animate_data={
                                                                    <img src={cordova_file_prefix + "mathematics.jpg"} style={{width:'50%',  }}></img>

                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                    <p style={p_style} ><b>Mathematics</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_mathematics_literacy}>
                                                           
                                                              <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_mathematics_literacy') }}>
                                                                <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={1000} animate_data={
                                                                        <img src={cordova_file_prefix + "mathematics-literacy.png"} style={{width:'50%',}}></img>
                                                                        
                                                               
                                                                }>
                                                                      
                                                              </ContinouslyAnimate>
                                                              <p style={p_style}><b>Mathematics Literacy</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_life_sciences}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_life_sciences') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={2000} animate_data={
                                                                    <img src={cordova_file_prefix + "life-sciences.jpg"} style={{width:'50%', }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Life Sciences</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_physical_sciences}>
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_physical_sciences') }} >
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={3000} animate_data={
                                                                    <img src={cordova_file_prefix + "physical-sciences.png"} style={{width:'50%',  }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                    <p style={p_style} ><b>Physical Sciences</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_business_studies}>
                                                          
                                                                <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_business_studies') }}>
                                                                  <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={4000} animate_data={
                                                                        <img src={cordova_file_prefix + "business-studies.png"} style={{width:'50%',}}></img>
                                                                      }>
                                                                      
                                                                      </ContinouslyAnimate>
    
                                                                        <p style={p_style}><b>Business Studies</b></p>
                                                                </div>
                                                           
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_accounting}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_accounting') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={5000} animate_data={
                                                                    <img src={cordova_file_prefix + "accounting.png"} style={{width:'50%', }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Accounting</b></p>
                                                            </div>
                                                        </Zoom>

                                                        <Zoom opposite when={this.state.icons.exit_economics}>
                                                         
                                                                <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_economics') }}>
                                                                <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={6000} animate_data={
                                                                        <img src={cordova_file_prefix + "economics.png"} style={{width:'50%',}}></img>
                                                                      }>
                                                                      
                                                                      </ContinouslyAnimate>
                                                                        <p style={p_style}><b>Economics</b></p>
                                                                </div>
                                                           
                                                        </Zoom>

                                                        <Zoom  opposite when={this.state.icons.exit_agricultural_science}>
                                                            <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_subject_choice_page('exit_agricultural_science') }}>
                                                            <ContinouslyAnimate animation_type={'jump'} animation_interval={8000} animation_delay={7000} animate_data={
                                                                    <img src={cordova_file_prefix + "agricultural_science.png"} style={{width:'50%', }}></img>
                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>
                                                                    <p style={p_style}><b>Agricultural Science</b></p>
                                                            </div>
                                                        </Zoom>
                                          
                                          
                                          
                                                        
                                          
                                              </div> 

                                              <button  style={{ width:'20%', fontSize:'100%', minWidth:"200px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{this.props.history.push("/dashboard")}}  >Back to Dashboard</button>  
                                                            
                              </div>
                </Bounce>





  }

 
  render() {

      let current_page = this.get_subject_list_page()

    

      return (
              <div>
                  
                      {current_page}

                      <Helmet>
                                              <title>Learn With Videos</title>  
                                            
                                             
                                            

                     </Helmet>

                   

              </div>




      );
  }
}

class SubjectTopics extends Component {

  constructor(props) {
    super(props);
    this.state = {

                    
                                     subject_name : '',

                                      topic_list : [                                                       
                                                  ]     ,

                                      current_topic:'',    

                                      video_list :[

                                               ] ,  

                                show_video_list_page : false,
                                
                                button_disabled: false,
                                current_topic_key :  "",
                                current_subject_key:"",
                                response_profile_data : ""
                                    

                };
  }

  componentDidMount(){

          this.set_subject_data_to_state() //react ga for analythics is fired inside this component
          

  }

  set_subject_data_to_state=()=>{


    let subject_path = window.location.pathname
    let subject_name = ''
    let topic_list=[]
    let current_subject_key = ""
   

    if(subject_path=== "/learning_centre-mathematics"  ){

        
                                subject_name = 'Mathemathics'
                                topic_list =[ 
                                              "Functions",
                                              "Number Patterns, Sequences and Series",
                                              "Finance, Growth and Decay",
                                              "Algebra",
                                              "Differential Calculus",
                                              "Probability",
                                              "Euclidean Geometry and Measurement",
                                              "Analytical Geometry",
                                              "Trigonometry",
                                              "Statistics",
                                              
                                             
                                            ]

                                   current_subject_key = "mathematics"


    }

    
    if(subject_path=== "/learning_centre-mathematics_literacy"  ){ 

     
                              subject_name = 'Mathematical Literacy'
                              topic_list =[ 
                                              "Finance",
                                              "Measurement",
                                              "Maps, Plans and other Representations of the Physical World",
                                              "Data Handling",
                                              "Probability",
                                              "Interpreting and Communicating Answers and Calculations",
                                              "Patterns, Relationships and Representations",
                                              "Numbers and Calculations with Numbers",
                                              
                       
                                          ]

                              current_subject_key = "mathematical_literacy"

                            

    }


    if(subject_path=== "/learning_centre-life_sciences"  ){

   
                              subject_name = 'Life Sciences'
                              topic_list =[ 
                                              "Life at Molecular, Cellular, and Tissue Level",
                                              "Life Processes in Plants and Animals",
                                              "Diversity, Change and Continuity",
                                              "Environmental Studies",
                                              
                                          ]

                             current_subject_key = "life_sciences"

                         


     }
    if(subject_path=== "/learning_centre-physical_sciences"  ){


    
                              subject_name = 'Physical Sciences'
                              topic_list =[
                                            "Mechanics",
                                            "Waves, Sound and Light",
                                            "Electricity and Magnetism",
                                            "Matter and Materials",
                                            "Chemical Systems",
                                            "Chemical Change",
                                             
                                           
                                          ]

                              current_subject_key = "physical_sciences"
                              

                           
                     







     }
    if(subject_path=== "/learning_centre-business_studies"  ){ 

                                  subject_name ='Business Studies'
                                  topic_list =[ 
                                               "Micro, Market and Macro Environments",
                                               "Business Sectors",
                                               "Contemporary Socioeconomic Issues",
                                               "Entrepreneurship",
                                               "Business Opportunity and Related Factors",
                                               "Business Plan",
                                               "Management and Leadership",
                                               'Forms of Ownership',
                                               "Setting up a Business",
                                               "Contracts",
                                               "Business Location",
                                               "Investment: Securities and Insurance",
                                               "Presentation of Business Information",
                                               "Creative Thinking and Problem Solving",
                                               "Self-Management, Professionalism and Ethics",
                                               "Human Rights, Inclusivity and Environmental Issues",
                                               "Social Responsibility",
                                               "Stress, Crisis, Change and Conflict Management",
                                               "Relationship and Team Performance",
                                               "Business Functions",
                                               "Quality of Performance",
                                              
                                              
                                              ]

                                  current_subject_key = "business_studies"
                 








    }
    if(subject_path=== "/learning_centre-accounting"  ){ 


                   
                                            subject_name = 'Accounting'
                                            topic_list =[ 
                                                        "Accounting Concepts",
                                                        "GAAP Principles",
                                                        "Bookkeeping",
                                                        "Accounting Equation",
                                                        "Final Accounts and Financial Statements",
                                                        "Salaries and Wages",
                                                        "Value-Added Tax",
                                                        "Reconciliations",
                                                        "Cost Accounting",
                                                        "Budgeting",
                                                        "Indigenous Bookkeeping Systems",
                                                        "Fixed Assets",
                                                        "Inventory",
                                                        "Ethics",
                                                        "Internal Control",
                                                       
                                                        
                                                        ]
                                            current_subject_key = "accounting"
                                    


                                          




    }
    if(subject_path=== "/learning_centre-economics"  ){ 

                                  subject_name ='Economics'
                                  topic_list =[ 
                                              "Economics: Basic Concepts",
                                              "Basic Economic Problem",
                                              "Circular Flow",
                                              "Quantitative Elements",
                                              "Economic Systems",
                                              "Business Cycles",
                                              "Dynamics of Markets",
                                              "Public Sector Intervention and Composition of The RSA Economy",
                                              "Economic Growth and Development",
                                              "Money and Banking",
                                              "Population and Labour Force",
                                              "Labour Market",
                                              "Redress Since 1994",
                                              "Unemployment",
                                              "Labour Relations",
                                              "Globalisation",
                                              "Inflation",
                                              "Tourism",
                                              "Poverty",
                                              "Other Economic Issues and Quantitative Elements of Economics"
                                              
                                              ]

                                  current_subject_key = "economics"
                            





    }
    if(subject_path=== "/learning_centre-agricultural_science"  ){ 

   
                                subject_name = 'Agricultural Sciences'
                                topic_list =[ 
                                            "Soil Science",
                                            "Plant Studies",
                                            "Animal Studies",
                                            "Agricultural Economics",
                                            "Basic Agricultural Chemistry",
                                            "Basic Genetics and Biological Concepts",
                                            "Sustainable Natural Resource Utilization",
                                           
                                            
                                            ]
                                current_subject_key = "agricultural_science"
                              


                                



                              



    }


    this.setState({subject_name:subject_name});
    this.setState({topic_list:topic_list});
    this.setState({current_subject_key:current_subject_key});
   


    
    if(google_analytics !== null){google_analytics(window.location.pathname)}


    //  if(window.location.hostname!=='localhost'){ 
                    // the code below is to trigger analytics to count this page
             //    ReactGA.pageview(window.location.pathname );
                  
         
       
    //   }
    


  }

  set_video_list_data_to_state=(item)=>{
                
              let video_list = []
              let topic_key = ""

              if(  item === "Functions" ){

                  video_list =   [ 
                                                        "https://www.youtube.com/embed/qaYWVaoo7lE?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                        "https://www.youtube.com/embed/RdBr0wZ8TBw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                        "https://www.youtube.com/embed/QvzSusqm2Uo?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                        "https://www.youtube.com/embed/41dfOISJ1gc?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                        "https://www.youtube.com/embed/hzmnrtwIFas?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",                                 
                                                                                            
                                        ]

                  topic_key = "functions"                      

                

              }
              if(  item === "Number Patterns, Sequences and Series" ){

                video_list =   [ 
                                                    "https://www.youtube.com/embed/ezFLu-DiqL8?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                    "https://www.youtube.com/embed/H6rVcFCxU3g?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                    "https://www.youtube.com/embed/Czf8wxk08ZI?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                   
                                      ]

                topic_key = "number_patterns_sequences_and_series"
                                

              }
              if(  item === "Finance, Growth and Decay" ){

                video_list =   [ 
                                                    "https://www.youtube.com/embed/6Dp-47Izy6c?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                   
                                                   
                                      ]

                let topic_key = "finance_growth_and_decay"

              }
              if(  item === "Algebra" ){

                video_list =   [ 
                                        "https://www.youtube.com/embed/kKS_Sp9rdIk?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                        "https://www.youtube.com/embed/iFAClsEiaEw" ,
                                        "https://www.youtube.com/embed/tlc-NLF655U",
                                        "https://www.youtube.com/embed/nv3941Z3CEA",
                                        "https://www.youtube.com/embed/_diDtos-m-g",
                                        "https://www.youtube.com/embed/NkgUbT3jSms",
                                        "https://www.youtube.com/embed/RdBr0wZ8TBw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                        "https://www.youtube.com/embed/kKS_Sp9rdIk?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",                                         
                                                   
                                      ]

                topic_key = "algebra"

              }
              if(  item === "Differential Calculus" ){

                video_list =   [ 
                                      "https://www.youtube.com/embed/xrV6Ki_HjrU",
                                      "https://www.youtube.com/embed/O69PEVqiSA4?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                      "https://www.youtube.com/embed/BfYYtZ__6Vw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                      "https://www.youtube.com/embed/H-fOyKCF_rQ?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                                                                                                                                  
                                      ]
                topic_key = "differential_calculus"
              
              }
              if(  item === "Probability" ){

                video_list =   [ 
                                  "https://www.youtube.com/embed/jBHQRUdKczU",
                                  "https://www.youtube.com/embed/H9RWXRrFl_g?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                  "https://www.youtube.com/embed/G1_Cef44wvY",
                                  "https://www.youtube.com/embed/aegBgqk5jeg",
                                  "https://www.youtube.com/embed/GzhtyG6mpR0?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                  "https://www.youtube.com/embed/QXNlYii4FO0",

                                                                                                                                              
                                      ]

                topic_key = "probability"

              }
              if(  item === "Euclidean Geometry and Measurement" ){

                video_list =   [ 
                                      "https://www.youtube.com/embed/1eDcTKo4PLk",
                                      "https://www.youtube.com/embed/mhzrcbYF6z8",
                                      "https://www.youtube.com/embed/Jfh7q-Z5GIE",
                                      "https://www.youtube.com/embed/cxKFnEKYZ4o?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                      "https://www.youtube.com/embed/ctqph6PmYbg?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                            
                                    ]
                topic_key = "euclidean_geometry_and_measurement"
              

              }
              if(  item === "Analytical Geometry" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/fPSO0iKFDQU?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/2jx3DZ_RCJw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                                                           
                                   ]

                topic_key = "analytical_geometry"

              }
              if(  item === "Trigonometry" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/ZPycsC0Ahgw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/YrcqSI15-qw?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/ANbSpgx4Cps?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/bDhL_RIbrrc?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/bYdGf2XwvoQ?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/CIebjSwG8NQ?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                 
                                                           
                                   ]

                topic_key= "trigonometry"

              }
              if(  item === "Statistics" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/P8reqGdGdUs?list=PLOaNAKtW5HLTQxVh0JlBhwYwJuHoejzGx",
                  "https://www.youtube.com/embed/ChFWM0kjMlI",
                  "https://www.youtube.com/embed/Pw2F6KFXKEU",
                  "https://www.youtube.com/embed/33VpKgb_USw",
                  "https://www.youtube.com/embed/bTxRcM7cO9k",

                                                           
                                   ]

                topic_key = "statistics"

              }

              // the list for mathematics literacy starts here

              if(  item === "Finance" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/GF57Fx3jvTg?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/Y_l9B3f8CEM?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/JLgfQMTvDs8?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/fuevaqnqisQ?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/CxHDf1K80wI?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/h3yQhAl6gAA?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/-SpFrSTS1w8?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/R_B7Xbhi5XQ?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/d7XkPrJvN3w?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/SNxCLgujozs?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/KDOM1u_XEBU?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",

                                                 
                                   ]

                topic_key = "finance"

              }
              if(  item === "Measurement" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/7SCtLxmZ_wM?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/Y58TdGcLaUU?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/9JX-sJFjN9E?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/g30WNcDhkbM?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/TEQNpXsh-x8?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/gIpu0Frfcas?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",

                                                 
                                   ]

                topic_key = "measurement"

              }
              if(  item === "Maps, Plans and other Representations of the Physical World" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/ZD5GMtDcHCI?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/F6cOv90Sdio?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/zGGYERQWJ8c?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/hYj1o_wcnis?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/D8wHqvLWH10?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                                                         
                                   ]

                topic_key = "maps_plans_and_other_representations_of_the_physical_world"

              }
              if(  item === "Data Handling" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/39VAZsS8ctU?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/xSo6u0S40To?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/6T8g7BXYJjY?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                
                                   ]
                topic_key = "data_handling"
              

              }
              if(  item === "Probability" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/AQHcTSNwQtk?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                  "https://www.youtube.com/embed/NV9b18jCJMg?list=PLOaNAKtW5HLRNEYj9c5AG_eD_jByjUHNB",
                         ]

                topic_key = "probability"            

              }
              if(  item === "Interpreting and Communicating Answers and Calculations" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/E8khYB4jUqM",
                  "https://www.youtube.com/embed/lQKutQngCSE",
                

                         ]

                topic_key = "interpreting_and_communicating_answers_and_calculations"

              }
              if(  item === "Patterns, Relationships and Representations" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/gpBzeXS4Cns",
                  "https://www.youtube.com/embed/ughLl5ZM9CM",
                  "https://www.youtube.com/embed/GIVQlDgNORE",
                              

                         ]
                topic_key = "patterns_relationships_and_representations"
              

              }
              if(  item === "Numbers and Calculations with Numbers" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/cZYD2kuJdSw",
                  "https://www.youtube.com/embed/qHNkZcJ__14",
                  "https://www.youtube.com/embed/2-UyjyU7s_Q",
                  "https://www.youtube.com/embed/4ZL1_jKp-d4",
                  "https://www.youtube.com/embed/Ym2hSkSLMFU",
                  "https://www.youtube.com/embed/aYgvKpMWmjc",
                  "https://www.youtube.com/embed/s2FD-lmfJnk",
                  "https://www.youtube.com/embed/xyQXmPrvYYg",
                  "https://www.youtube.com/embed/aYgvKpMWmjc",
                  "https://www.youtube.com/embed/s2FD-lmfJnk",
                  "https://www.youtube.com/embed/6Tfjv40ZfEY",
                  "https://www.youtube.com/embed/xyQXmPrvYYg",
                  "https://www.youtube.com/embed/aYgvKpMWmjc",
                  "https://www.youtube.com/embed/s2FD-lmfJnk",
                  "https://www.youtube.com/embed/6Tfjv40ZfEY",
                  "https://www.youtube.com/embed/EpsGjYmgClw",
                  "https://www.youtube.com/embed/3YAFpedF3OQ",
                  "https://www.youtube.com/embed/xyQXmPrvYYg",
                  "https://www.youtube.com/embed/EImbPUN5cws",
                  "https://www.youtube.com/embed/_XHZnWlA9aU",
                  "https://www.youtube.com/embed/_XHZnWlA9aU",
                  "https://www.youtube.com/embed/MBm2GLx8DSg",
                  "https://www.youtube.com/embed/2VkQ5g3vfRE",
                  "https://www.youtube.com/embed/n-zAkZiaGBg",
                  "https://www.youtube.com/embed/lQKutQngCSE",
                  "https://www.youtube.com/embed/E8khYB4jUqM",
                  "https://www.youtube.com/embed/E8khYB4jUqM?list=PLOaNAKtW5HLQ4-qqY8PXN5KLx_LZ2hxse",


                              

                         ]
                
                topic_key = "numbers_and_calculations_with_numbers"
                

              }

               // the list for life sciences starts here

              if(  item === "Life at Molecular, Cellular, and Tissue Level" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/LsBK4dRQwlA?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/pkZ9zl6bIiM?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/Q3yg02jpkYU?list=PLOaNAKtW5HLS7IlTWo6-HDC6bBVIqt6gn",
                  "https://www.youtube.com/embed/i6nLq4zNdeU?list=PLOaNAKtW5HLS7IlTWo6-HDC6bBVIqt6gn",
                  "https://www.youtube.com/embed/gamrRYzPjXk?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/kE9g-74s300?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/KWcfzBrx5ok?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/rWS90UtQRLA?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/KWcfzBrx5ok?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/fiLoA2v5yRQ?list=PLOaNAKtW5HLS7IlTWo6-HDC6bBVIqt6gn",
                  "https://www.youtube.com/embed/J1J_30H0DnE?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/nZpXnlRbnvE?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/dlgl2RWD8_Q?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/w7jXy3vZ-_E?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/I9TP4uV7blo?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/XpVtMBVkgOw?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/BBxPgW9lQjE?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/qeoX8GT8fx8",
                  "https://www.youtube.com/embed/I9TP4uV7blo?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/Y7RlU26-ivE?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/r9JgLA1XHNg?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/nYtFuPzpeeM?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/ccibr1AUKkM?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/lZjpGUZfskw?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/Pb2NISLHew4?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/I9TP4uV7blo?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/OdxTJDZlzlU?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/ebGghjo5O6A?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",

                              

                         ]

                topic_key = "life_at_molecular_cellular_and_tissue_level"

              }
              if(  item === "Life Processes in Plants and Animals" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/3i_q1jAAFJk?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/zSYMuPXcrNU?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/ZvXI1BW29g4?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/B6wz1J2BBvo?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/PYYbBbfrVs8?list=PLOaNAKtW5HLS7IlTWo6-HDC6bBVIqt6gn",
                  "https://www.youtube.com/embed/95FdlDAxSq0?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/KQtmeiUDHu0?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/B1Y10petXoQ?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/FP6iJfhX0xE?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/T960wxGxcnQ?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/-lmi9bdQB54?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/_Js7xw0yoLw?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/G_NL6raRL5U?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/Snu_fmyfqUQ?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/v5M5ipwCD14?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/mxeJoGfY_CI?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/Cv2wCayTeJ0?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/8ws1r-tZ658?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/9mGe65dr9xM?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/qW7E_a7v0Wo?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/-uN8NwZt4pU?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/fF0HYXQ3A5g?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/qBkYPK-YI2k?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/z2rUEnztNcM?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/EweoXhExoaE?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/o6_3LaufkYw?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/qwKfOo79UQM?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/hkVWRKeQTU0?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/ojCxGgjb3AA?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/1f8-7p5RDPE?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/hkVWRKeQTU0?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/ce3M1Xwhsas?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/IMhyrSwVM8c?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/s2f_khp6p2c?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/g_emTA21PNQ?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/Lq1ircqdlBM?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/3yup3lcFArc?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/4k-sd7lMpRs?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/VlrEln7Gq1I?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/ifBJheSUd04?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/mjrYI3gZXEI?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/RF1BTF0syW4?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/lydJT6lUe0k?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/6QscDrFyVlE?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/hzqMQtdAtxw?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/gxBuA7JdNHA?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/aK8tuu8SVBg?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/fXI92wJEizw?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/K83Kf6XxAiI?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/GFlUdTs4sjk?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/7WFsqoG4ePQ?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/nIuQ6-fHxbI?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/BsJDqVfKck8?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/iuxaJKwDdGA?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/7WFsqoG4ePQ?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/nIuQ6-fHxbI?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                                              

                         ]

                topic_key = "life_processes_in_plants_and_animals"

              }
              if(  item === "Diversity, Change and Continuity" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/waylMQhMACw?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/jINRLEYp3ck?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/gXzP4D83B3E?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/0IUb01N-cow?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/HySSnoexUQ8?list=PLOaNAKtW5HLSvEFJB6mSWCdUZgGVf_ahS",
                  "https://www.youtube.com/embed/OtxAMWWFhC4?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/cskX0lzykS8?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/UiuyNL8FFsc?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/wsEecTeskMk?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/tTNyOGf0Acc?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/rB0Xe90Yd-E?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/uG4PoIFouPs?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/B4GFIvirB_w?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",

                              

                         ]

                topic_key = "diversity_change_and_continuity"

              }
              if(  item === "Environmental Studies" ){

                video_list =   [ 
                  "https://www.youtube.com/embed/FXEyIGnb76s?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/rQHY5PInwsY?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/S9s9JFmI3vQ?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/S9s9JFmI3vQ?list=PLOaNAKtW5HLSP0_36GoAq3zmCE-UZoq_c",
                  "https://www.youtube.com/embed/QfTksP4q21M?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/J5esIR6nPmE?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/Txt7p3mJvnA?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/J5esIR6nPmE?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/Txt7p3mJvnA?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/LjDCaEIexZY?list=PLOaNAKtW5HLRVviGcDRDLzfezhqXdLygU",
                  "https://www.youtube.com/embed/w_mLMJ3bipw?list=PLOaNAKtW5HLRXOSpu72XK6vU3XYtYaAzE",
                  "https://www.youtube.com/embed/bBaViI8OvQM?list=PLOaNAKtW5HLTO1XoZX23QHmMKQJffZoYG",
                  "https://www.youtube.com/embed/Nit3PM9opus?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",
                  "https://www.youtube.com/embed/9LG1xCKBQXM?list=PLOaNAKtW5HLRlfQk9D_ZQk1phbKYWaYDX",


                         ]

                topic_key = "environmental_studies"

              }

                // the list for physical sciences starts here
              
              if(  item ===  "Mechanics" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/ipfj3knrIjc?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                    "https://www.youtube.com/embed/z5G0LIg8sBY?list=PLOaNAKtW5HLQCU9ZzFVG0Obf-dW8unZ_G",
                    "https://www.youtube.com/embed/BDUD8Vdl8NI?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                    "https://www.youtube.com/embed/pYFegwyhZdc?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                    "https://www.youtube.com/embed/8LagHZaoiv4?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                    "https://www.youtube.com/embed/fCz4GQZCck0?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                    "https://www.youtube.com/embed/nJs4HkF9N18?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                    "https://www.youtube.com/embed/aufBZFnflW4?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                    "https://www.youtube.com/embed/6UOAXfEZzNM?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_",
                    "https://www.youtube.com/embed/dfcwhIpybvc?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                    "https://www.youtube.com/embed/ky4RiLiyC3U?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/4eiZYYcr2TI?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/pDNxjNOy6tY?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                    "https://www.youtube.com/embed/w60J_At6K-E?list=PLOaNAKtW5HLQCU9ZzFVG0Obf-dW8unZ_G",
                    "https://www.youtube.com/embed/5_sVypdh4AE?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/3I_MLCgN2Uc?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/kYxQ0bAY3-0?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/vkirShj8WSU?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/wVBnsOtVc7s?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                    "https://www.youtube.com/embed/SYJtx61ZuWY",
                    "https://www.youtube.com/embed/-fv0MveTEy4?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH",
                    "https://www.youtube.com/embed/GrjMbTN_DVU?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                    "https://www.youtube.com/embed/ytZCDRA1e8U?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                    "https://www.youtube.com/embed/ytZCDRA1e8U?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                    "https://www.youtube.com/embed/LU2JkdvH7Nk?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                    "https://www.youtube.com/embed/F5shk4vxjo8?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                    "https://www.youtube.com/embed/LpKVpSj1pso?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                    "https://www.youtube.com/embed/Kpisbb-KVCc?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                    "https://www.youtube.com/embed/9Av01MoGN9I" ,
                    "https://www.youtube.com/embed/s8Lcgj38YHE?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                    "https://www.youtube.com/embed/MY5qLFrXRYY?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                    "https://www.youtube.com/embed/k8f5BbEI6rY?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                    "https://www.youtube.com/embed/T6jO-RQHTj8?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                    "https://www.youtube.com/embed/N9_aOJKdjq0?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                    "https://www.youtube.com/embed/LQjSVaPcy7o?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                   "https://www.youtube.com/embed/N7b6yJhc7wY?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                   

  
                           ]
                           
                  topic_key = "mechanics"
                
  
              }
              if(  item ===  "Waves, Sound and Light" ){

                video_list =   [ 
                 
                 "https://www.youtube.com/embed/G8wveIe6I_A?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/GNqNPY9Ivrg?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/q_Nc7NI6-KI?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/3aB1rtEPD9g?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                  "https://www.youtube.com/embed/SggTZPWgYBY",
                  "https://www.youtube.com/embed/k1Z-CkTY8Os",
                  "https://www.youtube.com/embed/B_8oc0lknpA?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/j17Ua_k4b4M?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/5K0Aw6MCliY?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/kI7xSwZBtG4?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/eEdbztoFokg?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/2VPQAEsFk60?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/rxNArrksOdM?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                  "https://www.youtube.com/embed/fy8pZoM-ciQ?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                  "https://www.youtube.com/embed/JclCoKJPzZM?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                             

                         ]

                topic_key = "waves_sound_light"

               }
               if(  item === "Electricity and Magnetism" ){

                video_list =   [ 
                 
                 "https://www.youtube.com/embed/8HTn-psooTw?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                 "https://www.youtube.com/embed/kSiCURyAIlU?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                 "https://www.youtube.com/embed/oMkifOmyoUk?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                 "https://www.youtube.com/embed/xL2oxTgk04E?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                 "https://www.youtube.com/embed/_vK0qcGd8Hw?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                 "https://www.youtube.com/embed/gw8cuZsBnhI?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/UINxhc8Lpsc?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                 "https://www.youtube.com/embed/W6R0BXQWNsU?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                 "https://www.youtube.com/embed/3LC65zxIIgY?list=PLOaNAKtW5HLQKYasZKPZSuXssMfM5pspL",
                 "https://www.youtube.com/embed/hJy6YDm7SAA?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/aMh9A2ueq-Q?list=PLOaNAKtW5HLQZzL89u8lgtJBvMN_Esq6r",
                 "https://www.youtube.com/embed/dmZNJL5v8PQ?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/bnFrhgukNLA?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH",
                 "https://www.youtube.com/embed/PY2fQMA76mA?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/ItsGCs66js4?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                  "https://www.youtube.com/embed/wvrXLlC7YmI?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                 "https://www.youtube.com/embed/jQ7CTBhmGBU?list=PLOaNAKtW5HLQZzL89u8lgtJBvMN_Esq6r",
                  "https://www.youtube.com/embed/VmXF91CTuKU?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                 "https://www.youtube.com/embed/ECylOGl5R7c?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/VmXF91CTuKU?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                 "https://www.youtube.com/embed/h-jMwtJ5rWM?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/gsECYAH0Q38?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH",
                 "https://www.youtube.com/embed/glxYuYJEVtU?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/IsHnx19CnB4?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/Ok5FUFc070Q?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/T96j2ZPAJ30?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                 "https://www.youtube.com/embed/pXx96UT7ykU?list=PLOaNAKtW5HLQKYasZKPZSuXssMfM5pspL",
               
                         ]

                topic_key = "electricity_and_magnetism"

               } 
               if(  item ==="Matter and Materials" ){

                video_list =   [ 
                 
                  "https://www.youtube.com/embed/59ZYFN0JJ04?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR" ,
                  "https://www.youtube.com/embed/lieBZ6BdU2I?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR" ,
                  "https://www.youtube.com/embed/ZVMb36WDoKo?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR" ,
                  "https://www.youtube.com/embed/Hx9rRnxckwc?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/7WqNIy_Z9pE?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/9kReuwCEEzY?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR" ,
                  "https://www.youtube.com/embed/RoKWjrkYZNw?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR" ,
                  "https://www.youtube.com/embed/Mf8lUfD7pYc?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/rHSPAWuy4ug?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/1dInhpWgSps?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR" ,
                  "https://www.youtube.com/embed/lW7MK4IRGks?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR" ,
                  "https://www.youtube.com/embed/pQecDa7VYrI?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR" ,
                  "https://www.youtube.com/embed/PvsUpdi1oEQ?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/cSvB4snxu00?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/7es9FYR_Vjk?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH" ,
                  "https://www.youtube.com/embed/9qrwh85T4y0?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/9qrwh85T4y0?list=PLOaNAKtW5HLS_3ygWjWd_6rfq97V8iPv_" ,
                  "https://www.youtube.com/embed/NomX7qVYFGk?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/x7tTORWGuAE?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/O4aDytwlrUY?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/KO8AjilI0vA?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/8gbZrhr_jUo?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/4-wjzaweI38?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR" ,
                  "https://www.youtube.com/embed/pkoRIVCFjrM?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/xm_b0IAF65Y?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/MHplAePqtKo?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                  "https://www.youtube.com/embed/KiTi8ycqZGc?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/NqIBVA5IeII?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/kV9WGbpm0iE?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/w0sBiVBYGQE?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5" ,
                  "https://www.youtube.com/embed/qPJ690-0J1s?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/uf9779qFx00?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/LrZn93EOMZY?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/XJ2TKnWZOBo?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/uf9779qFx00?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/LrZn93EOMZY?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/vHhlX0pvEbU?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/8zHgStUcZe4?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/GyPW4MW4sFw?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/iyAfygZDaVo?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/rQ-aHCy8vkE?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                  "https://www.youtube.com/embed/T6nCrfs0UpY?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
              


              
               
                         ]

                topic_key = "matter_and_materials"

               }
               if(  item === "Chemical Systems" ){

                video_list =   [ 
                 
                  "https://www.youtube.com/embed/PrBGsx11iZ0" ,
                 "https://www.youtube.com/embed/GJMbLQtmiy4" ,
                  "https://www.youtube.com/embed/dk8CrwdqElo" ,
                 "https://www.youtube.com/embed/IXBgB689aYg?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB" ,
                  "https://www.youtube.com/embed/yLa5VkNaBqs?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/R4OSuJCOyFk?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/R4OSuJCOyFk?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0" ,
                  "https://www.youtube.com/embed/fMVBhviH8Jw?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                  "https://www.youtube.com/embed/mxZR50nQYx0?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW" ,
                  "https://www.youtube.com/embed/nN4_xxCQDHg?list=PLOaNAKtW5HLQKYasZKPZSuXssMfM5pspL" ,
                 
                 
               
                         ]
                topic_key = "chemical_systems"
              

               }  
               if(  item === "Chemical Change" ){

                video_list =   [ 
                 
                  "https://www.youtube.com/embed/dekrdq7qHmU",
                  "https://www.youtube.com/embed/kKyMiFXltqU",
                  "https://www.youtube.com/embed/a88gYmEHnH8",
                  "https://www.youtube.com/embed/CVhgpjURr3c",
                  "https://www.youtube.com/embed/cvohPwMI83k",
                  "https://www.youtube.com/embed/PXZeRbsIcr8",
                  "https://www.youtube.com/embed/cItwORn9edE?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/5gHaMpiPIVA?list=PLOaNAKtW5HLQZEAedABd6yMi2HIFd-emR",
                  "https://www.youtube.com/embed/1Ra6f47iUhw",
                  "https://www.youtube.com/embed/BD1A6nzsnE4",
                  "https://www.youtube.com/embed/Qo--ZjJFCMw?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/4qGKqPqRnEs?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/bqDmM_ecmT0?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH",
                  "https://www.youtube.com/embed/U4FFoZNMC8U?list=PLOaNAKtW5HLQgpwlNn6mM7x8SRpSoV9MH",
                  "https://www.youtube.com/embed/DFIZt7WdGrk?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                  "https://www.youtube.com/embed/JG1x5C6cv8Y?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/5iOS1Y-cYnU?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/VX7lw9KOPno?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/VNQEka4xB8E?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/qZpKIcuxY9M?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",
                  "https://www.youtube.com/embed/0pvnisxEkew?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/pI05taMZnJs?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/wHXjtxuQv1c?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/0slY9F8nTfE?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/SzHh8hjOImM?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/TlwCv2LWvo8?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/JJKjqh0Ys18?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/LVuzwPJz0Og?list=PLOaNAKtW5HLR8rC_Ul8mkwveSQW7kshP5",
                  "https://www.youtube.com/embed/bwzfp3hLp3c?list=PLOaNAKtW5HLQEJw--_lJl4-niq8x1CUxR",
                  "https://www.youtube.com/embed/I2y6m1utZDA?list=PLOaNAKtW5HLQCwcxwpgK3t6ECeFVaiLVB",
                  "https://www.youtube.com/embed/aMaLYaOCa2A?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/P083xxBMx7o?list=PLOaNAKtW5HLTPwt6ZFBXGl96gRJ3c5TJ0",
                  "https://www.youtube.com/embed/AFn5m2vLGjM?list=PLOaNAKtW5HLTQE8dQBt8iKuzuVLS2XqQW",

               
                         ]

                topic_key = "chemical_change"

               }  

                 // the list for business stidies starts here

                 if(  item === "Micro, Market and Macro Environments" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/ptl6KWXvAzw?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/mlXGF0c1hXw?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",
                    "https://www.youtube.com/embed/wD6rjkMNUF8?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                
                  ]
                   
                  topic_key = "micro_market_and_macro_environments"
                
  
                 }
                 if(  item === "Business Sectors" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/ptl6KWXvAzw?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/RT7LQmCMvI0?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                   "https://www.youtube.com/embed/ydxcdyTGFk4?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                                 
                  ]
  
                  topic_key = "business_sectors"
                
  
                 }
                 if(  item === "Contemporary Socioeconomic Issues" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/4_2V_efcmOM?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  
               
                                 
                  ]
                   topic_key = "contemporary_socioeconomic_issues"
                
  
                 }
                 if(  item === "Entrepreneurship" ){

                  video_list =   [ 
                  
                    "https://www.youtube.com/embed/BZex0om-sew?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",
                    
                  ]
  
                  topic_key = "entrepreneurship"
  
                 }
                 if(  item === "Business Opportunity and Related Factors" ){

                  video_list =   [ 

                   "https://www.youtube.com/embed/8ps5-QzVufg?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                   "https://www.youtube.com/embed/eAwqjPeP7_8?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                   "https://www.youtube.com/embed/EGSylSUwvH8?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                   "https://www.youtube.com/embed/aQR6OS5yV9s?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                                   
                  ]
  
                  topic_key = "business_opportunity_and_related_factors"
  
                 }
                 if(  item ===  "Management and Leadership" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/8ps5-QzVufg?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/Xkz79y-7Okc?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                
                                   
                  ]

                  topic_key = "management_and_leadership"
  
                
  
                 }   
                 if(  item ===   'Forms of Ownership' ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/8ps5-QzVufg?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/BZex0om-sew?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",
                    "https://www.youtube.com/embed/XrSvAvm4xiM?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                    "https://www.youtube.com/embed/Ml4_7bircDM?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",

                
                                   
                  ]
                   
                  topic_key = "forms_of_ownership"
                
  
                 }
                 if(  item ===   "Contracts" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/xaSHwlhWddI?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  "https://www.youtube.com/embed/L-CsuKZHNbY?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  
                                               
                  ]
  
                  topic_key = "contracts"
  
                 }
                 if(  item ===    "Investment: Securities and Insurance" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/8ps5-QzVufg?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/BZex0om-sew?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",
                    "https://www.youtube.com/embed/EGSylSUwvH8?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                   "https://www.youtube.com/embed/goz2VMbPga8?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                    "https://www.youtube.com/embed/p9xrC-go_Bk?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                                               
                  ]
  
                  topic_key = "investment_securities_and_insurance"
  
                 }   
                 
                 if(  item ===    "Presentation of Business Information" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/BZex0om-sew?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",

                                               
                  ]
  
                  topic_key = "presentation_of_business_information"
  
                 }  

                 if(  item ===    "Creative Thinking and Problem Solving" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/1WdHyjk3oZM?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                     
                  ]
  
                  topic_key = "creative_thinking_and_problem-solving"
  
                 } 

                
                 if(  item === "Human Rights, Inclusivity and Environmental Issues" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                   "https://www.youtube.com/embed/BZex0om-sew?list=PLOaNAKtW5HLQoyxs_8dJblkt0WnEvbTL4",
                   "https://www.youtube.com/embed/x5gzkG6Wj18?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                   "https://www.youtube.com/embed/2XtbBzGs7RU?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",

                  ]
                  
                  topic_key = "human_rights_inclusivity_and_environmental_issues"
                
  
                 }
                 if(  item === "Social Responsibility" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z" ,
                  ]
                  topic_key = "social_responsibility"
                
  
                 } 
                 if(  item ===  "Stress, Crisis, Change and Conflict Management" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/pba2Qb3vc74?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  ]
                   topic_key = "stress_crisis_change_and_conflict_management"
                
  
                 }  
                 if(  item ===  "Relationship and Team Performance" ){

                  video_list =   [ 
                   "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                   "https://www.youtube.com/embed/c0iZ1vnbGkc?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  ]
                   topic_key = "relationship_and_team_performance"
                
  
                 }  
                 if(  item ===    "Business Functions" ){

                  video_list =   [ 
                    "https://www.youtube.com/embed/6F3T3RsQ1XM?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                   "https://www.youtube.com/embed/xaSHwlhWddI?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                    "https://www.youtube.com/embed/j4yDknRMrGo?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                  ]
  
                  topic_key = "business_functions"
  
                 }
                 if(  item ===    "Quality of Performance" ){

                  video_list =   [ 
                  "https://www.youtube.com/embed/rX9QWBuR7JU?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                  "https://www.youtube.com/embed/6F3T3RsQ1XM?list=PLOaNAKtW5HLQYmcqsHxgVI8QbxwyFus3Z",
                    "https://www.youtube.com/embed/nERzFWduh4I?list=PLOaNAKtW5HLT-wpYmiG_2_D4TSdWSgFLC",
                          
                  ]
  
                  topic_key = "quality_of_performance"
                
  
                 }

                   // the list for Accounting starts here
    
                   if(  item ===    "Bookkeeping" ){

                    video_list =   [ 
                      "https://www.youtube.com/embed/eWeWm_d0CWs?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                      "https://www.youtube.com/embed/hnJJavSMXFo?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                     "https://www.youtube.com/embed/6S270rvtdpU?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                     "https://www.youtube.com/embed/Kmjuhi7EmdU" ,
                            
                    ]
                     topic_key = "bookkeeping"
                  
    
                   }
                   if(  item ===     "Accounting Equation" ){

                    video_list =   [ 
                     "https://www.youtube.com/embed/jwJE220hSAc",
                    ]
                     topic_key = "accounting_equation"
                  
    
                   }

                   if(  item ===  "Final Accounts and Financial Statements" ){

                    video_list =   [ 
                    "https://www.youtube.com/embed/eWeWm_d0CWs?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/77_auDuBQeg",
                    "https://www.youtube.com/embed/cC7_Hpvvazg?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/jfJhB8r6zes?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/5CivB8zCVso?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/r49rTntMJyo?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/fW5nv_1qH-w?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                    "https://www.youtube.com/embed/1CtK-Zp9zKM?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                     "https://www.youtube.com/embed/G_xJPisAZIo?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB",
                           
                    ]
    
                   topic_key = "final_accounts_and_financial_statements"
    
                   }

                   if(  item ===  "Value-Added Tax" ){

                    video_list =   [ 
                      "https://www.youtube.com/embed/B8BYzdpOXpk?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                           
                    ]
    
                    topic_key = "value_added_tax"
    
                   }

                   if(  item ===  "Reconciliations"){

                    video_list =   [ 
                     "https://www.youtube.com/embed/QshXwMg80ws?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                     "https://www.youtube.com/embed/1DV8rbuJRG4?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                      "https://www.youtube.com/embed/tUkLsCtLcRA?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                      "https://www.youtube.com/embed/vtvD3gQWTKY?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                      "https://www.youtube.com/embed/aYeTu_WCOeg?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,
                      "https://www.youtube.com/embed/WJXyAY1QYAw?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,

                           
                    ]
    
                    topic_key = "reconciliations"
    
                   }

                   if(  item ===   "Cost Accounting"){

                    video_list =   [ 
                     "https://www.youtube.com/embed/SuP_yE7Odig?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,
                     "https://www.youtube.com/embed/6GgU1es1x7I?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                      "https://www.youtube.com/embed/ay588Vigwf8?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,


                           
                    ]
    
                     topic_key = "cost_accounting"
    
                   }

                   if(  item ===   "Budgeting"){

                    video_list =   [ 
                    "https://www.youtube.com/embed/gPSx64C29jI?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                     "https://www.youtube.com/embed/ay588Vigwf8?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                     "https://www.youtube.com/embed/Jk32u1yLNd4?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,
                     "https://www.youtube.com/embed/8rxJogty3jk?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b"  ,

                           
                    ]
    
                     topic_key = "budgeting"
    
                   }
                   if(  item ===    "Indigenous Bookkeeping Systems" ){

                    video_list =   [ 
                     "https://www.youtube.com/embed/s_vu5YzUu70" ,

                           
                    ]
    
                     topic_key = "indigenous_bookkeeping_systems"
    
                   }

                   if(  item ===    "Fixed Assets" ){

                    video_list =   [ 
                    "https://www.youtube.com/embed/CJ5bDy_7MkE?list=PLOaNAKtW5HLSCJ-WPRylxHNFxzDPJKcEB" ,
                     "https://www.youtube.com/embed/ja9SZUJSOhg?list=PLOaNAKtW5HLQWxtzimO8XRA7APlVldQKo" ,
                    "https://www.youtube.com/embed/CJ5bDy_7MkE?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,
                    "https://www.youtube.com/embed/LpVwGNuzDac?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,

                           
                    ]
    
                     topic_key = "fixed_assets"
    
                   }

                   if(  item ===    "Inventory" ){

                    video_list =   [ 
                      "https://www.youtube.com/embed/pig2-rKKJ8c" ,
                     "https://www.youtube.com/embed/zksCGDokxEU?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,

                           
                    ]
    
                    topic_key = "inventory"
    
                   }

                   if(  item ===    "Internal Control"){

                    video_list =   [ 
                     "https://www.youtube.com/embed/DbnD9WRGpHg?list=PLOaNAKtW5HLQI__16M4EuBAyZYuEvvp4b" ,
                           
                    ]
    
                     topic_key = "internal_control"
    
                   }

                       // the list for economics starts here

                     if(  item ===    "Economics: Basic Concepts"){

                    video_list =   [ 
                      "https://www.youtube.com/embed/QT1vwC9eZSs" ,
                      "https://www.youtube.com/embed/Z44Kpw1j-Tg?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                      "https://www.youtube.com/embed/zJs7mpTli84?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                      "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn"  ,
                           
                    ]
    
                    topic_key = "economics_basic_concepts"
    
                   }

                   if(  item ===   "Basic Economic Problem"){

                    video_list =   [ 
                     "https://www.youtube.com/embed/9CVGPSTHBP0?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,

                           
                    ]
    
                    topic_key = "basic_economic_problem"
    
                   }

                   if(  item ===     "Circular Flow"){

                    video_list =   [ 
                     "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                    "https://www.youtube.com/embed/Z44Kpw1j-Tg?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                     "https://www.youtube.com/embed/zJs7mpTli84?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                    "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                           
                    ]
    
                    topic_key = "circular_flow"
    
                   }

                   if(  item ===   "Quantitative Elements"){

                    video_list =   [ 
                      "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                      "https://www.youtube.com/embed/Z44Kpw1j-Tg?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                      "https://www.youtube.com/embed/zJs7mpTli84?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                      "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                           
                    ]
    
                    topic_key = "quantitative_elements"
    
                   }

                   if(  item ===   "Economic Systems"){

                    video_list =   [ 
                    "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                     "https://www.youtube.com/embed/Z44Kpw1j-Tg?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                     "https://www.youtube.com/embed/zJs7mpTli84?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                     "https://www.youtube.com/embed/QT1vwC9eZSs?list=PLOaNAKtW5HLReFLgsm6f2mdBx45LyMdhn" ,
                           
                    ]
    
                    topic_key = "economic_systems"
                  
    
                   }


 
              this.setState({current_topic:item});
              this.setState({video_list:video_list});
              this.setState({show_video_list_page:true});
              this.setState({current_topic_key:topic_key});

            







   



  



 }




  get_topic_list_page=()=>{

    let page_style =          {}  
    let h1_style =             {}
    let inner_div_style =       {}
    let p_style =            {}
    let logo_style =            {}

    let p_font_size = '100'
    let inner_div_style_margin_top = "6%"
    let logo_style_margin_top = "7%"
    let container_div_style = {}
    
   
            

    if (window.innerWidth / window.innerHeight > 1  ){ 

      

                    p_font_size = '100%'
                    inner_div_style_margin_top = "6%"
                    logo_style_margin_top = "7%"

                    
                  
                    if(window.innerWidth / window.innerHeight>1.8){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>1.9){
                      p_font_size =  '170%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }


                    if(window.innerWidth / window.innerHeight>2){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>2.1){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "1%"
                      logo_style_margin_top = "2%"
                    }

                    h1_style =    {fontSize:'150%', color:'green', }
                    page_style = {width:'auto', marginLeft:'9%',  marginTop:'2%',   color:'green', overflow:"auto" }                  
                    inner_div_style = {width:'30%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, }
                      p_style =            {fontSize:p_font_size}
                    logo_style = {marginLeft:"35%", marginTop:logo_style_margin_top, width:'30%'}
                   
                    
    }

    if (window.innerHeight / window.innerWidth >1  ){


          
         
          let p_font_size = '130%'
          let inner_div_style_margin_top = "0%"
          let logo_style_margin_top = "0%"



          h1_style =    {fontSize:'150%', color:'green', }
          page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
          inner_div_style = {width:'100%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top }
            p_style =            {fontSize:p_font_size}
          logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}



          if (window.innerWidth >500 ){

            p_font_size = '150%'
            inner_div_style_margin_top = "0%"
            logo_style_margin_top = "0%"
  

            h1_style =    {fontSize:'150%', color:'green', }
            page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green', overflow:"auto"  }                  
            inner_div_style = {width:'50%',  borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, marginLeft:'25%' }
              p_style =            {fontSize:p_font_size}
            logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}





          }


     }

                 
          
    //alert(window.innerHeight)


         
       



  return                  <Bounce up>
                                <div style={{padding:'0% 3% 3% 3%', overflow:"auto"}}>

                
                                              <div  style={page_style}>

                                                <h3>Learning With Videos : {this.state.subject_name}</h3>
                                               
                                                <hr/>
                                                
                                                        {this.state.topic_list.map(item =>
                                                      
                                                            <div style={inner_div_style} id='login_icon' onClick={(event)=>{ this.set_video_list_data_to_state(item);}} >
                                                            <ContinouslyAnimate animation_type={'pulse'} animation_interval={Math.ceil(Math.random()*100000)} animation_delay={0} animate_data={
                                                                    <div style={{width:'85%', height :'150px', backgroundColor:"green" ,color:'white', display: 'flex', alignItems: 'center',justifyContent: 'center'}}> <p style={p_style} ><b>{item}</b></p>  </div>

                                                                  }>
                                                                      
                                                                  </ContinouslyAnimate>

                                                                   
                                                            </div>
                                                      
                                                        )}

                                                   
                                                        
                                          
                                              </div> 
                                              <hr/>                                                 
                                              <button  style={{margin:'auto', width:'20%', fontSize:'100%', minWidth:"200px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{this.props.history.push("/learning_centre")}}  >Back to Subjects</button>
                                          
                                                
                                                            
                              </div>
                </Bounce>





  }


  get_video_list_page=()=>{

    let outer_div_style = {}
    let inner_div_style = {}
    let iframe_height = "400"
    let p_font_size = "250%"

            if (window.innerWidth / window.innerHeight > 1  ){ 

              outer_div_style={padding:'0% 20% 5% 20%', overflow: 'auto' }
              inner_div_style = { margin :'3% 0% 1% 0%', }
          
            
            
              
              }

              if (window.innerHeight / window.innerWidth >1  ){

                  outer_div_style={padding:'0% 10% 5% 10%', overflow: 'auto' }
                  inner_div_style = { margin :'3% 0% 1% 0%', }



              }

              if (window.innerWidth < 750  ){


                outer_div_style={padding:'0% 1% 5% 1%', overflow: 'auto' }
                iframe_height = "250"
                p_font_size = "150%"



              }

              
              let current_page;

            if(this.state.video_list.length<1){

                                current_page = <div style ={outer_div_style }>

                                                            <p style={{color:'green', fontSize:p_font_size}}><b>{this.state.subject_name + " : " +this.state.current_topic}</b></p>

                                                            <p><b>We do not have Learning Content for {this.state.current_topic} at the moment, please check back later.</b></p>
                                                            

                                                           
                                                                <button  style={{margin:'auto', width:'20%', fontSize:'100%', minWidth:"200px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{ this.setState({show_video_list_page:false});}}  >Back to Topics</button>

                                </div>    

                      return current_page


            }
            else{


    
                        current_page = <div style ={outer_div_style }>


                                            <p style={{color:'green', fontSize:p_font_size}}><b>{this.state.subject_name + " : " +this.state.current_topic}</b></p>
                                            

                                            {this.state.video_list.map(item =>
                                                  
                                                <div style={inner_div_style}>

                                                  <hr/>

                                                  <iframe width="100%" height={iframe_height} src={item} allowfullscreen="allowfullscreen"></iframe>

                                                </div>

                                                )}
                                                <button  style={{margin:'auto', width:'20%', fontSize:'100%', minWidth:"150px", float:"right"}} class="submit_or_next_question_button" onClick={()=>{ this.setState({show_video_list_page:false});}}  >Back to Topics</button>
                                                <button  style={{margin:'auto', width:'20%', fontSize:'100%', minWidth:"150px", float:"left"}} class="submit_or_next_question_button" onClick={()=>{this.save_profile_to_db()}} disabled={this.state.button_disabled} >Practice Questions</button>
                                            </div>    
                                            

                          return current_page
                 }

  }


  go_to_past_question_centre=(response)=>{                                              


                                                            if(window.confirm("You will be taken away from this page to practice questions on " + this.state.current_topic)){
                                                                    
                                                                              
                                                                        this.props.save_user_profile_to_store(response)
                                                                     
                                                                        
                                                                          //this function below deletes the question id in store so that new questions id are retrieved that fit newly saved profile
                                                                              this.props.save_database_questions_id_to_store([])

                                                                              //this code below deletes the undone question  so that new questions are retrieved that fit newly saved profile
                                                                            this.props.save_un_done_questions_to_store ([])
                                                                                                            
                                                                            // redirect user to past question centre

                                                                            this.props.history.push("/past-questions-centre")

                                                            }          

                                                
                                                
                                              

                       

  }


  save_profile_to_db=()=>{

    this.setState({button_disabled:true});

    let url= base_url + 'edit-profile'

    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
    let request_data = {
                            topic: this.state.current_topic_key,
                            year : "",
                            subject : this.state.current_subject_key
                          
                          
                          
                          }
    
                     
  
    axios({
      method: 'post',
      url: url,
      data: request_data,
      headers:token_header
    })
    
      .then(res => {

        
        this.go_to_past_question_centre(res.data)
         
                      
             
      })

    .catch((error)=> {

          
            alert("Ooopss. Something went wrong please try again")
            this.setState({button_disabled:false});

          
    })

  }

 
  render() {

      let current_page = this.get_topic_list_page()

      if(this.state.show_video_list_page ===true){
        current_page = this.get_video_list_page()
      }
     


      return (
              <div>
                  
                      {current_page}
                      
                      <Helmet>
                                              <title> Learn With Videos</title>  
                                            
                                             
                                            

                      </Helmet>

              </div>




      );
  }
}




class Loading_page extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   

                };
  }

 
  render() {

    

    

      return (
        <Zoom>
        <div  style={{width:'80%',   maxWidth:'500px',  margin: 'auto', }}>
	
        <div >
        <img src={cordova_file_prefix + "loading_logo.gif"} style={{width:'100%', marginTop:'40%'}}></img>
       
        </div> 
     
      </div> 
      </Zoom>




      );
  }
}

class ContinouslyAnimate extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   
                 counter: 0

                };
  }

  componentDidMount(){

              if (this.props.animation_delay !== "undefined"){

                  setTimeout(  ()=>{setInterval(()=>{this.setState({counter:this.state.counter+1})}, this.props.animation_interval)}, this.props.animation_delay  )
              }
              else{

              setInterval(()=>{this.setState({counter:this.state.counter+1})}, this.props.animation_interval)

              }

  }

 
  render() {

       
        let animate;

        if(this.props.animation_type === "shake"){

          animate = <Shake spy={this.state.counter}>{this.props.animate_data}</Shake>

        }
        if(this.props.animation_type === "flash"){

          animate = <Flash spy={this.state.counter}>{this.props.animate_data}</Flash>

        }
        if(this.props.animation_type === "tada"){

          animate = <Tada spy={this.state.counter}>{this.props.animate_data}</Tada>

        }
        if(this.props.animation_type === "pulse"){

          animate = <Pulse spy={this.state.counter}>{this.props.animate_data}</Pulse>

        }
        if(this.props.animation_type === "jump"){

          animate = <Jump spy={this.state.counter}>{this.props.animate_data}</Jump>

        }
        if(this.props.animation_type === "spin"){

          animate = <Spin spy={this.state.counter}>{this.props.animate_data}</Spin>

        }
        if(this.props.animation_type === "flip"){

          animate = <Flip spy={this.state.counter}>{this.props.animate_data}</Flip>

        }
        if(this.props.animation_type === "zoom"){

          animate = <Zoom spy={this.state.counter}>{this.props.animate_data}</Zoom>

        }
        if(this.props.animation_type === "wobble"){

          animate = <Wobble spy={this.state.counter}>{this.props.animate_data}</Wobble>

        }


        

    

      return (
        <div>
                {animate}
        </div>




      );
  }
}




class UserChoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
                        icons:
                                    { exit_login_icon:true,
                                      exit_instant_login_icon:true,
                                      exit_registration_icon:true,
                                     
                                    },

                        take_user_choice_away: false,
                        show_instant_login_warning_page: false,
                        show_account_created_succesffully_page: false,
                        button_disabled:false,
                        vesrion_value :''

                };
  }
  componentDidMount(){


        
        
        setTimeout(()=>{this.setState({take_user_choice_away:true})}, 500 )

        setTimeout(()=>{this.setState({version_value:"Version "+ version})}, 1300 )


        if(window.location.pathname !== ""){ 
        
                    this.props.history.push("")
       }


      if(google_analytics !== null){google_analytics(window.location.pathname)}
                  // if(window.location.hostname!=='localhost'){ 
                                // the code below is to trigger analytics to count this page
                        //    ReactGA.pageview(window.location.pathname  );
            
          
          
                  // }

              

  }

  handle_anonymous_login=(username, password)=> {
    
              let data = {
                            username : username,
                            password :password
                          }

               
              let url = base_url;
                  url= url + 'customised-get-login-token'
            
            
              axios({
                method: 'post',
                url: url,
                data:data,
                
              })
              
                .then(res => {

                    
                  
                      localStorage.login_token= res.data.token
                      
                      
                    
                })

                .catch((error)=> {
                    // handle error
                    if(error.response.status === 400){alert("Wrong Username/Password combination, Please type a correct Username and Password")}
                    else{alert("Something went wrong. Please try again")}
                    this.setState({ button_disable: false }) 
                  
                  
                })

}

  generate_randon_username_and_email=()=>{

    let random_number = Math.ceil(Math.random()*1000000000)
    let username = 'anonymous_' + random_number
    let email = "anonymous_"+ random_number +'@learnershub.co.za'

      return[username, email]

  }

  handle_anonymous_signup=(event)=> {   
                event.preventDefault()

                this.setState({ button_disabled: true })     

                let random_data = this.generate_randon_username_and_email()

                let anonymous_signup_data = {
                                                username:random_data[0],
                                                first_name:'Anonymous',
                                                last_name:'Anonymous',
                                                email:random_data[1],
                                                password:'anonymous',
                                                confirm_password:'anonymous',
                                                platform:'anonymous'
                                          }
                                                       
              let url = base_url;
                  url= url + 'create-new-user'
            
              const token = localStorage.getItem("token");
              let token_header= {};
              if(token){
                  token_header= { Authorization :  'Token '+token}     
              }
              
              axios({
                method: 'post',
                url: url,
                data:anonymous_signup_data,
                headers:token_header
              })
              
                .then(res => {

                          setTimeout(()=>{this.setState({ show_account_created_succesffully_page: true })  },5000)

                         setTimeout(()=>{
                                    this.setState({ button_disable: false })
                                    this.handle_anonymous_login(anonymous_signup_data.username, anonymous_signup_data.password)
                         }
                         ,10000)
                     
                         
                })

                .catch((error)=> {

                          setTimeout(()=>{
                                              // handle error
                                            
                                              if(error.response.status === 409){this.handle_anonymous_signup()}
                                              else{
                                                alert("Something went wrong. Please try again")
                                              }
                                              this.setState({ button_disable: false }) 

                                     }, 5000)   
                                              
                  
                })
        
  }

 

  go_back_to_user_choice_page=()=>{

          let new_state = this.state.icons
          new_state.exit_login_icon = true
          new_state.exit_instant_login_icon = true
          new_state.exit_registration_icon = true
          this.setState({icons:new_state})
    
          setTimeout(()=>{this.setState({show_instant_login_warning_page:false})  },500)

          setTimeout(()=>{this.setState({take_user_choice_away:true})},700)  



  }
  
  exit_user_choice_page =(controller)=>{
   
    let x;
    
    let current_state = this.state.icons
              for (x in current_state){
              
                              if(controller === x){
                               
                                
                                         continue
                                         
                                }
                                else{

                                 current_state[x]= false;

                                }
                                
                  }
                  this.setState({icons:current_state});
                          

                  setTimeout(()=>{this.setState({take_user_choice_away:false})  },700)

                  if(this.state.icons.exit_instant_login_icon ===true){
                             
                             setTimeout(()=>{this.setState({show_instant_login_warning_page: true})  },1200)

                  }       

                  if(this.state.icons.exit_login_icon ===true){
                  
                           setTimeout(()=>{this.props.history.push("/user-login") },1200)        
                            
                  }        
                  
                  if(this.state.icons.exit_registration_icon ===true){
                  
                    setTimeout(()=>{this.props.history.push("/register-new-account") },1200)        
                     
           }        
                  


                  
   }

  get_userchoice_page=()=>{

    let page_style =          {}  
    let h1_style =             {}
    let inner_div_style =       {}
    let p_style =            {}
    let logo_style =            {}

    let p_font_size = '100'
    let inner_div_style_margin_top = "6%"
    let logo_style_margin_top = "7%"

    
   
            

    if (window.innerWidth / window.innerHeight > 1  ){ 

      

                    p_font_size = '100'
                    inner_div_style_margin_top = "6%"
                    logo_style_margin_top = "7%"

                    
                  
                    if(window.innerWidth / window.innerHeight>1.8){
                      p_font_size =  '200%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>1.9){
                      p_font_size =  '170%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }


                    if(window.innerWidth / window.innerHeight>2){
                      p_font_size =  '160%'
                      inner_div_style_margin_top = "2%"
                      logo_style_margin_top = "3%"
                    }

                    if(window.innerWidth / window.innerHeight>2.1){
                      p_font_size =  '120%'
                      inner_div_style_margin_top = "1%"
                      logo_style_margin_top = "2%"
                    }

                    h1_style =    {fontSize:'150%', color:'green', }
                    page_style = {width:'auto', marginLeft:'9%',  marginTop:'2%',   color:'green',  }                  
                    inner_div_style = {width:'30%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, }
                      p_style =            {fontSize:p_font_size}
                    logo_style = {marginLeft:"35%", marginTop:logo_style_margin_top, width:'30%'}
                   
                    
    }

    if (window.innerHeight / window.innerWidth >1  ){


          
         
          let p_font_size = '100%'
          let inner_div_style_margin_top = "0%"
          let logo_style_margin_top = "0%"



          h1_style =    {fontSize:'150%', color:'green', }
          page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green',  }                  
          inner_div_style = {width:'100%', float:'left', borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top }
            p_style =            {fontSize:p_font_size}
          logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}



          if (window.innerWidth >500 ){

            p_font_size = '150%'
            inner_div_style_margin_top = "0%"
            logo_style_margin_top = "0%"
  

            h1_style =    {fontSize:'150%', color:'green', }
            page_style = {width:'auto', marginLeft:'0%',  marginTop:'2%',   color:'green',  }                  
            inner_div_style = {width:'50%',  borderStyle:'none', textAlign:'center', padding:'3%', marginTop:inner_div_style_margin_top, marginLeft:'25%' }
              p_style =            {fontSize:p_font_size}
            logo_style = {marginLeft:"0%", marginTop:logo_style_margin_top, width:'100%'}





          }


     }

            
          
            
          
    //alert(window.innerHeight)


            let learnershub_logo =  <ContinouslyAnimate animation_type={'pulse'} animation_interval={3000} animate_data={
                                                
                                            <img src={cordova_file_prefix + "logo.png"} style={logo_style} ></img>

                                          }>    
                                      </ContinouslyAnimate> 
        let bottom_logo = null
        let top_logo = null
        let welcome_text =null

            if (window.innerWidth / window.innerHeight > 1  ){ 
                        bottom_logo = learnershub_logo 
                        welcome_text = <h1 style={h1_style}>Welcome to Learners Platform</h1>
            }
            else {           
                    top_logo = learnershub_logo
              }



  return                  <Bounce up collapse when={this.state.take_user_choice_away}>
                    <div style={{padding:'3%'}}>


                          {top_logo}  
                      
                          
                          {welcome_text}
                          
                          
                      
                            
                                  <div  style={page_style}>
                                    
                            
                                            <Zoom  opposite when={this.state.icons.exit_login_icon}>
                                                <div style={inner_div_style} id='login_icon' onClick={(event)=>{this.exit_user_choice_page('exit_login_icon') }} >
                                                        <img src={cordova_file_prefix + "login.jpg"} style={{width:'50%',  }}></img>

                                                        <p style={p_style} ><b>Login  with Your Username/Email and Password</b></p>
                                                </div>
                                            </Zoom>

                                            <Zoom opposite when={this.state.icons.exit_instant_login_icon}>
                                                <ContinouslyAnimate animation_type={'tada'} animation_interval={3000} animate_data={
                                                     <div style={inner_div_style} id='instant_icon' onClick={(event)=>{this.exit_user_choice_page('exit_instant_login_icon') }}>
                                                            <img src={cordova_file_prefix + "instant access.jpg"} style={{width:'75%',}}></img>
                                                            <p style={p_style}><b>Get Instant Access Without Creating an Account</b></p>
                                                     </div>
                                                }>
                                                          
                                                </ContinouslyAnimate>
                                            </Zoom>

                                            <Zoom opposite opposite when={this.state.icons.exit_registration_icon}>
                                                <div style={inner_div_style} id='register_icon' onClick={(event)=>{this.exit_user_choice_page('exit_registration_icon') }}>
                                                        <img src={cordova_file_prefix + "create new account.jpg"} style={{width:'50%', }}></img>
                                                        <p style={p_style}><b>Create a Free Account to access the Platform</b></p>
                                                </div>
                                            </Zoom>
                               
                                            
                               
                                   </div> 

                          {bottom_logo}
                                  
                                                
                  </div>
                </Bounce>





  }
  get_instant_accesss_warning_page=()=>{

              let button_text = "Proceed With a Temporary Account"
              if(this.state.button_disabled === true){
                    button_text = <ContinouslyAnimate animation_type={"zoom"} animation_interval={1000} animate_data={"Creating Your Account"} />

              }

                       return       <Fade left  >
                                    <Zoom opposite when={!this.state.icons.exit_login_icon}>
                                                  <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                                    
                                                  <div style={{marginTop:'10%'}}>
                                                    <h1 style={{color:'red'}}>Friendly Reminder </h1>

                                                    <img src={cordova_file_prefix + "stop.jpg"} style={{width:'50%',  }}></img>
                                                  
                                                  <p style={{color:'green', fontSize:'100%', marginTop:'5%'}}>Just want to remind you that you are signing in with a temporary  account. We cannot guaranty you that your profile and performance data
                                                  will be made available when next you visit. if you want your data to be permanant, please go back and Login or create a free account if you do not have an
                                                  existing permanent account. </p>	
                                                                                           

                                                  <button  style={{margin:'5% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={(event)=>{this.handle_anonymous_signup(event)}}  disabled={this.state.button_disabled}>{button_text}</button> 
                                                  <button  style={{margin:'0% 0 5% 20%', width:'60%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={()=>{this.go_back_to_user_choice_page()}}  disabled={this.state.button_disabled} >Go Back</button>
                                                  </div>     
                                                  </div>
                                      </Zoom>      
                                    </Fade>

   }

  get_account_created_succesfully_page=()=>{

   
         let button_text = <ContinouslyAnimate animation_type={"zoom"} animation_interval={1000} animate_data={"Signing You In"} />

    

                 
                         
                          return              <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                          
                                        <div style={{marginTop:'10%'}}>
                                          <h1 style={{color:'Green'}}>Temporary Account Created </h1>

                                          <img src={cordova_file_prefix + "go.jpg"} style={{width:'50%',  }}></img>
                                        
                                        <p style={{color:'green', fontSize:'100%', marginTop:'5%'}}>We have created a temporary account for you. We will automatically Sign you in.  </p>	
                                                                                 

                                        <button  style={{margin:'5% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={(event)=>{this.handle_anonymous_signup(event)}}  disabled={this.state.button_disabled}>{button_text}</button> 
                                        
                                        </div>     
                                        </div>
                               
                       

}
   

   
 
  render() {

          let current_page 

          if (this.state.show_instant_login_warning_page === false && this.state.show_account_created_succesffully_page ==false){

                    current_page = this.get_userchoice_page()
          }

          if (this.state.show_instant_login_warning_page ===true){
                      current_page = this.get_instant_accesss_warning_page()
          }

          if (this.state.show_account_created_succesffully_page ===true){
                    current_page = this.get_account_created_succesfully_page()
          } 
 
         
         

      return (
                   <div>
                        
                        {current_page}   
                        
                       
                        <Helmet>
                                              <title>Welcome to Learners Platform</title>  
                                            
                                            

                         </Helmet>
                       
                         <p style ={{marginLeft:'70%',color:'red'}}><b>{this.state.version_value} </b></p> 
                          
                 </div>

      );
  }
}




class Tutorial_video extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   

                };
  }

  componentDidMount(){

       if(google_analytics !== null){google_analytics(window.location.pathname)}

        //if(window.location.hostname!=='localhost'){ReactGA.pageview("/tutorial-video-page");}
    

    
  }

 
  render() {

    

    

      return (
      
        <div  style={{width:'90%',   maxWidth:'900px',  margin: 'auto', }}>

                  <h2>Welcome, We are happy to have you here.</h2>

                  <p>We highly recommend you watch our two minutes introductory video to make using this platform easy for you.</p>

          
                <div >
                <iframe src="https://www.youtube.com/embed/AFZnxHVx4eE" style={{width:'100%', marginTop:'5%',}} height="450" allowfullscreen="allowfullscreen"/>
                
                
                </div> 


               
     
      </div> 
      




      );
  }
}

class Site_maintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   

                };
  }

  componentDidMount(){


    if(google_analytics !== null){google_analytics(window.location.pathname)}
     // if(window.location.hostname!=='localhost'){ReactGA.pageview("/site-maintenance-page");}
    

    
  }
 
  render() {

    

    

      return (
      
        <div  style={{width:'95%',   maxWidth:'900px',  margin: 'auto', }}>

                        <h1>Scheduled Site Maintenance</h1>

                        <p>We are for sorry for the downtime but we need to make Learnershub better for you. 
                          We are doing some system housekeeping while also deploying new stuffs just for you.

                          
                          Please bear with us, we will be back shortly</p>

                
                      <div >
                      <img src={cordova_file_prefix +"maintenance.jpg"} style={{width:'100%', marginTop:'5%', minHeight:'300px'}} ></img>
                        
                        
                    
                      </div> 



                      <Helmet>
                                              <title>Learners Platform is Under Maintenance</title>  
                                            
                                            

                      </Helmet>
     
      </div> 
      




      );
  }
}

class NoInternet extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   

                };
  }

  componentDidMount(){

           if(google_analytics !== null){google_analytics("/no-internet" )}
           // if(window.location.hostname!=='localhost'){      ReactGA.pageview("/no-internet"   );    }

  }
 
  render() {

  
      return (
      
                  <div  style={{width:'95%',   maxWidth:'900px',  margin: 'auto', }}>

                              <h1 style={{color:'red', marginBottom:'2%'}}>No or Slow Internet Access</h1>

                              <p>We could not detect internet access on your device. Please restore internet access to this device to continue to enjoy our delightful Platform</p>

                  
                          <div >

                                <img src={cordova_file_prefix +"internet_access.png"} style={{width:'100%', marginTop:'5%', minHeight:'300px'}} ></img>
                             
                      
                          </div> 
              
                  </div> 
        




      );
  }
}


class UpdateMobileApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   

                };
  }

  componentDidMount(){

           if(google_analytics !== null){google_analytics("/update-mobile-app" )}
           // if(window.location.hostname!=='localhost'){      ReactGA.pageview("/no-internet"   );    }

  }
 
  render() {

  
      return (
      
                  <div  style={{width:'95%',   maxWidth:'900px',  margin: 'auto', }}>

                              <h1 style={{color:'red', marginBottom:'8%', marginTop:'15%'}}>Update Available</h1>

                              <p>We have made the Learners Platform better for you. Please update your App.</p>

                              <button class="button btn btn-success btn-large"  ><a href="https://play.google.com/store/apps/details?id=za.co.learnershub.learner1" style={{color:"white"}}>Update Now</a></button>
                              <p style ={{marginLeft:'60%',color:'red'}}><b>Your Version: {version}</b></p>     

                  
                          <div >

                               
                             
                      
                          </div> 
              
                  </div> 
        




      );
  }
}


class Confirm_email extends Component {


  constructor(props) {
    super(props);
    this.state = {
                    confirmation_token:'',
                    token_requested:false,
                    is_button_pressed:false,                  
                   
                };
  }

  componentDidMount(){

            if(google_analytics !== null){google_analytics("/confirm-email-page" )}

            //if(window.location.hostname!=='localhost'){ReactGA.pageview("/confirm-email-page");}
    

    
  }

  handle_change=(event)=> {
   
    this.setState({confirmation_token: event.target.value});

  }


  confirm_email_token=()=>{
             
    this.setState({is_button_pressed: true}); 
    let url= base_url + 'confirm-email'
  
    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
  
    axios({
      method: 'post',
      url: url,
      data:[this.state.confirmation_token],
      headers:token_header
    })
    
      .then(res => {    
           
            alert('Email Verification Succesful')
            this.props.save_user_account_to_store(null)
           
            

      })

      .catch((error)=> {
          // handle error
          this.setState({is_button_pressed: false}); 
            
          //this.props.save_user_account_to_store(null)
          alert("Please enter a valid token")
        
      })
     


  }

  request_token=()=>{
    this.setState({is_button_pressed: true});        

    let url= base_url + 'confirm-email'
  
    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
  
    axios({
      method: 'get',
      url: url,
      headers:token_header
    })
    
      .then(res => {    
        this.setState({token_requested: true});
        this.setState({is_button_pressed: false});
            alert("Token has been sent to your email, please check both your inbox and spam folders") 
           

      })

      .catch((error)=> {
          // handle error
          this.setState({is_button_pressed: false});
            
          //this.props.save_user_account_to_store(null)
          alert("oops something went wrong")
        
      })
     


  }

  
 
  render() {

    let token_request=<div>
                              <p>Click the request token button below to have a confirmation token sent to your email</p>
                              <button onClick={this.request_token} disabled={this.state.is_button_pressed}>Request Token</button>
                      </div>

    if(this.state.token_requested===true){
      token_request=null
    }
  
    return (
      <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>

          <h1 style={{color:'red', margin:'10% 0% 5% 0%'}}> Email Confirmation</h1>
          <p>Yes, you now have an account with us, but we need to verify your email address</p>
          {token_request}
          <hr/>
           <p> Please check your email and enter the received token below (Please check your spam folder 
              if you cannot find the email in your inbox)</p>
              <div class="field">
              <label for="confirmation_token">Enter Email Token:</label>
              <input type="number" id="confirmation_token" name="confirmation_token"  placeholder="Type the token here" class="login" onChange={this.handle_change} />
            </div> 

            <button onClick={this.confirm_email_token} disabled={this.state.is_button_pressed}>Confirm Email</button>
      </div>
      


    );
  }

}



class HeaderSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
                                     
                  minimise_menu_button_on_mobile : "nav-collapse collapse",
                  aria_expanded :"false",
                  active_link: {

                                  dashboard:null,
                                  learning_centre:null,
                                  past_pdf_centre : null,
                                  past_question_centre: null,
                                  profile: null,
                                  contact_us:null

                  }
                  
                };

  }


  componentDidMount() {
        setInterval(()=>{this.get_active_link()},1000)
  }

 logout=()=>{

  localStorage.removeItem("login_token");
  setTimeout(()=>{
              this.props.save_user_account_to_store(null);
              this.props.save_user_profile_to_store(null);
              this.props.save_performance_summary_to_store({performance_data})}
              ,1000)
              


 }

 minimise_menu_button_on_mobile=()=>{
 
 // this hack was added to collapse the menu bar when a menu item has been clicked on a mobile device
    document.getElementById("laolu_navigation").classList.remove('in');
 
 }

 get_active_link=()=>{

            let active_link =  {

                                      dashboard:null,
                                      learning_centre:null,
                                      past_pdf_centre : null,
                                      past_question_centre: null,
                                      profile: null,
                                      contact_us:null

                                }
            
            if(window.location.href.search("dashboard")!==-1){
                  active_link.dashboard = "active"
            }
            if(window.location.href.search("learning_centre")!==-1){
                  active_link.learning_centre = "active"
            }
            if(window.location.href.search("past-questions-centre")!==-1){
                    active_link.past_question_centre = "active"
            }
            if(window.location.href.search("past_pdf_centre")!==-1){
              active_link.past_pdf_centre = "active"
      }
            if(window.location.href.search("profile_page")!==-1){
                    active_link.profile = "active"
            }
            if(window.location.href.search("contact_us")!==-1){
                    active_link.contact_us = "active"
            }
            
            this.setState({  active_link: active_link });
           

 }

  render() {

 let desktop_menu = null
 let mobile_menu = null  
 let menu_search_bar = null
 


  // this code below is to adjust margin bottom for mobile view

  let margin_bottom = null


 let   menu_buttons  =  <div class="subnavbar">
                                  <div class="subnavbar-inner">
                                      <div class="container">
                                            <ul class="mainnav">
                                                  <li class={ this.state.active_link.dashboard } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a><i class="icon-dashboard"></i><span>  <Link to="/dashboard"> Dashboard</Link>  </span> </a> </li>
                                                  
                                                  <li class={ this.state.active_link.learning_centre } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a ><i class="icon-facetime-video"></i><span>   <Link to="/learning_centre">Learning With Videos </Link> </span> </a> </li>
                                                  <li class={ this.state.active_link.past_question_centre } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a ><i class="icon-list-alt"></i><span>   <Link to="/past-questions-centre">Digital Matric Questions</Link> </span> </a> </li>
                                                  <li class={ this.state.active_link.past_pdf_centre } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a ><i class="icon-list-alt"></i><span>   <Link to="/past_pdf_centre">Past Matric Papers</Link> </span> </a> </li>
                                                  <li class={ this.state.active_link.profile } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a ><i class="icon-facetime-video"></i><span ><Link to="/profile_page" > Profile </Link></span> </a></li>
                                                  
                                                  <li class={this.state.active_link.contact_us } onClick={()=>{this.minimise_menu_button_on_mobile()}}><a ><i class="icon-code"></i><span><Link to="/contact_us">Contact Us</Link></span> </a> </li>
                                                 

                                                
                                                  <li  onClick={()=>{this.logout();this.minimise_menu_button_on_mobile()}}><a ><i class="icon-off"></i><span style={{color:'red'}}><b>Sign out</b></span> </a> </li>
                                                 
                                                 <li></li>
                                  
                                            </ul>
                                      </div>

                                  </div>
                                
                      </div>
    
    if(window.innerWidth>=728){ 
      
          desktop_menu = menu_buttons; 
          menu_search_bar =  <form class="navbar-search pull-right" >
                                    <input type="text" class="search-query" placeholder="Search" />
                            </form>       
      }

    if(window.innerWidth<728){ mobile_menu = menu_buttons; margin_bottom = "5%"}

    

   
  
    return (
             <div>

                      <div class="navbar navbar-fixed-top" style={{marginBottom:margin_bottom}}>
                              <div class="navbar-inner">
                                <div class="container">
                                   <a className="btn btn-navbar" data-toggle="collapse" data-target=".nav-collapse">
                                          <span class="icon-bar"></span><span class="icon-bar"></span>
                                          <span class="icon-bar"></span> 
                                     </a>
                                     
                                          <Link class="brand" to="/"> LearnersHub Portal</Link>
                                     
                                  <div id="laolu_navigation" class="nav-collapse collapse"> 
                                                                                             
                                  {mobile_menu}
                                    {menu_search_bar}
                                  </div>
                                  
                                
                                </div>
                              
                              
                              </div>
                              
                              {desktop_menu}
                      </div>


                      
            </div>
      


    );
  }

}


class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

                 }

  }



  render() {
  
    return (
                        <div>
                              <div class="extra">

<div class="extra-inner">

  <div class="container">

    <div class="row">
                  <div class="span3">
                      
                      <h4>
                          Home Site Links</h4>
                      <ul>
                          <li><a href="http://learnershub.co.za" target="_blank">Home Website</a></li>
                          <li><a href="http://learnershub.co.za/mentor.html" target="_blank">Mentor Platform</a></li>
                          <li><a href="http://learnershub.co.za/terms.html" target="_blank">Terms & Conditions</a></li>
                          <li><a href="http://learnershub.co.za/privacy.html" target="_blank">Privacy Policy</a></li>
                          <li><Link to="/contact_us">Contact Us</Link></li>
                          
                      </ul>
                    
                  </div>

                 

                 
                  <div class="span3">
                     
                      <h4>
                          External Resources</h4>
                      <ul>
                          <li><a href="https://www.youtube.com/channel/UC_ayO9NLpGuhCvxnWZ0KwYw" target="_blank">Video Lessons from Mindset Learn support</a></li>
                          <li><a href="https://app-eassessment.co.za/" target="_blank">Sample online exam from Department of Basic Education SA</a></li>
                          <li><a href="https://intl.siyavula.com/read" target="_blank">Free Textbooks from Siyavula</a></li>
                          <li><a href="https://wcedonline.westerncape.gov.za/matric-support" target="_blank" >Matric Support Resources</a></li>
                  
                      </ul>
                     
                  </div>

                 
                
                  <div class="span">
                     
                      <h4>
                          Social Zone</h4>
                          Like, Share and Follow our Pages 
                      <ul>
                            <li><a rel="nofollow" href="https://www.facebook.com/LearnersHubSA-103535464719045" target="_blank">Facebook</a></li>
                            <li><a rel="nofollow" href="https://twitter.com/LearnersHub_SA" target="_blank">Twitter</a></li>
                            <li><a rel="nofollow" href="https://www.instagram.com/learnershubsa/" target="_blank">Instagram</a></li>
                            <li><a rel="nofollow" href="#" target="_blank">Linkedin</a></li>
                      </ul>
                      
                  </div>


               
                  
                  
                
              </div> 

  </div>

</div>

</div> 


  
  
<div class="footer">

<div class="footer-inner">
  
  <div class="container">
    
    <div class="row">
      
        <div class="span12">
          &copy; 2020 <a href="#">LearnersHub</a>.
        </div> 
        
      </div>
      
  </div> 
  
</div> 

</div> 

                        </div>

    )

  }

}


class PastQuestionsCentre extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
                  original_current_question:{},
                  current_question:{},
                  fill_in_the_gap_answer:'',
                  selected_answer:"",
                  user_has_submitted_answer:false,
                  user_answer_is_correct: false,
                  question_addendum_details:'',
                  question_solution_details:'',
                  question_formula_details :'',
                  get_question_addendum_details_key:true,
                  get_question_solution_details_key:true,
                  get_question_formula_details_key :true,
                  should_open_addendun_or_solution_or_formula_modal:'',
                  modal_content_zoom_width:'70%',
                  open_complain_modal_page : false, 
                  previous_original_question:null
                                                
                };
  }

  componentDidMount(){
        this.google_analytics_question_logging()
       
        
  }

  google_analytics_question_logging=()=>{




                if(google_analytics !== null){google_analytics(window.location.pathname)}

                  // if(window.location.hostname!=='localhost'){  ReactGA.pageview(window.location.pathname);}



  }

  handle_fill_in_the_gap_change=(event)=>{
        this.setState({fill_in_the_gap_answer: event.target.value});

  }

  set_original_current_question_to_state=()=>{
     
       if(this.props.un_done_questions.length!==0 && this.state.original_current_question!==this.props.un_done_questions[0]){
                let currently_answered_question=this.props.un_done_questions[0]
                this.setState({
                                original_current_question: currently_answered_question,
                                fill_in_the_gap_answer:'',
                                selected_answer:"",
                                user_has_submitted_answer:false,
                                user_answer_is_correct: false,
                                question_addendum_details:'',
                                question_solution_details:'',
                                question_formula_details :'',
                                get_question_addendum_details_key:true,
                                get_question_solution_details_key:true,
                                get_question_formula_details_key :true,
                                should_open_addendun_or_solution_or_formula_modal:'',
                                modal_content_zoom_width:'70%',
                                open_complain_modal_page : false, 
                                            
                              });

                              setTimeout(this.set_question_performance_to_local_storage, 300)

         }
         
  }

  set_question_performance_to_local_storage=(mark)=>{



          let current_performance = JSON.parse(localStorage.getItem("question_performance"));
          let last_performance = current_performance[current_performance.length -1]
    
            if(mark === undefined){
                       //this code below is to set the question to localstorage incase a user quits
                      let new_performance = {}
                      new_performance.id =this.state.original_current_question.id
                      new_performance.question_type =this.state.original_current_question.question_type
                      new_performance.subject = this.state.original_current_question.subject
                      new_performance.topic = this.state.original_current_question.topic
                      new_performance.year = this.state.original_current_question.year
                      new_performance.month = this.state.original_current_question.month
                      new_performance.answer_is_correct = 'false'
                      
                     
                      if(last_performance === undefined){
                            current_performance.push(new_performance)
                            localStorage.question_performance = JSON.stringify(current_performance)
                            return
                      }
                      if (last_performance.id !== new_performance.id ){
                            current_performance.push(new_performance)
                            localStorage.question_performance = JSON.stringify(current_performance)
                       }      
              }

            if(mark === 'true' && last_performance!== undefined){
                        current_performance[current_performance.length -1].answer_is_correct = mark
                        localStorage.question_performance = JSON.stringify(current_performance)
            }
  }

  radomize_question_options_and_set_current_question_to_state=()=>{

        if(Object.keys(this.state.original_current_question).length !== 0  && (this.state.original_current_question.id !== this.state.current_question.id)){
                  let current_answered_question = this.state.original_current_question
                 
                  let options = [
                                    this.state.original_current_question.correct_answer,
                                    this.state.original_current_question.option1,
                                    this.state.original_current_question.option2,                   
                                    this.state.original_current_question.option3,
                                
                                ]
                  let random_options = options.sort(() => Math.random() - 0.5)

                  current_answered_question.option1 = random_options[0]
                  current_answered_question.option2 = random_options[1]
                  current_answered_question.option3 = random_options[2]
                  current_answered_question.option4 = random_options[3]

                  this.setState({current_question: current_answered_question});
         }


  }

  get_all_options_and_highlight_selected_option=()=>{

        let style={}
        let options = [
                          {
                            tag:'Option A',
                            option:this.state.current_question.option1, 
                            style:style
                          }, 
                          {  
                            tag:'Option B',
                            option:this.state.current_question.option2,
                            style:style
                          },
                          {  
                            tag:'Option C',
                            option:this.state.current_question.option3,
                            style:style
                          },
                          {  
                            tag:'Option D',
                            option:this.state.current_question.option4,
                            style:style
                          },

                      ]


                      

        for (let i = 0; i < options.length; i++) {

                if(options[i].option===this.state.selected_answer){

                      options[i].style = {backgroundColor:'green', color:'black'}
                      options[i].tag = "Your Choice"
                      
                }

        }



    //options = options.sort(() => Math.random() - 0.5)



    return options

  }

  check_and_get_addendum_and_formula_button=()=>{

        let addendum_button=null
        let formula_button= null

        if(this.state.current_question.addendum_id!== ""){

                addendum_button =   <p><a href="#" class="btn btn-primary" onClick={_=>this.setState({should_open_addendun_or_solution_or_formula_modal: 'addendum'})}>View Addendum</a></p>

        }

        if(this.state.current_question.formula_id!== ""){

                formula_button =   <p><a href="#" class="btn btn-primary" onClick={_=>this.setState({should_open_addendun_or_solution_or_formula_modal: 'formula'})}>View Informaton Sheet</a></p>

        }

        return [addendum_button, formula_button]

  }

  select_answer=(selected_option)=>{
       
        this.setState({selected_answer: selected_option});
  }

  get_and_display_options_or_fill_in_the_gap_text_field=()=>{

        let options = this.get_all_options_and_highlight_selected_option()

        let options_box=null

        if(this.state.user_has_submitted_answer===false){
        

                    if(this.state.original_current_question.question_type==='objective_question'){
                                  options_box = <div style={{overflow: 'auto', width:'98%', margin:'auto'}}>

                                                                  <div style={{width:'25%', minWidth:"300px", float:'left', }} onClick={()=>this.select_answer(options[0].option)}>

                                                                      <div class="panel panel-success" style={options[0].style}>
                                                                          <div class="panel-heading" style={options[0].style}>
                                                                          <h3 class="panel-title">{options[0].tag}</h3>
                                                                          </div>

                                                                          <div class="panel-body">
                                                                              <span dangerouslySetInnerHTML={{ __html:options[0].option}}></span>
                                                                              
                                                                              
                                                                          </div>
                                                                      </div>             

                                                                  </div>

                                                                  <div style={{width:'25%', minWidth:"300px", float:'left', }} onClick={()=>this.select_answer(options[1].option)}>

                                                                      <div class="panel panel-success" style={options[1].style}>
                                                                          <div class="panel-heading" style={options[1].style}>
                                                                          <h3 class="panel-title">{options[1].tag}</h3>
                                                                          </div>

                                                                          <div class="panel-body">
                                                                              <span dangerouslySetInnerHTML={{ __html:options[1].option}}></span>
                                                                              
                                                                              
                                                                          </div>
                                                                      </div>             

                                                                  </div>

                                                                  <div style={{width:'25%', minWidth:"300px", float:'left', }} onClick={()=>this.select_answer(options[2].option)}>

                                                                      <div class="panel panel-success" style={options[2].style}>
                                                                          <div class="panel-heading" style={options[2].style}>
                                                                          <h3 class="panel-title">{options[2].tag}</h3>
                                                                          </div>

                                                                          <div class="panel-body">
                                                                              <span dangerouslySetInnerHTML={{ __html:options[2].option}}></span>
                                                                             
                                                                              
                                                                          </div>
                                                                      </div>             

                                                                  </div>

                                                                  <div style={{width:'25%', minWidth:"300px", float:'left', }} onClick={()=>this.select_answer(options[3].option)}>

                                                                      <div class="panel panel-success" style={options[3].style}>
                                                                          <div class="panel-heading" style={options[3].style}>
                                                                          <h3 class="panel-title">{options[3].tag}</h3>
                                                                          </div>

                                                                          <div class="panel-body">
                                                                              <span dangerouslySetInnerHTML={{ __html:options[3].option}}></span>
                                                                             
                                                                              
                                                                          </div>
                                                                      </div>             

                                                                  </div>


                                                              </div>
                          
                    }
                    
                    if(this.state.original_current_question.question_type==='fill_in_the_gap'){

                                  options_box = <div style={{width:'98%', maxWidth:"500px", margin:'auto'}}>

                                                        <div class="panel panel-success">
                                                            <div class="panel-heading">
                                                                <h3 class="panel-title">Answer</h3>
                                                            </div>

                                                            <div class="panel-body">
                                                                  

                                                                <textarea style={{width:'99%', maxWidth:'99%'}} id="fill_in_the_gap_answer" onChange={this.handle_fill_in_the_gap_change} rows="3" cols="10"  placeholder="Type your answer here..." >
                                                                {this.state.fill_in_the_gap_answer}
                                                                </textarea>

                                                                
                                                            </div>
                                                            
                                                        </div>

                                                  </div>


                    }

        }

              return options_box

  }
  
  get_complaint_button=()=>{
            let complain_button = null
            if(this.state.user_has_submitted_answer){   
                     complain_button =  <p><a href="#" class="btn btn-primary" onClick={_=>this.setState({open_complain_modal_page:true})}>Submit a Complaint</a></p>
              }
              return complain_button
  }

  objective_question=()=>{

        
        let addendum_and_formula_button = this.check_and_get_addendum_and_formula_button()

        let options_box = this.get_and_display_options_or_fill_in_the_gap_text_field()
      
        let complain_button =  this.get_complaint_button()

        if(this.state.user_has_submitted_answer){

             options_box = this.get_your_answer_and_correct_answer_box()
            
        }


        let page= <div>
               
                          <div style={{width:'98%', maxWidth:"500px", margin:'auto'}}>

                                <div class="panel panel-success" >
                                    <div class="panel-heading" >
                                   
                                        <span tabIndex="-1" id='question'></span>
                                        <h3  class="panel-title">Question : {this.state.current_question.id}</h3>
                                        
                                       
                                    </div>

                                    <div class="panel-body">
                                        <span dangerouslySetInnerHTML={{ __html: this.state.current_question.question}}></span>
                                       
                                        {addendum_and_formula_button}
                                        {complain_button}
                                    </div>
                                </div>

                          </div>
                          {options_box }

                    </div>

      return page
  }


  fill_in_the_gap_question=()=>{

    let addendum_and_formula_button = this.check_and_get_addendum_and_formula_button()

    let options_box = this.get_and_display_options_or_fill_in_the_gap_text_field()

    let complain_button =  this.get_complaint_button()


    if(this.state.user_has_submitted_answer){

          options_box = this.get_your_answer_and_correct_answer_box()
    }

    let page= <div>
    
                      <div style={{width:'98%', maxWidth:"500px", margin:'auto'}}>

                            <div class="panel panel-success">
                                <div class="panel-heading">
                               
                                    <span tabIndex="-1" id='question'></span>
                                    <h3 class="panel-title">Question : {this.state.current_question.id}</h3>
                                </div>

                                <div class="panel-body">
                                    <span dangerouslySetInnerHTML={{ __html: this.state.current_question.question}}></span>                                  
                                    {addendum_and_formula_button}
                                    {complain_button}
                                </div>
                            </div>

                      </div>

                      {options_box}
                     


                </div>

  return page
  }

  get_question_by_type=()=>{

      if (this.state.current_question.question_type==='objective_question'){
             return this.objective_question()

      }

      if (this.state.current_question.question_type==='fill_in_the_gap'){
            return this.fill_in_the_gap_question()
 
      }

  }

  get_submit_or_next_or_solution_button=()=>{

        let submit_or_next_button = null
        let solution_button = null
        if(this.state.user_has_submitted_answer===true  ){

          submit_or_next_button = <button style={{ margin:"0% 5% 5% 5%", float:"right"}} class="submit_or_next_question_button" type="button" onClick={this.get_next_question_and_reset_state}>Next Question</button> 
           //the function below is used to refocus screen back to question after user has submitted answer to avoid moving up and down on mobile phones
           
        }

        if ( (this.state.selected_answer!=='' || this.state.fill_in_the_gap_answer!=='') && this.state.user_has_submitted_answer===false){

          submit_or_next_button = <button  style={{ margin:"0% 2% 5% 5%", float:"right"}} class="submit_or_next_question_button" type="button" onClick={this.submit_your_answer_for_marking}>Submit Answer</button> 
        }

        if (this.state.user_has_submitted_answer===true && this.state.original_current_question.solution_id!==''){

          solution_button = <button style={{ margin:"0% 0% 5% 2%", float:"left"}} class="submit_or_next_question_button" type="button" onClick={_=>this.setState({should_open_addendun_or_solution_or_formula_modal: 'solution'})}>View Solution</button> 
         
        }

        return [submit_or_next_button, solution_button]

  }

  ouput_answer_for_laolu_to_use_in_increasing_fake_profile_performance=()=>{

            if(this.props.user_account === null){return}
            // the line above is to avoid throwing errors when user account is not ready

            if (this.props.user_account.username === "learner1"||this.props.user_account.username === "learner2"||this.props.user_account.username === "learner3"){

              return this.state.current_question.correct_answer

            }


  }

  submit_your_answer_for_marking=()=>{

          this.setState({user_has_submitted_answer: true});

          if(this.state.original_current_question.question_type ==='objective_question'){

                    if(this.state.selected_answer === this.state.original_current_question.correct_answer){

                          this.setState({user_answer_is_correct: true});
                          // this line below is to set question performance to local storage
                          this.set_question_performance_to_local_storage('true')

                    }
          }


          if(this.state.original_current_question.question_type ==='fill_in_the_gap'){
                    //cleaning up correct answer from html tags
                    let correct_answer = this.state.original_current_question.correct_answer.toLowerCase()
                    correct_answer = correct_answer.replace(/<p>/gi, '');
                    correct_answer = correct_answer.replace(/<\/p>/gi, '');
                    correct_answer = correct_answer.replace('\n', '');
                    correct_answer = correct_answer.replace(/&nbsp; /gi, '');
                    correct_answer = correct_answer.replace(/&nbsp;/gi, '');


                    //cleaning up correct answer from html tags
                    let fill_in_the_gap_answer = this.state.fill_in_the_gap_answer.toLowerCase()
                    fill_in_the_gap_answer = fill_in_the_gap_answer.replace('\n', '');
                    fill_in_the_gap_answer = fill_in_the_gap_answer.trim()


                    if( fill_in_the_gap_answer === correct_answer ){
                     
                      this.setState({user_answer_is_correct: true});
                      // this line below is to set question performance to local storage
                      this.set_question_performance_to_local_storage('true')
                    }
          }

          
          setTimeout(this.play_needed_sound,100)
          this.focus_on_question()

  }

  play_needed_sound=()=>{


        let sound=null

        if(this.state.user_answer_is_correct === true){

                  sound = new Audio(cordova_file_prefix + "correct_answer.mp3")
                  
        }
        if(this.state.user_answer_is_correct === false){
                  sound = new Audio(cordova_file_prefix + "wrong_answer.mp3")
                
        }
        sound.play()


           

}

  get_your_answer_and_correct_answer_box=()=>{

        let options_box = null
        let your_answer = this.state.selected_answer
        
        if(this.state.original_current_question.question_type==='fill_in_the_gap'){

              your_answer = this.state.fill_in_the_gap_answer

        }


        let your_answer_style = {backgroundColor:'red', color:'white'}

        if(this.state.user_answer_is_correct){
         
              your_answer_style = {backgroundColor:'green', color:'white'}
        }


        if(this.state.user_has_submitted_answer){

                 options_box = <div style={{overflow: 'auto', width:'98%',maxWidth:"600px", margin:'auto'}}>

                                    <div style={{width:'25%', minWidth:"300px", float:'left', }} >

                                        <div class="panel panel-success" style={your_answer_style}>
                                            <div class="panel-heading" style={your_answer_style}>
                                            <h3 class="panel-title">Your Answer</h3>
                                            </div>

                                            <div class="panel-body">
                                              <span dangerouslySetInnerHTML={{ __html: your_answer}}></span>
                                              
                                                
                                            </div>
                                        </div>             

                                    </div>

                                    <div style={{width:'25%', minWidth:"300px", float:'left', }} >

                                        <div class="panel panel-success" style={{backgroundColor:'green', color:'white'}}>
                                            <div class="panel-heading" style={{backgroundColor:'green', color:'white'}}>
                                            <h3 class="panel-title">Correct Answer</h3>
                                            </div>

                                            <div class="panel-body">
                                                <span dangerouslySetInnerHTML={{ __html:this.state.original_current_question.correct_answer}}></span>
                                                
                                                
                                            </div>
                                        </div>             

                                    </div>


                        </div>       

        }
    
        return options_box
  }

  get_next_question_and_reset_state=()=>{
        
        // saving the current_question_id to the done questions in store
        let current_done_question_id = [...this.props.done_questions_id, this.state.original_current_question.id]
        this.props.save_done_questions_id_to_store(current_done_question_id)

        // loging the page to google analytics
        this.google_analytics_question_logging()

        this.setState(
                  {
                      original_current_question : {},  
                      current_question :{},
                      fill_in_the_gap_answer : '',
                      selected_answer : "",
                      user_has_submitted_answer : false,
                      user_answer_is_correct : false,
                      question_addendum_details:'',
                      question_solution_details:'',
                      question_formula_details :'',
                      get_question_addendum_details_key:true,
                      get_question_solution_details_key:true,
                      get_question_formula_details_key :true,
                  }         
          );

        //removing the current question from undone question and saving the trmaining undone question to store

        let current_un_done_question = this.props.un_done_questions.slice(1)
        this.props.save_un_done_questions_to_store(current_un_done_question)

        // play new_question sound
        let  sound =  new Audio(cordova_file_prefix + "new_question.mp3")
        sound.play()    
                 
     
  }

  get_addendum_or_workings_or_formula_details_and_save_to_state=(model)=>{

        let model_id = null
        //code to return quit function if current question is not associated with needed model
        if (model === 'addendum'){
              
              if(this.state.original_current_question.addendum_id==="" || this.state.get_question_addendum_details_key===false){
                return
              }
              model_id = this.state.original_current_question.addendum_id
              this.setState({get_question_addendum_details_key:false})
        }
        if (model === 'solution'){
              
              if(this.state.original_current_question.solution_id==="" || this.state.get_question_solution_details_key===false){
                return
              }
              model_id = this.state.original_current_question.solution_id
              this.setState({get_question_solution_details_key:false})
        }
        if (model === 'formula'){
              
              if(this.state.original_current_question.formula_id==="" || this.state.get_question_formula_details_key===false ){
                return
              }
              model_id = this.state.original_current_question.formula_id
              this.setState({get_question_formula_details_key:false})
        }
       

        
        let url = base_url ;
        url = url + 'get-individual-addendum-or-solution-or-formula-detail'            
         
          let request_data=[model, parseInt(model_id, 10)];
          let token = localStorage.getItem("login_token")       
          let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {


            if(res.data[0]==="addendum"){
                this.setState({question_addendum_details:res.data[1]});

            }
            if(res.data[0]==="solution"){
                this.setState({question_solution_details:res.data[1]});

            }
            if(res.data[0]==="formula"){
                this.setState({question_formula_details:res.data[1]});

            }
            
           
            
        })

        .catch((error)=> {
            // handle error
          
                
          
        })  



  }

  handle_modal_content_zoom_width=()=>{
     
    

        if(window.innerWidth<980){ 

                  // there is an npm package called image-focus which can help in focusing on a particular point when zooming in images.

                    if(this.state.modal_content_zoom_width==='70%'){
                          this.setState({modal_content_zoom_width:'150%'})
                    }
                    if(this.state.modal_content_zoom_width==='150%'){
                      this.setState({modal_content_zoom_width:'300%'})
                    }
                    if(this.state.modal_content_zoom_width==='300%'){
                      this.setState({modal_content_zoom_width:'70%'})
                    }
            }
  }

  open_modal_and_get_modal_content=()=>{

        let modal_overlay=null
        let modal_content=null
        let modal_text=''
        
        if(window.innerWidth<980){ modal_text = <p style={{color:'red'}}>Tap Image to Zoom. </p>}
        if(this.state.should_open_addendun_or_solution_or_formula_modal==="addendum"){ modal_content =this.state.question_addendum_details }
        if(this.state.should_open_addendun_or_solution_or_formula_modal==="solution"){ modal_content =this.state.question_solution_details }
        if(this.state.should_open_addendun_or_solution_or_formula_modal==="formula"){ modal_content =this.state.question_formula_details }


        if(this.state.should_open_addendun_or_solution_or_formula_modal !== ''){

              modal_overlay =  <Modal isOpen={true} style={customStyles} contentLabel="Minimal Modal Example">
                                       <button  style={{float:'right'}} onClick={_=>this.setState({should_open_addendun_or_solution_or_formula_modal: '', modal_content_zoom_width:'70%'})}>Exit</button>
                                        {modal_text}
                                        <img src={modal_content} onClick={this.handle_modal_content_zoom_width} style={{width:this.state.modal_content_zoom_width}} />
                                       
                              </Modal>

        }

        return modal_overlay

  }
  
  focus_on_question =()=> {   
    // this function is used to restore focus to the question to avoid moving up and down on mobile    
            if   (document.getElementById("question")!==null ){
                      document.getElementById("question").focus();
            }     
  }       
  
  get_complain_modal_page = ()=>{

    let selected_answer = this.state.selected_answer
    if(this.state.current_question.question_type === 'fill_in_the_gap'){ selected_answer = this.state.fill_in_the_gap_answer}

          let prop_data = { 
                         question_id: this.state.current_question.id,
                         selected_answer: selected_answer
                      }

      let complain_page = <Modal style={customStyles} isOpen={this.state.open_complain_modal_page} contentLabel="Minimal Modal Example">
                                <button  style={{float:'right'}} onClick={_=>this.setState({open_complain_modal_page:false})}>Exit</button>           
                                <Complain data={prop_data}/>
                
                         </Modal>

      return complain_page

  }

  get_question_setup_box=()=>{

        if (this.props.un_done_questions.length === 0){return null}
        let subject=""
        if(this.state.current_question.subject === "mathematical_literacy"){subject = "Mathematical Literacy" }
        if(this.state.current_question.subject === "mathematics"){subject = "Mathematics" }
        if(this.state.current_question.subject === "life_sciences"){subject = "Life Sciences" }
        if(this.state.current_question.subject === "physical_sciences"){subject = "Physical Sciences" }
        if(this.state.current_question.subject === "business_studies"){subject = "Business Studies" }
        if(this.state.current_question.subject === "accounting"){subject = "Accounting" }
        if(this.state.current_question.subject === "economics"){subject = "Economics" }
        if(this.state.current_question.subject === "agricultural_science"){subject = "Agricultural Science" }

        let margin_top = '20% 0px 2% 0px'
        if(window.innerWidth >600 ){margin_top = '12% 0px 2% 0px'}
        if(window.innerWidth >1100 ){margin_top = '5% 0px 2% 0px'}


        let setup_box  =   
        
                              <div style={{margin:margin_top}}>
                                          <div style={{width:'98%', maxWidth:"500px", margin:"auto", backgroundColor:"white"}}>
                                  
                                                  <div  >
                                            
                                                            <h3 style={{margin:'20px 0px 0px 20px'}} > Question Setup</h3>
                                                
                                              
                                                </div>

                                                  <div class="panel-body">
                                                      <p>Subject : {subject} </p>
                                                      <p>Year :  {this.state.current_question.year}</p>
                                                      <p>Topic :  {this.state.current_question.topic}</p>
                                                      <a href="#" class="btn btn-warning" onClick={()=>{this.props.change_should_show_question_setup_page(true)}}>Change Question Setup</a>
                                                    
                                                    
                                                  </div>
                                      

                                          </div>

                              </div>  


                return setup_box

  }
  render() {
    
  
    this.set_original_current_question_to_state()

    this.radomize_question_options_and_set_current_question_to_state()

    if(Object.keys(this.state.original_current_question).length!==0){
            this.get_addendum_or_workings_or_formula_details_and_save_to_state('addendum')
            this.get_addendum_or_workings_or_formula_details_and_save_to_state('solution')
            this.get_addendum_or_workings_or_formula_details_and_save_to_state('formula')
    }

    let page = this.get_question_by_type()

    let submit_or_next_or_button = this.get_submit_or_next_or_solution_button()

    let submit_or_next_button = submit_or_next_or_button[0]
    let solution_button = submit_or_next_or_button[1]
    let modal_overlay = this.open_modal_and_get_modal_content()
    let complain_page = this.get_complain_modal_page()
    let setup_box = this.get_question_setup_box()

    return (
              <Fade right>
              <div style={{overflow: 'auto', minHeight:'400px'}}>
                    {page}     

                    {solution_button}
                    {submit_or_next_button}
                    {this.ouput_answer_for_laolu_to_use_in_increasing_fake_profile_performance()}
                    {setup_box}
                    {modal_overlay}
                    {complain_page}

                    <Helmet>
                                              <title>Past Matric Questions</title>  
                                                                                   

                    </Helmet>

                    
                
              </div>  
              </Fade>
    )
  }
}

class Question_setup extends Component {

  constructor(props) {
    super(props);
    this.state = {
                    form_details:{
                              subject:'',
                              year:'',
                              topic:''
                              
                            },

                            button_disabled :false
                 }

  }

  componentDidMount() {
          this.set_profile_detail_to_state()
          

          if(google_analytics !== null){google_analytics("/question-profile-setup" )}


          //if(window.location.hostname!=='localhost'){ ReactGA.pageview("/question-profile-setup");}
            
        
          


  }

  handle_form_change=(event)=> {
   
    let current_form_details = this.state.form_details

    if(event.target.id==='subject'){  current_form_details.subject = event.target.value;  this.setState({form_details:current_form_details});   } 
    if(event.target.id==='year'){    current_form_details.year = event.target.value;  this.setState({form_details:current_form_details});   }
    
    
   
      
  }
  set_profile_detail_to_state=()=>{

            let current_form_details = this.state.form_details
            current_form_details.subject = this.props.user_profile.question_setup_subject
            current_form_details.year = this.props.user_profile.question_setup_year
            current_form_details.month = this.props.user_profile.question_setup_month
            this.setState({form_details:current_form_details });
                       
  }

  empty_current_question_and_question_id_in_store=()=>{
        //this function below deletes the question id in store so that new questions id are retrieved that fit newly saved profile
    this.props.save_database_questions_id_to_store([])

     //this code below deletes the undone question  so that new questions are retrieved that fit newly saved profile
    this.props.save_un_done_questions_to_store ([])

  }
  save_profile_to_db=()=>{

              this.setState({button_disabled:true});
          
              let url= base_url + 'edit-profile'

              const token = localStorage.getItem("login_token");
              
              let token_header= { Authorization :  'Token '+token}     
              
              let request_data = this.state.form_details
            
              axios({
                method: 'post',
                url: url,
                data: request_data,
                headers:token_header
              })
              
                .then(res => {
                      
                                setTimeout(()=>{
                      
                                                    this.props.save_user_profile_to_store(res.data)
                                                    this.props.change_should_show_question_setup_page(false)
                                                    this.empty_current_question_and_question_id_in_store()
                                                    alert("Question Profile Saved Succesfully")  
                                                    this.setState({button_disabled:false});
                                                  } 
                                                  ,4000)  
                       
                })

              .catch((error)=> {
                    
                       this.setState({button_disabled:false});
                  
              })

  }
  

  render() {
  



    // the code below is to set select box to initialise with your saved city
    let selected_subject = <option selected >All Subjects</option>
    let selected_year = <option selected>All Available Years</option>
    let selected_month = <option selected>All Month</option>
    
    if(this.state.form_details.subject !=='') {
          if(this.state.form_details.subject === 'mathematics'){selected_subject = <option selected value='mathematics'>Mathematics</option> }
          if(this.state.form_details.subject === 'mathematical_literacy'){selected_subject = <option selected value='mathematical_literacy'>Mathematical Literacy</option> }
          if(this.state.form_details.subject === 'life_sciences'){selected_subject = <option selected value='life_sciences'>Life Sciences</option> }
          if(this.state.form_details.subject === 'physical_sciences'){selected_subject = <option selected value='physical_sciences'>Physical Sciences</option> }
          if(this.state.form_details.subject === 'business_studies'){selected_subject = <option selected value='business_studies'>Business Studies</option> }
          if(this.state.form_details.subject === 'accounting'){selected_subject = <option selected value='accounting'>Accounting</option> }
          if(this.state.form_details.subject === 'economics'){selected_subject = <option selected value='economics'>Economics</option> }
          if(this.state.form_details.subject === 'agricultural_science'){selected_subject = <option selected value='agricultural_science'>Agricultural Science</option> }
    
        }
   
    if(this.state.form_details.year !=='') {selected_year =  <option selected value={this.state.form_details.year}> {this.state.form_details.year}</option> }


    let button_text = "Save Question Profile"

    if(this.state.button_disabled === true){

          button_text = <ContinouslyAnimate animation_type={'shake'} animation_interval={1000} animate_data={"Saving Profile"}/>


    }


    return (            
                        <Zoom>
                        <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                          
                        <div style={{marginTop:'10%'}}>
                          <h4>Setup Your Question Profile  </h4>
                        <p>Setup the category of questions you want to practice in your Learning Centre below</p>	
                        
                        <div style={{marginTop:'5%'}}>Subject: </div>
                        <select class="select-css" onChange={this.handle_form_change} id="subject">               
                          {selected_subject}
                          {subject.map( item => 
                                <option value={item[1]}>{item[0]}</option>
                          )}   
                                                 
                        </select>

                        <div style={{marginTop:'5%'}}>Year: </div>
                        <select class="select-css" onChange={this.handle_form_change} id="year" disabled>
                        {selected_year}
                        <option value="">All Available Years</option>
                        {year.map( item => 
                                <option value={item}>{item}</option>
                          )}                        
                        </select>

                                    
                       

                        <button  style={{margin:'10% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={this.save_profile_to_db} type="button" >{button_text}</button> 

                        </div>     
                        </div>
                        </Zoom>
    )

  }

}


class Profile_page extends Component {

  constructor(props) {
    super(props);
    this.state = {

                      should_open_profile_modal_edit: false,
                      user_id:'',
                      first_name:'',
                      Last_name:'',
                      email:'',
                      ethnicity:'',
                      school_district:'',
                      school_name:'',
                      city:'',
                      image:"",
                      open_edit_picture_modal:false,
                      new_unsaved_picture:'',
                      button_disabled:false

                 }

  }

  componentDidMount(){

                this.set_user_and_profile_detail_to_state()
                  
                if(google_analytics !== null){google_analytics( window.location.pathname )}
              // if(window.location.hostname!=='localhost'){ ReactGA.pageview(window.location.pathname );}
                      
                  
       
        
  }

  get_profile_page_content=()=>{

    let edit_profile_button  = <button  style={{margin:'10% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={_=>this.setState({should_open_profile_modal_edit:true})} type="button" >Edit Profile</button> 
    let edit_picture_button  = <button  style={{margin:'10% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button"  type="button" onClick={_=>this.setState({open_edit_picture_modal:true})} >Edit Picture</button> 
    
    let profile_picture = cordova_file_prefix + "empty_profile_picture.jpg"
    if(this.state.image !== ''){profile_picture = this.state.image}

    let profile_page_content =    <div class="panel panel-success" style={{width:'85%', minWidth:'300px', margin:'auto' }} >
                                         
                                        
                                          <div  class="panel-heading" style={{width:"100%", textAlign:'center'}}>
                                              <h2  >My Profile Page </h2> 
                                          </div>

                                          <div style={{ width:'100%', overflow: 'auto'}}>



                                                  <div  style={{width:"30%",minWidth:'300px', float:'left',  padding:'5% 5% 5% 5%' }}>
                                                        <img src={profile_picture} style={{width:"100%",   }}></img>
                                                        {edit_picture_button}
                                                                
                                                  </div>

                                                  <div style={{width:"70%", minWidth:'300px',  float:'left', padding:'5% 5% 5% 5%'}}>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >My User ID </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.user_id}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >Name </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.first_name+' '+this.state.last_name}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >Email </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.email}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >City </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.city}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >School District </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.school_district}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >School Name </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.school_name}</p>
                                                                          <hr/>
                                                                  </div>

                                                                  <div style={{width:"100%", textAlign:'center'   }}> 
                                                                          <h3 >Ethnicity </h3> 
                                                                          <hr/>
                                                                          <p>{this.state.ethnicity}</p>
                                                                          <hr/>
                                                                  </div>
                                                                  {edit_profile_button}
                                                  </div> 
                                              
                                          </div>
                                         
                                  </div>

          return  profile_page_content

  }

  handle_form_change=(event)=> {
   
          if(event.target.id==='school_name'){   this.setState({school_name: event.target.value});   } 
          if(event.target.id==='city'){   this.setState({city: event.target.value});   } 
          if(event.target.id==='ethnicity'){   this.setState({ethnicity: event.target.value});   } 
          if(event.target.id==='school_district'){   this.setState({school_district: event.target.value});   } 
         
            
  }

  get_edit_profile_modal_content=()=>{

    // the code below is to set select box to initialise with your saved city
    let selected_city = <option selected >Please select your city</option>
    let selected_school_district = <option selected>Please select your School District</option>
    let selected_ethnicity = <option selected>Please select your Ethnicity</option>
    
    if(this.state.city !=='') {selected_city = <option selected value={this.state.city}>{this.state.city}</option> }
    if(this.state.school_district !=='') {selected_school_district = <option selected value={this.state.school_district}>{this.state.school_district}</option> }
    if(this.state.ethnicity !=='') {selected_ethnicity = <option selected value={this.state.ethnicity}>{this.state.ethnicity}</option> }

    let button_text = "Save Profile"

    if(this.state.button_disabled === true){

      button_text = <ContinouslyAnimate animation_interval={1000} animation_type={'flash'} animate_data={"Saving Your Profile"} />

      }
    

    
    let edit_profile_modal_content = <div style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                            <div style={{marginTop:'10%'}}>
                                                    <h1 style={{color:'green'}}>Edit Profile</h1>
                                                  <p style={{color:'green'}}>Edit your profile information here and remember to save before exiting </p>	
                                                  
                                                  <div style={{marginTop:'5%'}}>First Name: </div>
                                                  <input value={this.state.first_name}  name="quantity" disabled></input>

                                                  <div style={{marginTop:'5%'}}>Last Name: </div>
                                                  <input value={this.state.last_name}  name="quantity" disabled></input>

                                                  <div style={{marginTop:'5%'}}>Email Address: </div>
                                                  <input  value={this.state.email} name="quantity" disabled></input>


                                                  <div style={{marginTop:'5%'}}>City: </div>
                                                  <select class="select-css"  id='city' onChange={this.handle_form_change} >
                                                  {selected_city}
                                                  <optgroup label='Eastern Cape'>
                                                      
                                                        {city_list[0].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label= 'Free State'>
                                                     
                                                        {city_list[1].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='Gauteng'>
                                                     
                                                        {city_list[2].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='KwaZulu-Natal'>
                                                    
                                                        {city_list[3].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='Limpopo'>
                                                     
                                                        {city_list[4].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='Mpumalanga'>
                                                    
                                                        {city_list[5].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='North West'>
                                                     
                                                        {city_list[6].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='Northern Cape'>
                                                     
                                                        {city_list[7].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>

                                                  <optgroup label='Western Cape'>
                                                     
                                                        {city_list[8].map(item => (                                                     
                                                          <option value={item}>{item}</option>                                                    
                                                        ))}
                                                  </optgroup>
                                                            
                                                  </select>


                                                  <div style={{marginTop:'5%'}}>School District: </div>
                                                  <select class="select-css" id='school_district' onChange={this.handle_form_change} >
                                                    {selected_school_district}
                                                    {school_district_list.map(item => (                                                     
                                                      <option value={item}>{item}</option>                                                    
                                                    ))}                
                                                  </select>
                                                
                                              
                                                  <div style={{marginTop:'5%'}}>Ethnicity: </div>
                                                  <select class="select-css" id='ethnicity' onChange={this.handle_form_change} >
                                                    {selected_ethnicity}
                                                    <option>American Indian or Alaska Native</option>
                                                    <option>Asian</option>    
                                                    <option>Black or African American</option>
                                                    <option>Hispanic or Latino</option>  
                                                    <option>Native Hawaiian or Other Pacific Islander</option>
                                                    <option>White</option>                       
                                                  </select>


                                                  <div style={{marginTop:'5%'}}>School Name: </div>
                                                  <input list="school_list" id="school_name" value={this.state.school_name} onChange={this.handle_form_change} placeholder='Type your school name'/>
                                                  <datalist id="school_list">
                                                  
                                                  {school_list.map(item => (  
                                                        <option value={item}/>
                                                    ))}     
                                                  </datalist>
                                                  
                                                   <p>If you cannot find your school name in the list, Please type in
                                                     your correct school name and save your profile</p>              
                                                     
                                                  <button  style={{margin:'10% 0 5% 10%', width:'80%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={this.save_user_profile_to_db} disabled={this.state.button_disabled}>{button_text}</button> 
  

                                            </div>     
                                      </div>
                                                  
          return edit_profile_modal_content

  }

 

  set_user_and_profile_detail_to_state=()=>{

        if (this.state.user_id === '' && (typeof(this.props.user_account) === 'object' && this.props.user_account !== null)) {

                  this.setState({
                                        user_id: this.props.user_account.id,
                                        last_name:this.props.user_account.last_name,
                                        first_name:this.props.user_account.first_name,
                                        email: this.props.user_account.email,
                                        

                                  });


                this.setState({
                                      ethnicity: this.props.user_profile.ethnicity,
                                      school_district:this.props.user_profile.district,
                                      school_name:this.props.user_profile.school,
                                      city:this.props.user_profile.city,
                                      image:this.props.user_profile.image, 

                                  });
                                  
                              
                                  
        }


  }

  close_modal=()=>{

    this.setState({ should_open_profile_modal_edit : false})
  } 

  save_user_profile_to_db=(new_image)=>{

    this.setState({button_disabled:true})

    let data = {}
        data.city = this.state.city
        data.ethnicity = this.state.ethnicity 
        data.school = this.state.school_name
        data.district = this.state.school_district
        data.image = this.state.image
        if(new_image==='new_image'){ data.image = this.state.new_unsaved_picture}

    let url= base_url + 'edit-profile'

    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
  
    axios({
      method: 'post',
      url: url,
      data:data,
      headers:token_header
    })
    
      .then(res => {

            setTimeout(()=>{
            
                                    this.props.save_user_profile_to_store(res.data)
                                    this.setState({
                                                    open_edit_picture_modal:false,
                                                    image:res.data.image,
                                                    new_unsaved_picture:''   
                                    })

                                    this.close_modal()
                                    alert('Saved Successfully') 
                                    this.setState({button_disabled:false})
                              }
                              ,3000)

          


            
            
            

      })

    .catch((error)=> {

            setTimeout(()=>{
          
                                       alert("oops! somerhing went wrong")
                                       this.setState({buton_disabled:false})
                            }
                            ,3000)

    })



  }

  down_size_image = ()=>{
    
      let dataUrl = this.state.new_unsaved_picture
      let newWidth = 300
      
      let  result = downscaleImage(dataUrl, newWidth, "image/jpeg", 0.7)
      this.setState({ new_unsaved_picture : result})
      
      function downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
              "use strict";
              var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

              // Provide default values
              imageType = imageType || "image/jpeg";
              imageArguments = imageArguments || 0.7;

              // Create a temporary image so that we can compute the height of the downscaled image.
              image = new Image();
              image.src = dataUrl;
              //As a heads up, sometimes image.width/height will return 0 since it hasn't loaded. You might need to convert this 
              //into an async function and listen to image.onload to get the correct image with and height
              oldWidth = image.width;
              oldHeight = image.height;
              

              //newHeight = Math.floor(oldHeight / oldWidth * newWidth)
              newHeight = 300
              // Create a temporary canvas to draw the downscaled image on.
              canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;

              // Draw the downscaled image on the canvas and return the new data URL.
              ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, newWidth, newHeight);
              newDataUrl = canvas.toDataURL(imageType, imageArguments);
              return newDataUrl;
          }

  }

  getBase64=(event)=> {
    
    var files = document.getElementById(event.target.id).files; 
    if((files[0].type=='image/jpeg'||files[0].type=='image/png'||files[0].type=='image/gif') && files.length > 0){

            
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            
            reader.onload = ()=> {   
                                  this.setState(state => (state.new_unsaved_picture = reader.result, state))
                                  setTimeout(this.down_size_image, 500)                            
                      }
                                                           
            reader.onerror = function (error) {
              alert('Error: ', error);
            };
           


    }
      
    else {
      alert('Only Jpeg, PNG and Gif Files are Accepted, Please select a different picture')
    }         
}
  render() {
   
    
    let profile_page_content = this.get_profile_page_content()
    let edit_profile_modal_content = this.get_edit_profile_modal_content()
    let picture_button_text = "Save Picture"

    if(this.state.button_disabled === true){

          picture_button_text = <ContinouslyAnimate animation_interval={1000} animation_type={'flash'} animate_data={"Saving Your Picture"} />

    }

    return (


                     <Fade up>
                      
                                <div style={{marginBottom:'5%'}}>
                                            {profile_page_content}
                                            
                                            <Modal style={customStyles} isOpen={this.state.should_open_profile_modal_edit} contentLabel="Minimal Modal Example">
                                                      <button  style={{float:'right'}} onClick={_=>this.close_modal()}>Exit</button>
                                                      {edit_profile_modal_content}
                                                
                                          </Modal>

                                          <Modal style={customStyles} isOpen={this.state.open_edit_picture_modal} contentLabel="Minimal Modal Example">
                                          <button  style={{float:'right'}} onClick={_=>this.setState({open_edit_picture_modal:false})}>Exit</button>
                                                <div style={{width:'95%', maxWidth:'600px', margin:'auto', marginTop:"7%" }}>
                                                    
                                                      <h1>Edit Profile Picture</h1>
                                                        <h4 style={{marginTop:'10%', color:'green'}}> Click the upload button below to select a new profile picture. Remember to save it before leaving </h4>
                                                      <input style={{marginTop:'5%'}} id="upload_new_profile_picture" onChange={this.getBase64} type="file" accept="image/*"></input>
                                                      <img src={this.state.new_unsaved_picture} />
                                                      <br/>
                                                      <button  style={{margin:'15% 0 5% 10%', width:'50%', fontSize:'130%', height:'70px', minWidth:'250px'}} class="submit_or_next_question_button" onClick={_=>this.save_user_profile_to_db('new_image')} disabled={this.state.button_disabled} >{picture_button_text}</button> 
                                                </div>
                                          </Modal>

                                                                    

                                </div>


                                <Helmet>
                                                    <title>Profile Page</title>  
                                                  
                                                  
                                                  

                               </Helmet>   
                  </Fade>
    )

  }

}


class Contact_us_page extends Component {

  constructor(props) {
    super(props);
    this.state = {
                      user_id:'',
                      user_email:'',
                      message_subject:'',
                      message_body:'',
                      button_disabled:false,

                 }

  }

  componentDidMount(){

            if(google_analytics !== null){google_analytics( window.location.pathname )}

            //if(window.location.hostname!=='localhost'){ReactGA.pageview(window.location.pathname );}
        
  }

  get_user_from_store_and_save_to_state=()=>{

        if (this.state.user_id === '' && (typeof(this.props.user_account) === 'object' && this.props.user_account !== null)) {

                this.setState({
                                  user_id: this.props.user_account.id,
                                  user_email: this.props.user_account.email,

                             });
        }
  }
  handle_form_change=(event)=> {
   
    let new_form_data= this.state;
        
    let key;
    for (key in new_form_data){

      // the event.target check below is needed to avoid throwing errors incase the event is a ck editor event
      if(event.target!==undefined){

                      if(key===event.target.id){

                            new_form_data[key]=event.target.value;
                            this.setState(new_form_data);     

                    
                      } 

        }    
          //the event.editor check below is to stop the script from throwing error incase its not a ckeditor event
          if(event.editor!==undefined){

                    // event.editor.config.bodyId below is the only way to identify the compnent id of a ck editor component so as to be
                    //able to set its data to state using event.editor.getData() method of the event object produce d by ck editor
          
                    if(key===event.editor.config.bodyId){

                          new_form_data[key]=event.editor.getData();
                          this.setState(new_form_data);

                    }
          }





    }


      
  }

  validate_form_field=()=>{

          if(this.state.message_subject==='' || this.state.message_body===''){
                  alert('All fields must be filled')
                  return false
          }
  }

  send_message_to_admin=()=>{

              if(this.validate_form_field() === false){return}
              this.setState({button_disabled:true});
              let message = {}
             
              
              message.subject = this.state.message_subject
              message.body = this.state.message_body
              
          let url= base_url + 'send-mail-to-admin'

          const token = localStorage.getItem("login_token");

          let token_header= { Authorization :  'Token '+token}     


          axios({
            method: 'post',
            url: url,
            data:message,
            headers:token_header
          })

            .then(res => {
                   setTimeout(()=>{     
                              this.setState({button_disabled:false});                                                                     
                              alert('Message Sent Succesfully') 
                              this.props.history.push("/dashboard")

                   }
                   , 4000)           

            })

          .catch((error)=> {

                setTimeout(()=>{     
                            this.setState({button_disabled:false});
                          
                            alert("Message Sending Failed")
                }
                , 4000)
          })



  }

  render() {
  
      this.get_user_from_store_and_save_to_state()
      let advice = null
      let button_text = "Send Message"

      if(this.state.button_disabled === true){
        advice = <p style={{color:'red'}}>We are trying to send your information, Please wait for confirmation before leaving this page</p>
        button_text = <ContinouslyAnimate animation_type={'shake'} animation_interval={1000} animate_data={"Sending Your Message"}/>
      }


    return (

              <div>
               
                  <Fade down>
                 
                                    <div style={{marginBottom:'5%'}}>
                                      
                                                  <div class="panel panel-success" style={{width:'85%', minWidth:'330px', margin:'auto' }}>

                                                                <div  class="panel-heading" style={{width:"100%", textAlign:'center'}}>
                                                                              <h3  >Contact Us</h3> 
                                                                          </div>


                                                        <div  style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                                        <div style={{marginTop:'5%'}}>
                                                      
                                                        <p>Please use the contact form below to communicate with us. Be assured that we will respond to you
                                                          as soon as possible. Thank you</p>	
                                                        
                                                        <div style={{marginTop:'5%'}}>Your User ID: </div>
                                                        <input value={this.state.user_id}   disabled></input>

                                                        <div style={{marginTop:'5%'}}>Your Email: </div>
                                                        <input value={this.state.user_email}  name="quantity" disabled></input>

                                                        <div style={{marginTop:'5%'}}>Message Subject: </div>
                                                        <input value={this.state.subject}  style={{width:'80%'}} id="message_subject" onChange={this.handle_form_change}></input>
                                                        
                                                        <div style={{marginTop:'5%'}}>Message Body: </div>
                                                        <CKEditor data='' onChange={this.handle_form_change} 
                                                                            config={ {
                                                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript'] ],
                                                                                      height:100,
                                                                                      bodyId:"message_body"                           
                                                                            } }
                                                        />
                                                      
                                                      

                                                      <button  style={{margin:'5% 0 5% 5%', width:'60%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={this.send_message_to_admin} type="button" >{button_text}</button>
                                                        {advice}
                                                        </div>     
                                                        </div>
                                                    </div>
                                    </div>   

                                    <Helmet>
                                              <title>Contact Us</title>  
                                            
                                            
                                            

                                    </Helmet>   
                 </Fade>

                 </div>
    )

  }

}


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
                    news_list:[],
                    acheivers_board_list:[{username:'', profile:'', performance:''}]
                  
        }

  }

  componentDidMount() {
    this.get_dashboard_news_list()
    this.get_acheivers_board_list()

    if(google_analytics !== null){google_analytics( window.location.pathname )}
   
    //if(window.location.hostname!=='localhost'){ReactGA.pageview(window.location.pathname );}
    
    if(window.location.pathname === "/register-new-account" || window.location.pathname === "/user-login" ||window.location.pathname === "/"){ 
     
      this.props.history.push("/dashboard")
    }

  }

  get_performance_summary=(time_frame, subject)=>{
    
        if(time_frame === "6m"){


                if(subject === "all"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.total_attempts - this.props.performance_summary.performance_data.six_months_data.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }


                if(subject === "mathematics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.total_attempts - this.props.performance_summary.performance_data.six_months_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }


                if(subject === "mathematics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.mathematics.total_attempts - this.props.performance_summary.performance_data.six_months_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }

                if(subject === "mathematical_literacy"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.mathematical_literacy.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.mathematical_literacy.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.mathematical_literacy.total_attempts - this.props.performance_summary.performance_data.six_months_data.mathematical_literacy.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]

                }

                if(subject === "life_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.life_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.life_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.life_sciences.total_attempts - this.props.performance_summary.performance_data.six_months_data.life_sciences.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                }

                if(subject === "physical_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.physical_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.physical_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.physical_sciences.total_attempts - this.props.performance_summary.performance_data.six_months_data.physical_sciences.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                                          
                }

                if(subject === "agricultural_science"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.agricultural_science.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.agricultural_science.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.agricultural_science.total_attempts - this.props.performance_summary.performance_data.six_months_data.agricultural_science.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "business_studies"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.business_studies.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.business_studies.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.business_studies.total_attempts - this.props.performance_summary.performance_data.six_months_data.business_studies.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "accounting"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.accounting.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.accounting.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.accounting.total_attempts - this.props.performance_summary.performance_data.six_months_data.accounting.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "economics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.six_months_data.economics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.six_months_data.economics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.six_months_data.economics.total_attempts - this.props.performance_summary.performance_data.six_months_data.economics.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]

                }
        }

        if(time_frame === "3m"){

                if(subject === "all"){
                          let percent = 0
                          let attempted_questions = this.props.performance_summary.performance_data.three_months_data.total_attempts
                          let correct_questions = this.props.performance_summary.performance_data.three_months_data.correct_attempts
                          let wrong_questions = this.props.performance_summary.performance_data.three_months_data.total_attempts - this.props.performance_summary.performance_data.three_months_data.correct_attempts
                      
                          if (correct_questions !== 0 ){ 
                                    percent =  (correct_questions / attempted_questions *100).toFixed(1)
                          }
                      
                          return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }


                if(subject === "mathematics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.mathematics.total_attempts - this.props.performance_summary.performance_data.three_months_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }

                if(subject === "mathematical_literacy"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.mathematical_literacy.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.mathematical_literacy.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.mathematical_literacy.total_attempts - this.props.performance_summary.performance_data.three_months_data.mathematical_literacy.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]

                }

                if(subject === "life_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.life_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.life_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.life_sciences.total_attempts - this.props.performance_summary.performance_data.three_months_data.life_sciences.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                }

                if(subject === "physical_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.physical_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.physical_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.physical_sciences.total_attempts - this.props.performance_summary.performance_data.three_months_data.physical_sciences.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                                          
                }

                if(subject === "agricultural_science"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.agricultural_science.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.agricultural_science.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.agricultural_science.total_attempts - this.props.performance_summary.performance_data.three_months_data.agricultural_science.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "business_studies"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.business_studies.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.business_studies.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.business_studies.total_attempts - this.props.performance_summary.performance_data.three_months_data.business_studies.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "accounting"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.accounting.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.accounting.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.accounting.total_attempts - this.props.performance_summary.performance_data.three_months_data.accounting.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "economics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.three_months_data.economics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.three_months_data.economics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.three_months_data.economics.total_attempts - this.props.performance_summary.performance_data.three_months_data.economics.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]

                }
        }
        
        if(time_frame === "1m"){



                if(subject === "all"){
                          let percent = 0
                          let attempted_questions = this.props.performance_summary.performance_data.one_month_data.total_attempts
                          let correct_questions = this.props.performance_summary.performance_data.one_month_data.correct_attempts
                          let wrong_questions = this.props.performance_summary.performance_data.one_month_data.total_attempts - this.props.performance_summary.performance_data.one_month_data.correct_attempts
                      
                          if (correct_questions !== 0 ){ 
                                    percent =  (correct_questions / attempted_questions *100).toFixed(1)
                          }
                      
                          return[attempted_questions, percent, correct_questions , wrong_questions  ]
                 }



                if(subject === "mathematics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.mathematics.total_attempts - this.props.performance_summary.performance_data.one_month_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }

                if(subject === "mathematical_literacy"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.mathematical_literacy.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.mathematical_literacy.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.mathematical_literacy.total_attempts - this.props.performance_summary.performance_data.one_month_data.mathematical_literacy.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]

                }

                if(subject === "life_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.life_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.life_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.life_sciences.total_attempts - this.props.performance_summary.performance_data.one_month_data.life_sciences.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                }

                if(subject === "physical_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.physical_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.physical_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.physical_sciences.total_attempts - this.props.performance_summary.performance_data.one_month_data.physical_sciences.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                                          
                }

                if(subject === "agricultural_science"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.agricultural_science.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.agricultural_science.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.agricultural_science.total_attempts - this.props.performance_summary.performance_data.one_month_data.agricultural_science.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "business_studies"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.business_studies.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.business_studies.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.business_studies.total_attempts - this.props.performance_summary.performance_data.one_month_data.business_studies.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "accounting"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.accounting.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.accounting.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.accounting.total_attempts - this.props.performance_summary.performance_data.one_month_data.accounting.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "economics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_month_data.economics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_month_data.economics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_month_data.economics.total_attempts - this.props.performance_summary.performance_data.one_month_data.economics.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]

                }
        }

        if(time_frame === "1w"){

                if(subject === "all"){
                          let percent = 0
                          let attempted_questions = this.props.performance_summary.performance_data.one_week_data.total_attempts
                          let correct_questions = this.props.performance_summary.performance_data.one_week_data.correct_attempts
                          let wrong_questions = this.props.performance_summary.performance_data.one_week_data.total_attempts - this.props.performance_summary.performance_data.one_week_data.correct_attempts
                      
                          if (correct_questions !== 0 ){ 
                                    percent =  (correct_questions / attempted_questions *100).toFixed(1)
                          }
                      
                          return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }



                if(subject === "mathematics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.mathematics.total_attempts - this.props.performance_summary.performance_data.one_week_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }

                if(subject === "mathematical_literacy"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.mathematical_literacy.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.mathematical_literacy.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.mathematical_literacy.total_attempts - this.props.performance_summary.performance_data.one_week_data.mathematical_literacy.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]

                }

                if(subject === "life_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.life_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.life_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.life_sciences.total_attempts - this.props.performance_summary.performance_data.one_week_data.life_sciences.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                }

                if(subject === "physical_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.physical_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.physical_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.physical_sciences.total_attempts - this.props.performance_summary.performance_data.one_week_data.physical_sciences.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                                          
                }

                if(subject === "agricultural_science"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.agricultural_science.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.agricultural_science.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.agricultural_science.total_attempts - this.props.performance_summary.performance_data.one_week_data.agricultural_science.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "business_studies"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.business_studies.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.business_studies.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.business_studies.total_attempts - this.props.performance_summary.performance_data.one_week_data.business_studies.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "accounting"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.accounting.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.accounting.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.accounting.total_attempts - this.props.performance_summary.performance_data.one_week_data.accounting.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "economics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_week_data.economics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_week_data.economics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_week_data.economics.total_attempts - this.props.performance_summary.performance_data.one_week_data.economics.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]

                }
        }

        if(time_frame === "1d"){
                 if(subject === "all"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.total_attempts - this.props.performance_summary.performance_data.one_day_data.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }


                if(subject === "mathematics"){
                            let percent = 0
                            //alert("stop")
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.mathematics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.mathematics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.mathematics.total_attempts - this.props.performance_summary.performance_data.one_day_data.mathematics.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]
                }

                if(subject === "mathematical_literacy"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.mathematical_literacy.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.mathematical_literacy.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.mathematical_literacy.total_attempts - this.props.performance_summary.performance_data.one_day_data.mathematical_literacy.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent, correct_questions , wrong_questions  ]

                }

                if(subject === "life_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.life_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.life_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.life_sciences.total_attempts - this.props.performance_summary.performance_data.one_day_data.life_sciences.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                }

                if(subject === "physical_sciences"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.physical_sciences.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.physical_sciences.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.physical_sciences.total_attempts - this.props.performance_summary.performance_data.one_day_data.physical_sciences.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                                          
                }

                if(subject === "agricultural_science"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.agricultural_science.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.agricultural_science.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.agricultural_science.total_attempts - this.props.performance_summary.performance_data.one_day_data.agricultural_science.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "business_studies"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.business_studies.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.business_studies.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.business_studies.total_attempts - this.props.performance_summary.performance_data.one_day_data.business_studies.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "accounting"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.accounting.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.accounting.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.accounting.total_attempts - this.props.performance_summary.performance_data.one_day_data.accounting.correct_attempts
                        
                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }
                        
                            return[attempted_questions, percent,correct_questions , wrong_questions  ]
                        
                }

                if(subject === "economics"){
                            let percent = 0
                            let attempted_questions = this.props.performance_summary.performance_data.one_day_data.economics.total_attempts
                            let correct_questions = this.props.performance_summary.performance_data.one_day_data.economics.correct_attempts
                            let wrong_questions = this.props.performance_summary.performance_data.one_day_data.economics.total_attempts - this.props.performance_summary.performance_data.one_day_data.economics.correct_attempts

                            if (correct_questions !== 0 ){ 
                                      percent =  (correct_questions / attempted_questions *100).toFixed(1)
                            }

                            return[attempted_questions, percent,correct_questions , wrong_questions  ]

                }
        }

  }

  six_months_board=()=>{

    let six_months_all_subjects_summary = this.get_performance_summary('6m','all' )
    let six_months_mathematics_summary = this.get_performance_summary('6m','mathematics' )
    let six_months_mathematical_literacy_summary = this.get_performance_summary('6m','mathematical_literacy' )
    let six_months_life_sciences_summary = this.get_performance_summary('6m','life_sciences' )
    let six_months_physical_sciences_summary = this.get_performance_summary('6m','physical_sciences' )
    let six_months_business_studies_summary = this.get_performance_summary('6m','business_studies' )
    let six_months_accounting_summary = this.get_performance_summary('6m','accounting' )
    let six_months_economics_summary = this.get_performance_summary('6m','economics' )
    let six_months_agricultural_science_summary = this.get_performance_summary('6m','agricultural_science' )
    



    let six_months = <div class="panel panel-default">
    
    <div class="panel-heading">
    
       <h3>
              <a data-toggle="collapse" data-parent="#accordion" href="#six_months">6 Months Performance Data</a>
        </h3>
    </div>


   
      <div id="six_months" class="panel-collapse collapse">
      <div style={{maxWidth:'500px',  width:'90%', minWidth:'280px', margin:'auto', float:'right',}}>

      <div class="widget-header"> 
            <h3> All Subjects</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_all_subjects_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_all_subjects_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_all_subjects_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_all_subjects_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          



          <div class="widget-header"> 
            <h3> Mathematics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          


            <div class="widget-header"> 
            <h3> Mathematical Literacy</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematical_literacy_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematical_literacy_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematical_literacy_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematical_literacy_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Life Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_life_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_life_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_life_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_life_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Physical Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_physical_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_physical_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_physical_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_physical_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Business Studies</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_business_studies_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_business_studies_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_business_studies_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_business_studies_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Accounting</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_accounting_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_accounting_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_accounting_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_accounting_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Economics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_economics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_economics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_economics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_economics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Agricultural Science</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_agricultural_science_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_agricultural_science_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_agricultural_science_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_agricultural_science_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>




          </div>
    </div>
    </div>

    return six_months


  }

  three_months_board=()=>{

    let six_months_all_subjects_summary = this.get_performance_summary('3m','all' )
    let six_months_mathematics_summary = this.get_performance_summary('3m','mathematics' )
    let six_months_mathematical_literacy_summary = this.get_performance_summary('3m','mathematical_literacy' )
    let six_months_life_sciences_summary = this.get_performance_summary('3m','life_sciences' )
    let six_months_physical_sciences_summary = this.get_performance_summary('3m','physical_sciences' )
    let six_months_business_studies_summary = this.get_performance_summary('3m','business_studies' )
    let six_months_accounting_summary = this.get_performance_summary('3m','accounting' )
    let six_months_economics_summary = this.get_performance_summary('3m','economics' )
    let six_months_agricultural_science_summary = this.get_performance_summary('3m','agricultural_science' )
    


    let six_months = <div class="panel panel-default">
    
    <div class="panel-heading">
    
        <h3>  
              <a data-toggle="collapse" data-parent="#accordion" href="#three_months">3 Months Performance Data</a>
        </h3> 
    </div>


   
      <div id="three_months" class="panel-collapse collapse">
      <div style={{maxWidth:'500px',  width:'90%', minWidth:'280px', margin:'auto', float:'right',}}>

      <div class="widget-header"> 
            <h3> All Subjects</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_all_subjects_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_all_subjects_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_all_subjects_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_all_subjects_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          



          <div class="widget-header"> 
            <h3> Mathematics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          


            <div class="widget-header"> 
            <h3> Mathematical Literacy</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematical_literacy_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematical_literacy_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematical_literacy_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematical_literacy_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Life Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_life_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_life_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_life_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_life_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Physical Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_physical_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_physical_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_physical_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_physical_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Business Studies</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_business_studies_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_business_studies_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_business_studies_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_business_studies_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Accounting</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_accounting_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_accounting_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_accounting_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_accounting_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Economics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_economics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_economics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_economics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_economics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Agricultural Science</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_agricultural_science_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_agricultural_science_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_agricultural_science_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_agricultural_science_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>




          </div>
    </div>
    </div>

    

    return six_months


  }

  one_month_board=()=>{

    let six_months_all_subjects_summary = this.get_performance_summary('1m','all' )
    let six_months_mathematics_summary = this.get_performance_summary('1m','mathematics' )
    let six_months_mathematical_literacy_summary = this.get_performance_summary('1m','mathematical_literacy' )
    let six_months_life_sciences_summary = this.get_performance_summary('1m','life_sciences' )
    let six_months_physical_sciences_summary = this.get_performance_summary('1m','physical_sciences' )
    let six_months_business_studies_summary = this.get_performance_summary('1m','business_studies' )
    let six_months_accounting_summary = this.get_performance_summary('1m','accounting' )
    let six_months_economics_summary = this.get_performance_summary('1m','economics' )
    let six_months_agricultural_science_summary = this.get_performance_summary('1m','agricultural_science' )
    


    let six_months = <div class="panel panel-default">
    
    <div class="panel-heading">
    
        <h3>  
              <a data-toggle="collapse" data-parent="#accordion" href="#one_month">1 Month Performance Data</a>
        </h3> 
    </div>


   
      <div id="one_month" class="panel-collapse collapse">
      <div style={{maxWidth:'500px',  width:'90%', minWidth:'280px', margin:'auto', float:'right',}}>

      <div class="widget-header"> 
            <h3> All Subjects</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_all_subjects_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_all_subjects_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_all_subjects_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_all_subjects_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          



          <div class="widget-header"> 
            <h3> Mathematics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          


            <div class="widget-header"> 
            <h3> Mathematical Literacy</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematical_literacy_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematical_literacy_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematical_literacy_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematical_literacy_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Life Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_life_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_life_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_life_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_life_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Physical Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_physical_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_physical_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_physical_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_physical_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Business Studies</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_business_studies_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_business_studies_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_business_studies_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_business_studies_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Accounting</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_accounting_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_accounting_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_accounting_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_accounting_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Economics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_economics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_economics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_economics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_economics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Agricultural Science</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_agricultural_science_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_agricultural_science_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_agricultural_science_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_agricultural_science_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>




          </div>
    </div>
    </div>

    

    return six_months


  }

  one_week_board=()=>{

    let six_months_all_subjects_summary = this.get_performance_summary('1w','all' )
    let six_months_mathematics_summary = this.get_performance_summary('1w','mathematics' )
    let six_months_mathematical_literacy_summary = this.get_performance_summary('1w','mathematical_literacy' )
    let six_months_life_sciences_summary = this.get_performance_summary('1w','life_sciences' )
    let six_months_physical_sciences_summary = this.get_performance_summary('1w','physical_sciences' )
    let six_months_business_studies_summary = this.get_performance_summary('1w','business_studies' )
    let six_months_accounting_summary = this.get_performance_summary('1w','accounting' )
    let six_months_economics_summary = this.get_performance_summary('1w','economics' )
    let six_months_agricultural_science_summary = this.get_performance_summary('1w','agricultural_science' )
    


    let six_months = <div class="panel panel-default">
    
    <div class="panel-heading">
    
        <h3>  
              <a data-toggle="collapse" data-parent="#accordion" href="#one_week">1 Week Performance Data</a>
        </h3> 
    </div>


    
      <div id="one_week" class="panel-collapse collapse">
      <div style={{maxWidth:'500px',  width:'90%', minWidth:'280px', margin:'auto', float:'right',}}>

      <div class="widget-header"> 
            <h3> All Subjects</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_all_subjects_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_all_subjects_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_all_subjects_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_all_subjects_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          



          <div class="widget-header"> 
            <h3> Mathematics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          


            <div class="widget-header"> 
            <h3> Mathematical Literacy</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematical_literacy_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematical_literacy_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematical_literacy_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematical_literacy_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Life Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_life_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_life_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_life_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_life_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Physical Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_physical_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_physical_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_physical_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_physical_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Business Studies</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_business_studies_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_business_studies_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_business_studies_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_business_studies_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Accounting</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_accounting_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_accounting_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_accounting_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_accounting_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Economics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_economics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_economics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_economics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_economics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Agricultural Science</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_agricultural_science_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_agricultural_science_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_agricultural_science_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_agricultural_science_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>




          </div>
    </div>
    </div>

    

    return six_months


  }

  one_day_board=()=>{

    let six_months_all_subjects_summary = this.get_performance_summary('1d','all' )
    let six_months_mathematics_summary = this.get_performance_summary('1d','mathematics' )
    let six_months_mathematical_literacy_summary = this.get_performance_summary('1d','mathematical_literacy' )
    let six_months_life_sciences_summary = this.get_performance_summary('1d','life_sciences' )
    let six_months_physical_sciences_summary = this.get_performance_summary('1d','physical_sciences' )
    let six_months_business_studies_summary = this.get_performance_summary('1d','business_studies' )
    let six_months_accounting_summary = this.get_performance_summary('1d','accounting' )
    let six_months_economics_summary = this.get_performance_summary('1d','economics' )
    let six_months_agricultural_science_summary = this.get_performance_summary('1d','agricultural_science' )
    


    let six_months = <div class="panel panel-default" >
    
    <div class="panel-heading">
    
        <h3>  
              <a data-toggle="collapse" data-parent="#accordion" href="#one_day">1 Day Performance Data</a>
        </h3> 
    </div>

   

      <div id="one_day" class="panel-collapse collapse ">
      <div style={{maxWidth:'500px',  width:'90%', minWidth:'280px', margin:'auto', float:'right',}}>

      <div class="widget-header"> 
            <h3> All Subjects</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_all_subjects_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_all_subjects_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_all_subjects_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_all_subjects_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          



          <div class="widget-header"> 
            <h3> Mathematics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>
          


            <div class="widget-header"> 
            <h3> Mathematical Literacy</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_mathematical_literacy_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_mathematical_literacy_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_mathematical_literacy_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_mathematical_literacy_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Life Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_life_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_life_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_life_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_life_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>


            <div class="widget-header"> 
            <h3> Physical Sciences</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_physical_sciences_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_physical_sciences_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_physical_sciences_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_physical_sciences_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Business Studies</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_business_studies_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_business_studies_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_business_studies_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_business_studies_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Accounting</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_accounting_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_accounting_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_accounting_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_accounting_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Economics</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_economics_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_economics_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_economics_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_economics_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>

            <div class="widget-header"> 
            <h3> Agricultural Science</h3>
          </div>
  
            <div class="widget-content">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th> Description </th>
                    <th> Data</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Total Questions Attempted </td>
                    <td>{six_months_agricultural_science_summary[0]} </td>
                
                  </tr>

                  <tr>
                    <td> % Correct</td>
                    <td> {six_months_agricultural_science_summary[1]}% </td>
          
                  </tr>

                  <tr>
                    <td> Correct Answer</td>
                    <td> {six_months_agricultural_science_summary[2]} </td>
              
                  </tr>
                  <tr>
                    <td> Wrong Answer</td>
                    <td> {six_months_agricultural_science_summary[3]}</td>
      
                  </tr>
                  
                
                
                </tbody>
              </table>
            </div>




          </div>
          </div>
    </div>

    

    return six_months


  }

  get_dashboard_news_list=()=>{

                

                  let url= base_url + 'list-of-dashboard-news'

                  const token = localStorage.getItem("login_token");
                  
                  let token_header= { Authorization :  'Token '+token}     
                  
                
                  axios({
                    method: 'get',
                    url: url,
                   
                    headers:token_header
                  })
                  
                    .then(res => {
                                              
                          this.setState({news_list: res.data });

                      

                    })

                  .catch((error)=> {
                        
                      
                      
                  })


  }

  get_acheivers_board_list=()=>{

                

    let url= base_url + 'get-acheivers-data-from-db'

    const token = localStorage.getItem("login_token");
    
    let token_header= { Authorization :  'Token '+token}     
    
  
    axios({
      method: 'get',
      url: url,
     
      headers:token_header
    })
    
      .then(res => {
                                
               this.prepare_acheivers_board_data(res.data)

        

      })

    .catch((error)=> {
          
         
        
    })


  }

  prepare_acheivers_board_data=(data)=>{
    
          let x = 0
          
          while (x < data.length){

                    if(data[x].profile.school ===""){ data[x].profile.school="Profile Incomplete" }
                    if(data[x].profile.district ===""){ data[x].profile.district="Profile Incomplete" }
                    if(data[x].profile.image ===""){ data[x].profile.image= cordova_file_prefix + "empty_profile_picture.jpg" }
                    x++
          }

          this.setState({acheivers_board_list:data });

  }

  get_dashboard_news_date=(date)=>{
    
    let month = date.substr(5, 2)
          let day = date.substr(8, 2)
          if(month==='01'){month = 'Jan'}
          if(month==='02'){month = 'Feb'}
          if(month==='03'){month = 'Mar'}
          if(month==='04'){month = 'April'}
          if(month==='05'){month = 'May'}
          if(month==='06'){month = 'June'}
          if(month==='07'){month = 'July'}
          if(month==='08'){month = 'Aug'}
          if(month==='09'){month = 'Sept'}
          if(month==='10'){month = 'Oct'}
          if(month==='11'){month = 'Nov'}
          if(month==='12'){month = 'Dec'}
        
      return [day, month]

  }
  watch_video=()=>{
    //localStorage.removeItem("has_watched_tutorial_video");
    this.props.change_has_watched_tutorial_video_status(false) 

  }

  mobile_shorcut_menu=()=>{

              let mobile_shortcut_menu = null
              if(window.innerWidth<760){ 
                      mobile_shortcut_menu =  <div class="subnavbar">
                                                  <div class="subnavbar-inner" style={{marginLeft:'0%'}}>
                                                      <div class="container">
                                                              <ul class="mainnav">
                            
                                                                
                                                                        <li ><a ><i class="icon-list-alt"></i><span>   <Link to="/past-questions-centre">Digital Matric Questions</Link> </span> </a> </li>
                                                                        <li ><a ><i class="icon-facetime-video"></i><span>   <Link to="/learning_centre">Learn With Videos </Link> </span> </a> </li>
                                                                      
                                                                        <li ><a ><i class="icon-list-alt"></i><span>   <Link to="/past_pdf_centre">Past Matric Papers </Link> </span> </a> </li>

                                                                      
                                                              </ul>
                                                      </div>

                                                   </div>

                                                </div>


              }


              return mobile_shortcut_menu

  }

  convert_time_to_time_since=()=>{

              let date = Date.parse(this.props.performance_summary.last_updated_at)
              

              if (this.props.performance_summary.last_updated_at === undefined){
                return "You do not have data to report yet"
              }
              let seconds = Math.floor((new Date() - date) / 1000);

              let interval = seconds / 31536000;

              if (interval > 1) {
                return Math.floor(interval) + " years"+ " Since Last Update";
              }
              interval = seconds / 2592000;
              if (interval > 1) {
                return Math.floor(interval) + " months" + " Since Last Update";
              }
              interval = seconds / 86400;
              if (interval > 1) {
                return Math.floor(interval) + " days" + " Since Last Update";
              }
              interval = seconds / 3600;
              if (interval > 1) {
                return Math.floor(interval) + " hours" + " Since Last Update";
              }
              interval = seconds / 60;
              if (interval > 1) {
                return Math.floor(interval) + " minutes" + " Since Last Update";
              }
              return Math.floor(seconds) + " seconds" + " Since Last Update";


  }
  
  render() {
    let six_months_total_summary = this.get_performance_summary('6m','all' )
    let time_since = this.convert_time_to_time_since()
    
    let convert_option= null
    if(this.props.user_account.platform !=="learner"){

      convert_option =  <p style={{marginLeft:"10px", color:"red"}}> Convert your account to a permanent account <button onClick={()=>{ this.props.set_convert_account_to_permanent_page_data(true)}}>Click Here</button></p>

    }
   
    return (
                        <Fade left>
                                
                                      <div style={{width:'85%',  margin:'auto', overflow: 'auto', minWidth:'320px'}}>
                                          
                                            <div id="column1" style={{maxWidth:'575px',  margin:'auto', float:'left', padding:'2% 1% 2% 0%'}}>
                                                    <div class="widget widget-nopad">


                                                          {this.mobile_shorcut_menu()}


                                                          <div class="widget-header"> <i class="icon-list-alt"></i>
                                                            <h3> My 6 Months Stats Summary</h3>
                                                          </div>

                                                          <div class="widget-content">
                                                            <div class="widget big-stats-container">
                                                              <div class="widget-content">
                                                                <h6 class="bigstats" style={{ textAlign:'center'}}> {time_since} </h6>
                                                                
                                                                      <div id="big_stats" class="cf">
                                                                      
                                                                                  <div class="stat"> <span class="value"><ContinouslyAnimate animation_type={"shake"} animation_interval={10000} animation_delay ={0} animate_data ={six_months_total_summary[0]}/></span>  <p><b>Questions Attempted </b></p><hr></hr></div>
                                                                                        
                                                                                  <div class="stat"><span class="value"><ContinouslyAnimate animation_type={"shake"} animation_interval={10000} animation_delay ={1000} animate_data ={six_months_total_summary[1]+'%'}/></span><p><b> Pass Rate <hr></hr></b></p> </div>
                                                                                

                                                                                  <div class="stat"> <span class="value"><ContinouslyAnimate animation_type={"shake"} animation_interval={10000} animation_delay ={2000} animate_data ={six_months_total_summary[2]}/></span><p><b> Correct Attempts<hr></hr></b></p> </div>
                                                                                
                                                                                  
                                                                                  <div class="stat"> <span class="value"><ContinouslyAnimate animation_type={"shake"} animation_interval={10000} animation_delay ={3000} animate_data ={six_months_total_summary[3]}/></span><p><b>Wrong Attempts<hr></hr> </b></p> </div>
                                                                
                                                                  
                                                                        </div>

                                                                        
                                                              
                                                              </div>
                                                                  
                                                              
                                                            </div>



                                                            <p style={{marginLeft:"10px"}}> Watch a two minutes introductory Video <button onClick={this.watch_video}>Click Here</button></p>
                                                            <hr/>
                                                            { convert_option }
                                                          
                                                        



                                                          </div>
                                                          
                                                        </div>


                                                        <div class="widget widget-nopad">
                                                              <div class="widget-header"> <i class="icon-list-alt"></i>
                                                                <h3> Recent News</h3>
                                                                <button style={{float:'right'}}><Link to="/view_all_news">View All News Items</Link></button>
                                                              </div>
                                                            
                                                              <div class="widget-content">
                                                                <ul class="news-items">


                                                                

                                                                  {this.state.news_list.map((item, index) => ( 
                                                  
                                                                      <li>
                                                                            
                                                                            <div class="news-item-date"> <span class="news-item-day">{this.get_dashboard_news_date(item.created_at)['0']}</span> <span class="news-item-month">{this.get_dashboard_news_date(item.created_at)['1']}</span> </div>
                                                                            <div class="news-item-detail"> <a href="#" class="news-item-title" >{item.news_subject}</a>
                                                                              <p class="news-item-preview"> {item.news_body}</p> 
                                                                            </div>
                                                                        
                                                                      </li>
                                                
                                                                    ))}  







                                                                
                                                                </ul>
                                                              </div>
                                                            
                                                            </div>



                                                          











                                              `</div>
                                              <div id="column2" style={{maxWidth:'570px',  minWidth:'320px', margin:'auto', float:'left', padding:'2% 0% 2% 1%'}}>


                            <div class="panel-group" id="accordion">

                                  {this.six_months_board()}
                                  {this.three_months_board()}
                                  {this.one_month_board()}
                                  {this.one_week_board()}
                                  {this.one_day_board()}

                                                      


                          </div>



                                                    <div class="widget">
                                                    
                                                          <div class="widget-header"> <i class="icon-file"></i>
                                                          
                                                            <h3> Last 7 Days Achievers Board</h3>
                                                          </div>
                                                        
                                                          <div class="widget-content">
                                                            <ul class="messages_layout">



                                                                {this.state. acheivers_board_list.map((item, index) => ( 
                                                      
                                                    
                                                                        <li class="from_user left"> <a href="#" class="avatar"><img src={item.profile.image} style={{width:'25%'}}/></a>
                                                                          <div class="message_wrap"> <span class="arrow"></span>
                                                                            <div class="info"> <a class="name">{item.name}</a> 
                                                                              <div class="options_arrow">
                                                                                <div class="dropdown pull-right"> <a class="dropdown-toggle " id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="#"> <i class=" icon-caret-down"></i> </a>
                                                                                  <ul class="dropdown-menu " role="menu" aria-labelledby="dLabel">
                                                                                    <li><a href="#"><i class=" icon-share-alt icon-large"></i>View Profile</a></li>
                                                                                    <li><a href="#"><i class=" icon-trash icon-large"></i> Follow</a></li>
                                                                                    <li><a href="#"><i class=" icon-share icon-large"></i> Like</a></li>
                                                                                  </ul>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <div class="text"> LearnersHub Position : {item.position}  </div>
                                                                            <div class="text"> Questions Attempted : {item.performance.total_attempts}  </div>
                                                                            <div class="text"> Pass Rate : {((item.performance.correct_attempts/item.performance.total_attempts)*100).toFixed(1) + '%'}  </div>
                                                                            <div class="text"> District : {item.profile.district}  </div>
                                                                            <div class="text"> <b>School</b> : {item.profile.school}  </div>
                                                                          
                                                                            
                                                                          </div>
                                                                        </li>
                                                                    ))}  


                                                            </ul>
                                                          </div>
                                                        
                                                        </div>




                                              </div>



                                      </div>

                                      <Helmet>
                                              <title>Dashboard</title>  
                                            
                                            
                                            

                                      </Helmet>   
                        </Fade>

    )

  }

}


class Complain extends Component {

  constructor(props) {
    super(props);
    this.state = {
                     
                      question_id:'',
                      selected_answer:'',
                      complain_category:'',
                      complain_body:'',
                      disable_button:false

                 }

  }

  componentDidMount(){
    this.setState({   question_id :this.props.data.question_id,
                      selected_answer : this.props.data.selected_answer

                   });
                   
                   if(google_analytics !== null){google_analytics( "/complain-page" )}
                   //if(window.location.hostname!=='localhost'){ReactGA.pageview("/complain-page"  );}
                    
                
                    
                


  }

 
  handle_form_change=(event)=> {
   
    let new_form_data= this.state;
        
    let key;
    for (key in new_form_data){

      // the event.target check below is needed to avoid throwing errors incase the event is a ck editor event
      if(event.target!==undefined){

                      if(key===event.target.id){

                            new_form_data[key]=event.target.value;
                            this.setState(new_form_data);     

                    
                      } 

        }    
          //the event.editor check below is to stop the script from throwing error incase its not a ckeditor event
          if(event.editor!==undefined){

                    // event.editor.config.bodyId below is the only way to identify the compnent id of a ck editor component so as to be
                    //able to set its data to state using event.editor.getData() method of the event object produce d by ck editor
          
                    if(key===event.editor.config.bodyId){

                          new_form_data[key]=event.editor.getData();
                          this.setState(new_form_data);

                    }
          }





    }


      
  }

  validate_form_field=()=>{

          if(this.state.complain_category===''){
                  alert('Please Select a Complain Category')
                  return false
          }
         if (this.state.complain_body===''){
              alert('Complain body cannot be empty')
              return false
         }
  }

  send_complain_to_admin=()=>{

              if(this.validate_form_field() === false){return}
              this.setState({disable_button:true});
              let complaint = {}
             
              complaint.question_id = this.state.question_id
              complaint.selected_answer = this.state.selected_answer
              complaint.category = this.state.complain_category
              complaint.body = this.state.complain_body
              
          let url= base_url + 'add-new-complaint'

          const token = localStorage.getItem("login_token");

          let token_header= { Authorization :  'Token '+token}     


          axios({
            method: 'post',
            url: url,
            data:complaint,
            headers:token_header
          })

            .then(res => {
                        
                  this.setState({disable_button:false});
                  
                  
                  alert('Complaint Submitted Succesfully') 

            })

          .catch((error)=> {
                this.setState({disable_button:false});
              
                alert("Conplaint Sending Failed")
              
          })



  }

  render() {
  
      let advice = null
      let send_button = <button  style={{margin:'5% 0 5% 5%', width:'60%', fontSize:'150%', height:'70px' }} class="submit_or_next_question_button" onClick={this.send_complain_to_admin} type="button" >Send Message</button>

      if(this.state.disable_button === true){
        advice = <p style={{color:'red'}}>We are trying to send your information, Please wait for confirmation before leaving this page</p>
        send_button = null
      }


    return (

                <div style={{marginBottom:'5%'}}>
                  
                  <div  style={{width:'100%', minWidth:'330px', margin:'auto' }}>

                                <div  class="panel-heading" style={{width:"100%", textAlign:'center'}}>
                                              <h2  >Make a Complaint</h2> 
                                          </div>


                        <div  style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                        <div style={{marginTop:'5%'}}>
                       
                        <p>Please use the form below to communicate with us concerning the question you just answered. Be assured that we will respond to you
                          as soon as possible. Thank you</p>
                         <p style={{color:'red'}}>Please if your issue is not related to a particular question, Use the Contact us form <Link to="/contact_us">here</Link> to get in touch with us. Thanks</p> 	
                        
                        <div style={{marginTop:'5%'}}>Question ID: </div>
                        <input value={this.state.question_id}   disabled></input>

                        <div style={{marginTop:'5%'}}>Your Answer: </div>
                        <span dangerouslySetInnerHTML={{ __html: this.state.selected_answer}}></span>

                        <div style={{marginTop:'5%'}}>Complaint Category: </div>
                        <select  onChange={this.handle_form_change} id="complain_category">               
                                <option value=''>Select a Complaint Category</option>     
                                <option value='question_is_wrong'>Question is Wrong</option>
                                <option value='correct_answer_is_wrong'>Correct Answer is Wrong</option>
                                <option value='addendum_is_wrong'>Addendum is Wrong or Not Sufficient</option>
                                <option value='solution_is_wrong'>Solution is Wrong or Not Sufficient</option>
                                <option value='formula_is_wrong'>Information Sheet is Wrong or Not Sufficient</option>
                                <option value='other_reasons'>Other Reasons</option>         
                                                         
                        </select>
                        
                        <div style={{marginTop:'5%'}}>Message Body: </div>
                        <CKEditor data='' onChange={this.handle_form_change} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript'] ],
                                                      height:100,
                                                      bodyId:"complain_body"                           
                                            } }
                        />
                       
                       

                        {send_button} 
                        {advice}
                        </div>     
                        </div>
                    </div>
                 </div>   
    )

  }

}


class View_all_news extends Component {

  constructor(props) {
    super(props);
    this.state = {
                   
                    news_list:[],

                 }

  }

  componentDidMount(){
                this.get_dashboard_news_list()

                if(google_analytics !== null){google_analytics( window.location.pathname)}   
                //if(window.location.hostname!=='localhost'){ReactGA.pageview(window.location.pathname );}
                  
              
      
    

  }

  get_dashboard_news_list=()=>{

                

              let url= base_url + 'all-news'

              const token = localStorage.getItem("login_token");
              
              let token_header= { Authorization :  'Token '+token}     
              
            
              axios({
                method: 'get',
                url: url,
              
                headers:token_header
              })
              
                .then(res => {
                                          
                      this.setState({news_list: res.data });

                  

                })

              .catch((error)=> {
                    
                 
                  
              })


  }

  get_dashboard_news_date=(date)=>{
    
    let month = date.substr(5, 2)
          let day = date.substr(8, 2)
          if(month==='01'){month = 'Jan'}
          if(month==='02'){month = 'Feb'}
          if(month==='03'){month = 'Mar'}
          if(month==='04'){month = 'April'}
          if(month==='05'){month = 'May'}
          if(month==='06'){month = 'June'}
          if(month==='07'){month = 'July'}
          if(month==='08'){month = 'Aug'}
          if(month==='09'){month = 'Sept'}
          if(month==='10'){month = 'Oct'}
          if(month==='11'){month = 'Nov'}
          if(month==='12'){month = 'Dec'}
        
      return [day, month]

  }

  render() {
  

    return (

                <div style={{marginBottom:'5%'}}>
                  
                                  <div class="panel panel-success" style={{width:'85%', minWidth:'330px', margin:'auto' }}>

                                                <div  class="panel-heading" style={{width:"100%", textAlign:'center'}}>
                                                              <h3> All News </h3> 
                                                          </div>


                                        <div  style={{width:'95%', maxWidth:'600px', margin:'auto' }}>
                                        <div style={{marginTop:'5%'}}>


                                        <ul class="news-items">
                                        {this.state.news_list.map((item, index) => ( 
                                                    
                                                    <li>
                                                          
                                                          <div class="news-item-date"> <span class="news-item-day">{this.get_dashboard_news_date(item.created_at)['0']}</span> <span class="news-item-month">{this.get_dashboard_news_date(item.created_at)['1']}</span> </div>
                                                          <div class="news-item-detail"> <a href="#" class="news-item-title" >{item.news_subject}</a>
                                                            <p class="news-item-preview"> {item.news_body}</p> 
                                                          </div>
                                                      
                                                    </li>
                              
                                                  ))}  
                                      
                                      </ul>

                                      
                                        </div>     
                                        </div>
                                    </div>



                                    <Helmet>
                                              <title>View all News</title>  
                                            
                                            
                                            

                                    </Helmet>   
                 </div>   
    )

  }

}











///////////////connecting SubjectTopics to store

const mapStateToSubjectTopics_Props = state => {
  return{            
           
             user_profile: state.user_profile,
         }
};

const mapDispatchToSubjectTopics_Props = dispatch => ({  

      save_user_profile_to_store : (data) => dispatch(save_user_profile_to_store(data)),
      save_database_questions_id_to_store : (data) => dispatch(save_database_questions_id_to_store(data)),
      save_un_done_questions_to_store : (data) => dispatch(save_un_done_questions_to_store(data)),
     
});
let ConnectSubjectTopics  = connect (mapStateToSubjectTopics_Props, mapDispatchToSubjectTopics_Props)(SubjectTopics);





///////////////connecting Confirm_email to store

const mapDispatchToConfirm_email_Props = dispatch => ({  

  save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
     
});
let ConnectConfirm_email  = connect (null, mapDispatchToConfirm_email_Props)(Confirm_email);





///////////////connecting convertaccount to store

const mapDispatchToConvertAccount_Props = dispatch => ({  

  save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
     
});
let ConnectConvertAccount  = connect (null, mapDispatchToConvertAccount_Props)(ConvertAccount);


///////////////connecting dashboard to store
const mapStateTodashboard_pageProps = state => {
  return{            
            performance_summary : state.performance_summary,
            has_watched_tutorial_video:state.has_watched_tutorial_video,
            user_account: state.user_account, 
         }
};

const mapDispatchTodashboard_Props = dispatch => ({  

  change_has_watched_tutorial_video_status : (data) => dispatch(change_has_watched_tutorial_video_status(data)),
  change_should_show_question_setup_page : (data) => dispatch(change_should_show_question_setup_page(data)),
  set_convert_account_to_permanent_page_data : (data) => dispatch(set_convert_account_to_permanent_page_data(data)),
     
});
let ConnectDashboard  = connect (mapStateTodashboard_pageProps, mapDispatchTodashboard_Props)(Dashboard);




///////////////connecting QuestionSetup to store
const mapStateToQuestionSetup_pageProps = state => {
  return{            
             user_profile: state.user_profile,
             
         }
};

const mapDispatchToQuestionSetup_Props = dispatch => ({  
      save_user_profile_to_store : (data) => dispatch(save_user_profile_to_store(data)),
      change_should_show_question_setup_page : (data) => dispatch(change_should_show_question_setup_page(data)),
      save_database_questions_id_to_store : (data) => dispatch(save_database_questions_id_to_store(data)),
      save_un_done_questions_to_store : (data) => dispatch(save_un_done_questions_to_store(data)),
});
let ConnectQuestionSetup  = connect (mapStateToQuestionSetup_pageProps, mapDispatchToQuestionSetup_Props)(Question_setup);





///////////////connecting HeaderSection to store


const mapDispatchToHeaderSection_Props = dispatch => ({  
  
  save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
  save_performance_summary_to_store : (data) => dispatch(save_performance_summary_to_store(data)),  
  save_user_profile_to_store : (data) => dispatch(save_user_profile_to_store(data)),
});
let ConnectHeaderSection = connect (null, mapDispatchToHeaderSection_Props)(HeaderSection);







///////////////connecting Contact_us_page to store
const mapStateToContact_us_pageProps = state => {
  return{            
             user_account: state.user_account,
             
         }
};


let ConnectContact_us_page = connect (mapStateToContact_us_pageProps, null)(Contact_us_page);



///////////////connecting Profile_page to store
const mapStateToProfile_pageProps = state => {
  return{            
             user_account: state.user_account,
             user_profile: state.user_profile,
         }
};

const mapDispatchToProfile_page_Props = dispatch => ({  
  save_user_profile_to_store : (data) => dispatch(save_user_profile_to_store(data)),
});

let ConnectProfile_page = connect (mapStateToProfile_pageProps, mapDispatchToProfile_page_Props)(Profile_page);



///////////////connecting Learning_centre_page to store
const mapStateToLearning_centre_pageProps = state => {
  return{            
            un_done_questions: state.un_done_questions,
            done_questions_id: state.done_questions_id,
            user_account: state.user_account, 

         }
};

const mapDispatchToLearning_centre_pageProps = dispatch => ({  
        save_un_done_questions_to_store : (data) => dispatch(save_un_done_questions_to_store(data)),
        save_done_questions_id_to_store : (data) => dispatch( save_done_questions_id_to_store(data)),
        change_should_show_question_setup_page : (data) => dispatch( change_should_show_question_setup_page(data)),
});
let ConnectPastQuestionsCentre = connect (mapStateToLearning_centre_pageProps, mapDispatchToLearning_centre_pageProps)(PastQuestionsCentre);


///////////////connecting App to store
const mapStateToAppProps = state => {
  return{
            user_account: state.user_account, 
            user_profile: state.user_profile, 
            database_questions_id:state.database_questions_id,
            done_questions_id: state.done_questions_id,
            un_done_questions: state.un_done_questions,
            should_show_question_setup_page: state.should_show_question_setup_page,
            performance_summary : state.performance_summary,
            has_watched_tutorial_video:state.has_watched_tutorial_video,
            show_convert_account_page:state.show_convert_account_page,
         }
};

const mapDispatchToAppProps = dispatch => ({  
      save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
      save_user_profile_to_store : (data) => dispatch(save_user_profile_to_store(data)),
      save_database_questions_id_to_store : (data) => dispatch(save_database_questions_id_to_store(data)),
      save_un_done_questions_to_store : (data) => dispatch(save_un_done_questions_to_store(data)),
      change_should_show_question_setup_page : (data) => dispatch(change_should_show_question_setup_page(data)),
      save_performance_summary_to_store : (data) => dispatch(save_performance_summary_to_store(data)),
      change_has_watched_tutorial_video_status : (data) => dispatch(change_has_watched_tutorial_video_status(data)),
      set_convert_account_to_permanent_page_data : (data) => dispatch(set_convert_account_to_permanent_page_data(data)),
});


export default connect(mapStateToAppProps, mapDispatchToAppProps)(App);

