export const save_user_account_to_store = (data) => ({
    type: "save_user_account_to_store",
    payload:data
  });

  export const save_user_profile_to_store = (data) => ({
    type: "save_user_profile_to_store",
    payload:data
  });

  export const save_performance_summary_to_store = (data) => ({
    type: "save_performance_summary_to_store",
    payload:data
  });


  export const save_database_questions_id_to_store = (data) => ({
    type: "save_database_questions_id_to_store",
    payload:data
  });

  
  export const save_un_done_questions_to_store = (data) => ({
    type: "save_un_done_questions_to_store",
    payload:data
  });

  export const save_done_questions_id_to_store = (data) => ({
    type: "save_done_questions_id_to_store",
    payload:data
  });

  export const change_should_show_question_setup_page = (data) => ({
    type: "change_should_show_question_setup_page",
    payload:data
  });

  
  
  export const change_has_watched_tutorial_video_status = (data) => ({
    type: "change_has_watched_tutorial_video_status",
    payload:data
  });

  export const set_convert_account_to_permanent_page_data = (data) => ({
    type: "set_convert_account_to_permanent_page_data",
    payload:data
  });