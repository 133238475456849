import { createStore } from 'redux'
import rotateReducer from './Reducers';
import { composeWithDevTools } from 'redux-devtools-extension';








let state= {  
              user_account:null,
              user_profile:null,
              performance_summary:null,
              un_done_questions:[],
              done_questions_id:[], 
              should_show_question_setup_page:false,
              database_questions_id:[],
              has_watched_tutorial_video: false,
              show_convert_account_page : false,
                                      
            };



function configureStore() {
  return createStore(rotateReducer,
                       state, 
                       composeWithDevTools() );
}

export default configureStore;