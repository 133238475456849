import React from 'react'

const PdfJsViewer = ({ height, width, pdf_path, cordova }) => {
    let path = '/android_asset/www/pdfjs/web/viewer.html'
    if(!cordova){ path = 'pdfjs/web/viewer.html'}
    let url = path + '?file=' + pdf_path 
    
    return (
        <div style={{ width, height }}>
            {
                <iframe
                    title={pdf_path}
                    width='100%'
                    height='100%'
                    src={url}
                >
                </iframe>
            }
        </div>
    )
}
export default PdfJsViewer