

const school_district_list = ["Butterworth", 
"Cofimvaba", 
"Cradock", 
"Dutywa", 
"East London", 
"Fort Beaufort", 
"Graaff-Reinet", 
"Grahamstown ", 
"King Williams Town", 
"Lady Frere", 
"Libode ", 
"Lusikisiki", 
"Maluti", 
"Mbizana", 
"Mt Fletcher", 
"Mt Frere ", 
"Mthatha ", 
"Ngcobo ", 
"Port Elizabeth", 
"Queenstown", 
"Qumbu", 
"Sterkspruit", 
"Uitenhage", 
"Fezile Dabi", 
"Lejweleputswa", 
"Motheo", 
"Thabo Mofutsanyana", 
"Xhariep", 
"Ekurhuleni North", 
"Ekurhuleni South", 
"Gauteng East", 
"Gauteng North", 
"Gauteng West", 
"Johannesburg Central", 
"Johannesburg East", 
"Johannesburg North", 
"Johannesburg South", 
"Johannesburg West", 
"Sedibeng East", 
"Sedibeng West", 
"Tshwane North", 
"Tshwane South", 
"Tshwane West", 
"Amajuba", 
"Ilembe ", 
"Pinetown ", 
"Sisonke ", 
"Ugu ", 
"Umgungundlovu ", 
"Umkhanyakude ", 
"Umlazi ", 
"Umzinyathi ", 
"Uthukela ", 
"Uthungulu ", 
"Zululand ", 
"Lebowakgomo ", 
"Mogalakwena ", 
"Mopani ", 
"Polokwane ", 
"Riba Cross ", 
"Sekhukhune ", 
"Tshipise Sagole ", 
"Tzaneen ", 
"Vhembe ", 
"Waterberg ", 
"Bohlabela ", 
"Ehlanzeni ", 
"Gert Sibande ", 
"Nkangala ", 
"Frances Baard ", 
"John Taolo Gaetsewe ", 
"Namakwa ", 
"Pixley ka Seme ", 
"Siyanda ", 
"Bojanala ", 
"Dr Kenneth Kaunda ", 
"Dr Ruth Segomotsi Mompati ", 
"Ngaka Modiri Molema ", 
"Cape Winelands ", 
"Eden and Central Karoo ", 
"Metro Central ", 
"Metro East ", 
"Metro North ", 
"Metro South ", 
"Overberg ", 
"West Coast ",]


const school_list = ["Adelaide Gymnasium Technical-Academic School, Adelaide",
                            "Alexander Road High School, Port Elizabeth",
                            "Aliwal-Noord Hoërskool, Aliwal North",
                            "Alphedale High School",
                            "A.M.S. Sityana High School, King William's Town",
                            "Beaconhurst School, East London",
                            "Berlin High School, Berlin",
                            "Bishop DeMont High School, Aliwal North",
                            "Bizana Village Senior Secondary School, Bizana",
                            "Bubele High School, Lady Frere",
                            "Butterworth High School, Butterworth",
                            "Cambridge High School, East London",
                            "Cathcart High School, Cathcart",
                            "Clarendon High School for Girls, East London",
                            "Collegiate Girls' High School, Port Elizabeth",
                            "Cangci Comprehensive Technical high School, Bizana, Eastern Cape",
                            "Dale College, King William's Town",
                            "Daniel Pienaar THS, Uitenhage",
                            "De Vos Malan Hoërskool, King William's Town",
                            "Diocesan School for Girls, Grahamstown",
                            "East London High School",
                            "Elliot High School, Elliot",
                            "Ethembeni Enrichment Centre, Port Elizabeth",
                            "Ethembeni High School, King William's Town",
                            "Excelsior Senior Secondary School, Mthatha",
                            "Funda High School, Whittlesea",
                            "Fundani High School, Cathcart",
                            "GADRA Matric School, Grahamstown",
                            "Gill College, Somerset East",
                            "Gobe Commercial High School, Kentani",
                            "Gobinamba High School, Tsomo",
                            "Graeme College, Grahamstown",
                            "Greenpoint Secondary School",
                            "Grey High School, Port Elizabeth",
                            "Harvest Christian School, Port Elizabeth",
                            "Hector Petersen High School, Zwelitsha",
                            "Hlokoma High School, East London Mdantsane",
                            "St Martin's high School, Mthatha",
                            "Hoër Volkskool, Graaff-Reinet",
                            "Hoërskool Andrew Rabie, Port Elizabeth",
                            "Hoërskool Brandwag, Uitenhage",
                            "Hoërskool Burgersdorp, Burgersdorp",
                            "Hoërskool DF Malherbe, Port Elizabeth",
                            "Hoërskool Framesby, Port Elizabeth",
                            "Hoërskool Hangklip, Queenstown",
                            "Hoërskool Kirkwood, Kirkwood, Eastern Cape",
                            "Hoërskool Linkside High School, Port Elizabeth",
                            "Hoërskool Nico Malan, Humansdorp",
                            "Hoërskool P.J. Olivier, Grahamstown",
                            "Hoërskool Ugie, Ugie",
                            "Hudson Park High School, East London",
                            "Inyathi High school, Thornhill Enoch Mgijima",
                            "JS Skenjana High School, Dutywa",
                            "Khanya High School, Whittlesea",
                            "Khanyisa High School, Mthatha",
                            "Khulani Commercial High School, Mdantsane",
                            "Kingsridge Girls' High School, King William's Town",
                            "Kingswood College, Grahamstown",
                            "Knight Marhambana High School, Peddie",
                            "Kusile Comprehensive School",
                            "Kwa-Komani Comprehensive School, Komani",
                            "Lady Grey Arts Academy, Lady Grey",
                            "Lawson Brown High School",
                            "Lilyfontein School, East London",
                            "Little Flower High School, Qumbu",
                            "Luvuyo Lerumo High School, Queenstown",
                            "Lwandlekazi Senior Secondary, Port Elizabeth",
                            "Macibe High School, Butterworth",
                            "Masixole High School, East London , Mdantsane",
                            "Marlow Agricultural High School, Cradock",
                            "Mehlomakhulu High school, Herschel, Sterkspruit",
                            "Merrifield Preparatory School and College, East London",
                            "Mgezwa Senior Secondary School, Lusikisiki",
                            "Mhlotshana High School, Whittlesea",
                            "Muir College, Uitenhage",
                            "Newton Technical High School, Port Elizabeth",
                            "Ngcelwane High School, Mdantsane",
                            "Ngalonkulu Senior Primary School, Bizana, Eastern Cape",
                            "Nomaka Mbeki Technical Senior Secondary School, Dutywa",
                            "Nompumelelo High School, Whittlesea",
                            "Ntsonkotha Senior Secondary School, Lady Frere",
                            "Nyameko High School, East London Mdantsane",
                            "Pearson High, Port Elizabeth",
                            "Port Alfred High School, Port Alfred",
                            "Port Rex Technical High School , East London",
                            "Qaqamba Senior Secondary School",
                            "Queen's College, Queenstown",
                            "Queenstown Girls High School, Queenstown",
                            "Ranndogwana Secondary School, Halambani Mahagala",
                            "Riebeeck College, Uitenhage",
                            "RUCC Christian School, Mthatha",
                            "S.E.K Mqhayi High School, Mdantsane",
                            "Sanctor Senior Secondary School Port Elizabeth",
                            "Sandisiwe Senior Secondary School Mdantsane",
                            "Selborne College, East London",
                            "Sijongephambili High School Whittlesea",
                            "Sophakama High School Port Elizabeth",
                            "St. Thomas High School, Port Elizabeth",
                            "St. Andrew's College, Grahamstown",
                            "St. Matthew's High School, Keiskammahoek",
                            "Stirling High School, East London",
                            "Strelitzia High School, Uitenhage",
                            "Stutterheim High School, Stutterheim",
                            "Toise Senior Secondary School, King William's Town",
                            "Tyali Senior Secondary School, Feni Location, Centane, Transkei",
                            "Ugie High School, Ugie, Eastern Cape",
                            "Ulwazi High School, East London , Mdantsane",
                            "Umtata High School, Mthatha",
                            "Upper Corana senior secondary School, Libode",
                            "Union High School, Graaff-Reinet",
                            "Victoria Girls' High School, Grahamstown",
                            "Victoria Park High School, Port Elizabeth",
                            "Vulamazibuko High School, Mdantsane",
                            "Vuvumutshena Secondary School, Halambani Masetoni",
                            "West Bank High School, East London",
                            "Westering High School, Port Elizabeth",
                            "Westville Senior Secondary High School, Port Elizabeth",
                            "Willowvale Senior Secondary School, Willowvale",
                            "Winterberg Agricultural High School, Fort Beaufort",
                            "Woodridge College, Thornhill",
                            "Woolhope Secondary SchoolPort Elizabeth",
                            "Xilinxa Senior Secondary School, Nqamakwe",
                            "Zwelibongile Senior Secondary School, Holy Cross",
                            "Zamuxolo Junior Secondary School, Cofimvaba",
                            "Eunice Girls School, Bloemfontein",
                            "Grey College, Bloemfontein",
                            "Hoërskool Jim Fouché, Bloemfontein",
                            "Hoër Meisieskool Oranje, Bloemfontein",
                            "Bloemfontein High School, Bloemfontein",
                            "Brebner High School, Bloemfontein",
                            "Dr Viljoen Hoërskool, Bloemfontein",
                            "Navalsig High School, Bloemfontein",
                            "Hoërskool Sentraal, Bloemfontein",
                            "Kopanong Secondary School, Bloemfontein",
                            "Christian Brothers' College, St Joseph's, Bloemfontein",
                            "Hoërskool Fichardtpark, Bloemfontein",
                            "St. Andrew's School, Bloemfontein",
                            "St. Michael's School, Bloemfontein",
                            "Sand Du Plessis High School, Bloemfontein",
                            "Hoërskool Sentraal, Bloemfontein",
                            "Accelerated Christian College, Bloemfontein",
                            "Höerskool Louis Botha HTS, Bloemfontein",
                            "Dr Blok Secondary School, Bloemfontein",
                            "Hoërskool Ficksburg, Ficksburg, Ficksburg",
                            "King’s School, Ficksburg, Ficksburg",
                            "Hoërskool Harrismith High School, Harrismith",
                            "Hoërskool Paul Erasmus, Senekal",
                            "Hoërskool Staatspresident C.R. Swart, Brandfort",
                            "Ithabiseng FET school, Senekal",
                            "Hoër Tegniese Skool, Sasolburg",
                            "Marematlou Secondary School, Meloding, Virginia",
                            "Mampoi High School, Qwaqwa",
                            "Mohato Secondary School,",
                            "E.E Monese Comprehensive Secondary School, Senekal",
                            "Kheleng Secondary School, Hennenman",
                            "Bahale Secondary School, Hennenman",
                            "Afrikaans Hoërskool, Sasolburg",
                            "Phehello High School, Kutlwanong",
                            "Rekgotsofetse Secondary School, Paul Roux",
                            "Sasolburg High School, Sasolburg",
                            "Seemahale Secondary School, Botshabelo",
                            "Weiveld Agricultural and Hotel School, Parys",
                            "Bethlehem Voortrekker High School, Bethlehem",
                            "St Andrews, Welkom",
                            "St Dominic's College, Welkom",
                            "Welkom High School, Welkom",
                            "Welkom Gymnasium, Welkom",
                            "Welkom Hoërskool, Welkom",
                            "Welkom Islamic, Welkom",
                            "Welkom Secondary, Welkom",
                            "Welkom Technical High (T.H.S.), Welkom",
                            "Edmund Rice Combined, Welkom",
                            "Adamsonvlei Combined, Welkom",
                            "Eagles Christian Combined, Welkom",
                            "Dunamis Christian, Welkom",
                            "Viehoek Combined, Welkom",
                            "Riebeeckstad Hoërskool, Welkom",
                            "Hoërskool Goudveld, Welkom",
                            "Unitas Hoërskool, Welkom",
                            "Kingdom Academic, Welkom",
                            "Edu-College, Welkom",
                            "Heederpark High School, Welkom",
                            "Kananelo senior secondary school, Kroonstad",
                            "Bongani-Lebohang Secondary School, Cornelia",
                            "Curro Academy Savanna City , Mid Vaal (Savanna City)",
                            "Laer-skool Hannis Visagie, Nigel Nigel",
                            "Potso Mohajane High School, Nigel, Nigel",
                            "Alra-park Secondary School, Alra-park, Nigel",
                            "Nigel Secondary School, Alra-park, Nigel",
                            "Aha-Thuto Secondary School, Orange Farm",
                            "A.B Phokompe, Randfontein",
                            "African Leadership Academy, Honeydew",
                            "Afrikaanse Hoërskool Germiston, Germiston",
                            "Allen Glen High School, Allens Nek, Roodepoort",
                            "Alma Mater International School, Krugersdorp",
                            "Amazing K Special Needs / Autism School, Randburg",
                            "American International School of Johannesburg, Johannesburg",
                            "Athlone Boys' High School, Kensington, Johannesburg",
                            "Athlone High School for Girls, Observatory, Gauteng",
                            "Aurora Private School, Randburg",
                            "Barnato Park High School, Johannesburg",
                            "BASA, Johannesburg",
                            "Bastion Hoërskool, Roodepoort",
                            "Beaulieu College, Kyalami",
                            "Bedfordview High School Bedfordview",
                            "Bishops Bavin School, Bedfordview",
                            "Blue hills college (Midrand)",
                            "Boitumelong secondary school (Tembisa)",
                            "Benoni Educational College, Benoni",
                            "Bertharry Private School, Tembisa",
                            "Bracken High School, Alberton",
                            "British International College, Bryanston",
                            "Bryanston High School, Bryanston",
                            "Charter College International High School, Honeydew",
                            "Curro Aurora, Randburg]]",
                            "Curro helderywk]",
                            "Coronationville Secondary School,Coronationville",
                            "Charlotte Maxeke Secondary School, Tembisa",
                            "Crawford College, Sandton",
                            "Dainfern College, Fourways",
                            "Denver Secondary School, Johannesburg",
                            "Die Anker High School, Brakpan",
                            "Dinwiddie High School, Germiston",
                            "Dowerglen High School, Dowerglen",
                            "Eden College Lyndhurst, Johannesburg",
                            "Edenglen High School",
                            "Edenvale High School",
                            "Education Alive School, Johannesburg",
                            "Ferndale High School, Randburg",
                            "Florida Park High School, Roodepoort",
                            "Forest High School, Turffontein",
                            "Forest Town School, Forest Town",
                            "Fourways High School, Fourways",
                            "General Smuts High School, Vereeniging",
                            "George Khoza Secondary, Johannesburg",
                            "Germiston High School, Johannesburg",
                            "Glenbrack Secondary School, Alberton",
                            "Glenvista High School, Johannesburg",
                            "Grantley College, Parktown, Johannesburg",
                            "Greenside High School, Johannesburg",
                            "Highlands Boys' High School, Johannesburg",
                            "Hoërskool Alberton, Alberton",
                            "Hoerskool Die Adelaar, Roodepoort",
                            "Hoërskool Dinamika, Alberton",
                            "Hoërskool Dr. E.G. Jansen, Boksburg",
                            "Hoërskool Florida, Roodepoort",
                            "Hoërskool Marias Viljoen, Johannesburg",
                            "Hoërskool Monument, Krugersdorp",
                            "Hoërskool Noordheuwel, Krugersdorp",
                            "Hoërskool Birchleigh",
                            "Hoërskool Oosterlig",
                            "Hoërskool Randburg, Randburg",
                            "Holy Rosary School, Edenvale, near greenstone",
                            "Hope School, Westcliff",
                            "Horizon High School, Johannesburg South",
                            "HTS Nic Diederichs High Technical School",
                            "Hyde Park High School, Johannesburg",
                            "Immaculata High School, Soweto",
                            "Inkanyezi Waldorf School, Alexandra",
                            "Jeppe High School for Boys, Johannesburg",
                            "Jeppe High School for Girls, Johannesburg",
                            "Jeugland Hoërskool, Kempton Park",
                            "Johannesburg Polytech Institute, Johannesburg",
                            "Johannesburg Secondary School, Johannesburg",
                            "Jules High School, Jeppestown",
                            "Kenilworth High School, Johannesburg",
                            "Kensington Secondary School, Kensington",
                            "Kibler Park Secondary School, Kibler Park",
                            "King David Schools, Johannesburg",
                            "King Edward VII School, Johannesburg",
                            "Kingsmead College, Johannesburg",
                            "Krugersdorp High School, Krugersdorp",
                            "Kenneth Masikela Secondary, KwaThema",
                            "Lamula Jubilee Secondary School,Meadowlands,Soweto",
                            "Langlaagte Technical High School, Johannesburg",
                            "Lantern School, Krugersdorp",
                            "Lenasia High School, Lenasia",
                            "LSI College, Northcliff",
                            "Liverpool Secondary School, Benoni",
                            "Mapetla High School, Soweto",
                            "Mandisa Shiceka Secondary School Johannesburg, Krugersdorp",
                            "Meadowlands Secondary School, Soweto",
                            "Michael Mount Waldorf School, Bryanston",
                            "Midrand High School, Midrand",
                            "Mondeor High School, Johannesburg",
                            "Morris Isaacson High School, Soweto",
                            "Musenga Vhadzimu Combined School, Diepsloot",
                            "Mosupatsela Secondary School,Johannesburg Krugersdorp",
                            "Naledi High School, Soweto",
                            "National School of the Arts",
                            "New Nation School, Auckland Park",
                            "Nirvana Secondary School, Lenasia",
                            "Njabulo Nimrod Ndebele Secondary School, Nigel, Duduza",
                            "Norkem Park High School, Norkem Park",
                            "Northcliff High School, Johannesburg",
                            "Orlando West High School, Soweto",
                            "Parktown Boys' High School, Johannesburg",
                            "Parktown High School for Girls, Johannesburg",
                            "Phefeni Secondary School, Orlando-West, Soweto",
                            "Phahama Senior Secondary School, Randfontein",
                            "Phomolong Primary School, Tembisa",
                            "Providence Academy, Johannesburg",
                            "Queens High School, Johannesburg",
                            "Phoenix College,Johannesburg",
                            "Rand Girls High School, Parktown",
                            "Randfontein High School, Johannesburg",
                            "RandView College,Randfontein,Johannesburg",
                            "Randfontein Secondary School, Johannesburg",
                            "Randpark High School, Randburg",
                            "Reddam House, Bedfordview",
                            "Redhill School, Sandton",
                            "Riverlea Secondary School, Johannesburg",
                            "Roedean School, Johannesburg",
                            "Roosevelt High School Johannesburg",
                            "Sacred Heart College, Johannesburg",
                            "Sandown High School, Sandton",
                            "Sandringham High School, Johannesburg",
                            "Sheikh Anta Diop College, Yeoville",
                            "Sijabulile Secondary School, Johannesburg, Katlehong",
                            "Sir John Adamson Secondary School, Johannesburg",
                            "Sir Pierre Van Ryneveld High School, Kempton Park",
                            "Springs Boys' High School, Springs, Johannesburg",
                            "Springs Girls High School, Springs",
                            "St Benedict's College, Bedfordview, Johannesburg",
                            "St Catherine's Convent, Florida, Johannesburg",
                            "St David's Marist, Inanda, Sandton",
                            "St Mary's School, Waverley, Johannesburg",
                            "St Peter's College, Sandton",
                            "St Stithians College, Randburg",
                            "S.G Mafaesa Senior Secondary School,Johannesburg Krugersdorp",
                            "St. Andrew's School for Girls, Johannesburg",
                            "St. John's College, Johannesburg",
                            "St. Martin's School, Johannesburg",
                            "St. Theresa's Convent, Rosebank",
                            "Sunrise Combined College, Tembisa",
                            "Tershia King, Tembisa",
                            "Tsakane Ext 8 Secondary School, Tsakane",
                            "Tsakane Secondary School (Tisoso), Tsakane",
                            "Tlakula Secondary School, KwaThema",
                            "Abo SomaHhashi Comp. School, Tsakane",
                            "Thabo Senior Secondary School, Soweto",
                            "The Hill High School, Johannesburg",
                            "Thomas Mofolo High School, Soweto",
                            "Thulare High School, Soweto",
                            "Thuto Lehakwe, Randfontein",
                            "Thutolore Senior Secondary School, Soweto",
                            "Torah Academy School, Johannesburg",
                            "Trinity-House High School, Randpark Ridge",
                            "UniHigh West Rand, Krugersdorp",
                            "Unity Secondary School, Daveyton",
                            "University of Johannesburg Metropolitan Academy (UJMA), previously known as RAUCALL",
                            "Vector college, Johannesburg",
                            "Waterstone College, Kibler Park",
                            "Waverley Girls' High School, Johannesburg",
                            "Windmill park secondary school , windmill park,Johannesburg",
                            "Westridge High School, Roodepoort",
                            "Winnie Mandela Secondary School, Tembisa",
                            "Yeshiva College of South Africa, Johannesburg",
                            "Abbotts College, Pretoria",
                            "Afrikaanse Hoër Meisieskool, Pretoria",
                            "Afrikaanse Hoër Seunskool, Pretoria",
                            "Alatishe Secondary School, Pretoria",
                            "Bokgoni Technical Secondary School, Atteridgeville",
                            "British International College, Pretoria",
                            "Christian Brothers' College, Mount Edmund, Pretoria",
                            "Clapham High School, Pretoria",
                            "Cornerstone College",
                            "Cornwall Hill College",
                            "Crawford College, Pretoria, Pretoria",
                            "CVO Skool Pretoria, Pretoria",
                            "Curro Hazeldean ,Pretoria",
                            "David Hellen Peta High School, Atteridgeville",
                            "Dr WF Nkomo High School, Atteridgeville",
                            "Excelsior Akademie Rooihuiskraal, Centurion",
                            "Founders Community School, Pretoria",
                            "Flavius Mareka High School, Saulsville",
                            "Hatfield Christian School",
                            "Hillview High School, Pretoria",
                            "Hoërskool Die Wilgers, Pretoria",
                            "Hoerskool Elandspoort High School, Danville",
                            "Hoërskool Eldoraigne, Pretoria",
                            "Hoërskool Garsfontein",
                            "Hoërskool Gerrit Maritz, Pretoria",
                            "Hoërskool Hercules",
                            "Hoërskool Langenhoven",
                            "Hoërskool Menlopark, Pretoria",
                            "Hoërskool Montana, Pretoria",
                            "Hoërskool Oos-Moot, Pretoria",
                            "Hoërskool Overkruin",
                            "Die Hoërskool Pretoria-Noord, Pretoria",
                            "Hoërskool Raslouw",
                            "Hoërskool Staatspresident C.R. Swart, Waverley",
                            "Hoërskool Waterkloof, Pretoria",
                            "Hoërskool Wonderboom, Pretoria",
                            "Hofmeyr High School, Atteridgeville",
                            "Holy Trinity Christian School, Atteridgeville",
                            "Laudium Secondary School, Pretoria",
                            "Lyttelton Manor High School, Pretoria",
                            "Mabopane High School, Mabopane",
                            "Makgetsi High School, Hammanskraal",
                            "Max Stibbe Waldorf School, Pelotona Secondary School Mooiplaats",
                            "North Ridge School, Pretoria North, Pretoria",
                            "Phatudi Secondary School, Saulsville",
                            "Phelindaba High School, Atteridgeville",
                            "Pretoria Boys High School",
                            "Pretoria High School for Girls",
                            "Pretoria Secondary School",
                            "Pretoria Technical High School",
                            "Pro Arte Alphen Park",
                            "Ratshepo High School",
                            "Saulridge High School, Atteridgeville",
                            "Sikhululekile High School, Hammanskraal",
                            "Soshanguve High School, Soshanguve, Pretoria",
                            "Southdowns College,Irene",
                            "St. Alban's College, Pretoria",
                            "St. Mary's Diocesan School for Girls, Pretoria",
                            "Star College Pretoria",
                            "Sutherland High School, Centurion",
                            "The Glen High School, Pretoria",
                            "Tshwane Muslim School",
                            "Village Waldorf School, Irene",
                            "Willowridge High School, Pretoria",
                            "I.R Lesolang High School,Winterveldt",
                            "Institution of learning skool",
                            "Vereeniging and Vanderbijlpark[edit]",
                            "Hoërskool Driehoek, Vanderbijlpark",
                            "General Smuts High School, Vereeniging",
                            "Overvaal Hoërskool, Vereeniging",
                            "Riverside High, Vereeniging",
                            "Three Rivers Christian Academy, Vereeniging",
                            "Three Rivers High, Vereeniging",
                            "Vereeniging Gimnasium, Vereeniging",
                            "Word of Life School, Vereeniging",
                            "Hoër Tegniesie Skool Carel de Wet Technical High School, Vanderbijlpark",
                            "Sosol High Vanderbijlpark",
                            "Afrikaans Hoërskool Germiston, Germiston",
                            "Alberton High School, Alberton",
                            "Ashbury College, Benoni",
                            "Benoni High School., Benoni",
                            "Boksburg High School, Boksburg",
                            "Christian Brothers' College, Boksburg",
                            "Eden High School, Thokoza",
                            "Eden Park Secondary High School, Alberton",
                            "Edenvale High School, Edenvale",
                            "Eketsang High School, Katlehong",
                            "Enderun College, Springs",
                            "Eureka High School, Springs",
                            "Germiston High School, Germiston",
                            "Hoërskool Dr. E.G.Jansen, Boksburg",
                            "Hoërskool Erasmus, Bronkhorstspruit",
                            "Hoërskool Voortrekker, Boksburg",
                            "Jet Nteo Secondary School, Boipatong",
                            "Jeugland Hoërskool, Kempton Park",
                            "Katlehong High School, Katlehong",
                            "Thutopele high school, Katlehong",
                            "Sijabulile Secondary school, Katlehong",
                            "Kwadukathole secondary school, katlehong",
                            "Mpontseng secondary school, Katlehong",
                            "Lebohang Secondary School, Boipatong",
                            "Lehlabile Senior Secondary, Mamelodi",
                            "Mahareng Secondary School, Boipatong",
                            "Mamelodi High School, Mamelodi",
                            "Midstream College, Midrand",
                            "Nellmapius High School, Mamelodi",
                            "Ponego Comprehensive school, Katlehong",
                            "Springs Tegniese Hoërskool, Springs",
                            "St Catherine's School, Germiston",
                            "St. Dominic's Catholic School for Girls, Boksburg",
                            "Suncrest High School, Vanderbijlpark",
                            "Tembisa High School, Tembisa",
                            "Willowmore High School, Benoni",
                            "Zitikeni Secondary School, Tembisa",
                            "Tembisa west Masebenze",
                            "Albini Girls' High School Pinetown",
                            "Al-Falaah College, Durban",
                            "Amanzimtoti High School, Durban",
                            "Avoca Secondary School, Durban",
                            "Bechet High School",
                            "Brettonwood High School, Durban",
                            "Bergville High School",
                            "Centenary Secondary School, Durban",
                            "Clifton School, Durban",
                            "Clairwood Secondary School",
                            "Curro Creston College",
                            "Danville Park Girls' High School, Durban",
                            "Dr AD Lazarus Secondary School",
                            "Durban Academy, Durban",
                            "Durban Girls' College, Berea",
                            "Durban Girls' High School, Glenwood",
                            "Durban Girls' Secondary School, Durban",
                            "Durban High School, Berea",
                            "Durban North College, Durban North",
                            "Durban Roseway Waldorf School",
                            "Effingham Secondary School, Durban",
                            "Embury College",
                            "Eric Mtshali High School, Kloof",
                            "Folweni High School, Durban",
                            "Ganges Secondary School, Merebank",
                            "Gelofte Skool, Durban",
                            "George Campbell School of Technology, Durban",
                            "Glenwood High School (Durban)",
                            "Greenbury Secondary School, Durban",
                            "Grovesnor Boys High School, Bluff",
                            "Grovesnor Girls High School, Bluff",
                            "Hillcrest High School",
                            "Hillgrove High School, Newlands West",
                            "Igagasi High School, Umlazi, Durban",
                            "Igugulabasha High School, Ntuzuma, Durban",
                            "Isipingo Secondary, Isipingo Hills",
                            "Kearsney College, Botha's Hill",
                            "Inanda Seminary School, Inanda",
                            "JG Zuma High School, KwaMashu",
                            "Khabazela High School, Hillcrest, Durban",
                            "Khayelihle High School, Umbumbulu",
                            "King Shaka High School, Umlazi",
                            "Kingsway High School, Durban",
                            "Kloof High School, Kloof",
                            "Kuswag Skool, Amanzimtoti",
                            "Ladysmith High School, Ladysmith",
                            "Lamontville High School, Lamontville, Durban",
                            "Maris Stella School, Durban",
                            "Mowat Park High School, Montclair",
                            "New Forest High School, Durban",
                            "Newlands East Secondary School, Durban",
                            "Nkosibomvu Secondary School, Tongaat, KwaZulu Natal",
                            "Northbury Secondary school, Pietermaritzburg",
                            "Northlands Girls' High School, Durban",
                            "Northwood School, Durban",
                            "Ntwenhle High School, Durban",
                            "Orient Islamic School, Durban",
                            "Our Lady of Fatima Dominican Convent School, Durban",
                            "Pinetown Boys' High School, Pinetown",
                            "Pinetown Girls' High School, Pinetown",
                            "Port Natal High School, Durban",
                            "Umkomaas Secondary School, Durban",
                            "Queensburgh Boys' High School, Durban",
                            "Queensburgh Girls' High School, Durban",
                            "Reservoir Hills Secondary School, Durban",
                            "Ridge Park College, Durban",
                            "Risecliff Secondary School, Durban",
                            "Riverdene High School, Newlands West",
                            "Robert Hlongwa High School, Amanzimtoti",
                            "Rossburgh High School, Durban",
                            "Reunion Secondary School, Malaba Hills",
                            "Sastri College, Durban",
                            "Scottburgh High School, Durban",
                            "Sidelile High School, Umkomaas",
                            "Siphephele High School, Durban",
                            "St Benedict School Pinetown",
                            "St. Henry's Marist Brothers' College, Durban",
                            "St. Mary's Diocesan School for Girls, Kloof",
                            "St. Patrick's College, Kokstad",
                            "Star College, Westville",
                            "Strelitzia Secondary School, Lotus Park",
                            "Thomas More College, Kloof",
                            "Umbilo Secondary School, Wentworth",
                            "Umkhumbi High School, KwaMakhutha",
                            "Umlazi Commercial High School, Durban",
                            "Umzinto Secondary School, Durban",
                            "Velabahleke High School, Umlazi, Durban",
                            "Verulam High School, Verulam",
                            "Waterfall College Independent, Co-educational Senior School, Hillcrest",
                            "Wentworth Secondary School, Wentworth",
                            "Westridge Boys High School, Durban",
                            "Westville Boys' High School, Westville",
                            "Westville Girls' High School, Westville",
                            "Wiggins Secondary School, Umlazi",
                            "Wingen High Secondary School, Durban",
                            "Zwelihle High School, Umlazi",
                            "Mangquzuka High, Oshabeni",
                            "Pietermaritzburg and surrounding areas[edit]",
                            "Alexandra High School, Pietermaritzburg",
                            "Amakholwa High School, Sinathingi",
                            "Amaphuphesizwe High School, Maphumulo",
                            "Bhekuximba High School, Edendale",
                            "Carter High School, Pietermaritzburg",
                            "Chistlehurst Academy and Arts School, Pietermaritzburg",
                            "Christian Life Academy, Pietermaritzburg",
                            "Copesville Secondary School, Pietermaritzburg",
                            "Deutsche Schule, Hermannsburg",
                            "Eastwood High School, Pietermaritzburg",
                            "Edendale Technical High School",
                            "Epworth High School, Pietermaritzburg",
                            "Esther Payne-Smith High School, Pietermaritzburg",
                            "Georgetown High School, Pietermaritzburg",
                            "Grace College, Hilton, Pietermaritzburg",
                            "Haythorne High School, Pietermaritzburg",
                            "Heather Secondary School, Pietermaritzburg",
                            "Heritage Academy (Pietermaritzburg), Pietermaritzburg",
                            "Hilton College, Hilton",
                            "Howick High School, Howick",
                            "Howick Secondary School",
                            "Injoloba Secondary School, Howick",
                            "Islamia Muslim School, Pietermaritzburg",
                            "Kharina Secondary School, Pietermaritzburg",
                            "King Edward High School, Matatiele",
                            "Kokstad College, Kokstad",
                            "Langsyde Combined School, Elandskop",
                            "Linpark High School, Pietermaritzburg",
                            "Maritzburg Christian School, Pietermaritzburg",
                            "Maritzburg College, Pietermaritzburg",
                            "Maritzburg Muslim School for Girls, Pietermaritzburg",
                            "Michaelhouse, Balgowan",
                            "Marion High School, Pietermaritzburg",
                            "M.L. Sultan Secondary School, Pietermaritzburg",
                            "Mpophomeni Secondary School, Mpophomeni, Howick",
                            "Nomaswazi High School, Pietermaritzburg",
                            "Northbury Secondary School, Pietermaritzburg",
                            "Nyonithwele Secondary School, Pietermaritzburg",
                            "Pholela High School, Bulwer",
                            "Pietermaritzburg Girls' High School, Pietermaritzburg",
                            "Raisethorpe Secondary School, Pietermaritzburg",
                            "Richmond Combined School, Richmond",
                            "Russell High School, Pietermaritzburg",
                            "Silver Heights Secondary School, Pietermaritzburg",
                            "Siyahlomula High School, Pietermaritzburg",
                            "Siyanda Secondary School, Sweetwater, Pietermaritzburg",
                            "Smero Secondary School, Pietermaritzburg",
                            "St. Anne's Diocesan College, Hilton",
                            "St. Charles College, Pietermaritzburg",
                            "St. John's Diocesan School for Girls, Pietermaritzburg",
                            "St. John Paul II Secondary School, Elandskop, Pietermaritzburg",
                            "Sukuma Comprehensive School, Pietermaritzburg",
                            "The Wykeham Collegiate, Pietermaritzburg",
                            "Thornhill Christian College, Pietermaritzburg",
                            "Umthoqotho High School, Pietermaritzburg",
                            "Voortrekker High School, Pietermaritzburg",
                            "Woodlands Secondary School, Pietermaritzburg",
                            "Midlands[edit]",
                            "Amazulu High School, Newcastle",
                            "Drakensberg Boys' Choir School, Winterton",
                            "Dundee High School, Dundee",
                            "Estcourt High School, Estcourt",
                            "Estcourt Senior Secondary School, Estcourt",
                            "Ferrum High School, Newcastle",
                            "Greytown High School, Greytown",
                            "Gudu Secondary School, Vryheid",
                            "Hoërskool Sarel Cilliers, Glencoe",
                            "Inkamana High School, Vryheid",
                            "KwaMvimbela High School, Underberg",
                            "Ladysmith High School",
                            "Mathunjwa High School, Vryheid",
                            "Michaelhouse, Balgowan",
                            "Newcastle High School, Newcastle",
                            "Ncinjane Secondary School, Ladysmith",
                            "Sekethwayo High School, EMondlo, KwaZulu-Natal",
                            "Sihlengeni Combined Secondary School, Vryheid",
                            "Treverton College, Mooi River",
                            "Vryheid Comprehensive Secondary School, Vryheid",
                            "Wartburg Kirchdorf School, Wartburg",
                            "Weston Agricultural College,(Best Agricultural High School in the province) Mooi River",
                            "Ukhali High School, Makhwelela region",
                            "North Coast and Zululand[edit]",
                            "Arboretum Primary/Premere School/Skool, Richards Bay",
                            "Ashton International College, Ballito",
                            "Emkhayideni Technical High School",
                            "Empangeni High School, Empangeni",
                            "Emzingwenya Primary School, Esikhawini",
                            "Hambisanani High School, Manguzi",
                            "Iniwe Secondary School, Empangeni",
                            "John Ross College, Richards Bay",
                            "King Senzangakhona High School, Ulundi",
                            "Mtunzini Primary School, Mtunzini",
                            "Ohlange High School",
                            "Sibhamu High School, Emanyiseni",
                            "Stanger Manor Secondary School, Stanger",
                            "Stanger Secondary School, Stanger",
                            "Star of the Sea High School (South Africa), Manguzi",
                            "Sunnydale High School, Eshowe",
                            "Eshowe High School, Eshowe",
                            "Uyengo High School, Empangeni",
                            "Ngqokwane High School, kwaMaphumulo",
                            "Isibanisezwe High School, kwaMaphumulo",
                            "uKukhanya Kwezwe High School, kwaMaphumulo",
                            "Vukile High school, kwaMaphumulo",
                            "Nkosana High School, Matubatuba",
                            "Nkodibe High School, Matubatuba",
                            "Nhliziyo High School, Matubatuba",
                            "Madwaleni High School, Matubatuba",
                            "Bonga Secondary School, Hluhluwe",
                            "Ezifundeni High School, Hluhluwe",
                            "Siphosabadletshe Secondary School,Hluhluwe",
                            "North coast agricultural college(shakaskraal secondary school),shakaskraal",
                            "Mgezeni Technical High School , eNtambanana",
                            "Mningi High School, eNtambanana",
                            "Ikhandlela Secondary School eSikhawini",
                            "Mpephise High School, eFuyeni",
                            "Hibiscus Coast[edit]",
                            "Hoërskool Suid Natal, Port Shepstone",
                            "Mbonwa High School, Harding",
                            "Mgudlwa High School, Port Shepstone",
                            "Port Shepstone High School, Port Shepstone",
                            "Port Shepstone Secondary School",
                            "Siyaphambili High School, Harding",
                            "Zuzicebo High School, Port Shepstone",
                            "Reunion Secondary School, Durban",
                            "Ginyiqhinga Comprehensive High School, Harding",
                            "Southcity College, Ramsgate",
                            "Waterberg Academy, Vaalwater",
                            "Baranuka High School, Phalaborwa",
                            "Bopedi Bapedi High School, Ga-Marishane",
                            "Capricon High School, Polokwane",
                            "Dikobe Secondary School, Ga-Mphahlele",
                            "Glen Cowie Secondary School, Glen Cowie",
                            "Christ The King, Mankweng",
                            "D.G. Tsebe High School, Mahwelereng",
                            "Ditlalemeso secondary School, Mankweng",
                            "Dr A.M.S. Makunyane High Hchool, Polokwane, Seshego Zone 5",
                            "Dzata Secondary School, Dzanani",
                            "Ebenezer High School, Mahwelereng",
                            "Fetakgomo Secondary School, Mohlaletse",
                            "Florapark Comprehensive High School, Polokwane",
                            "Frank Mashile Secondary School, Ga Nkwana Mashung",
                            "Giyani High School, Giyani Section A",
                            "Gojela High School, Mahwelereng",
                            "Gwamasenga High School",
                            "Hanyani Thomo High School, Giyani, Thomo",
                            "Hatlani Muyexe High School, Giyani, Muyexe",
                            "hendrick Ntambeleni secondary School Maangani",
                            "Hoërskool Pietersburg, Polokwane",
                            "Hoërskool Noorderland, Polokwane",
                            "Hwiti High School, Mankweng",
                            "Jane Furse Comprehensive School",
                            "Joel Sibasa Secondary School, Ga-Mashashane",
                            "Manyaku Secondary School, Mmotwaneng",
                            "Jonathan Mushathama Secondary School, Tshirwole",
                            "Karabi Secondary School, Ga-Phago",
                            "Kgomontswere Primary School, Boratapelo Ga Matlala",
                            "Khaiso High School, Seshego Zone 3",
                            "kheto Agricultural high school, Giyani",
                            "Komane High School, Ga-Nchabeleng",
                            "KwaPhayikeni High School",
                            "Lebowakgomo High School, Lebowakgomo",
                            "Lengama Secondary School, Mokwete",
                            "Leolo High School, Burgersfort",
                            "Lesedi Waldorf Centre, Bakone",
                            "Litshovhu senior secondary School, Madombidzha",
                            "Luphai Secondary School, Limpopo",
                            "Lwandani Secondary School, Dzindi Circuit",
                            "[Maditsi High School, Schuinsrand Ga-Ledwaba]",
                            "Mahwetse Secondary School, Ga Masemola",
                            "Makgenene High School, Mapela-Phafola",
                            "Makgofe High School,Mmotong, Makgofe",
                            "Makgoka High School, Boyne",
                            "Makgongoana High School, Ga-Makanye",
                            "makgwahleng secondary School, Ga Skororo",
                            "Malabe Secondary School, Maololo",
                            "Malabe Secondary School, Maololo",
                            "Mamabudusha Secondary School, Mamotintane",
                            "Mamafa secondary School, Ga Phasha",
                            "Mamolemane Secondary School, Moletjie Ga Matamnyane",
                            "Mamone Secondary School, Boratapelo Ga Matlala",
                            "Mantsubele High School, Ga Skororo",
                            "Maroba High School, Ga Rakgoatha",
                            "Marobathota High School, Boyne",
                            "Masobe High School, Ga-Mothapo Tholongwe",
                            "Masemola Senior Secondary School, Ga Masemola",
                            "Masereni secondary School, khomela(Garside)",
                            "Maserunyane Secondary, Mapela-Mesopotamia",
                            "Matlebjoane Senior Secondary School, Ga Masemola",
                            "Mbilwi Secondary School",
                            "Miriyavhavha Technical Secondary School, Ha Khakhu, Thondoni",
                            "Mmantutule High, Mapela",
                            "Mmatedu Secondary School, Ga-Mabuela",
                            "Mokgorotlwane Secondary School, Ga-Mphahlele",
                            "Mokhulwane Secondary School, Ga-nchabeleng",
                            "Mokutu Secondary School, Ga-Nakampe",
                            "Moleshatlou Secondary School, Ga-Maila Mapitsana",
                            "Molomoatau Secondary School, Ga Masemola",
                            "Monyaku secondary School, Ga-Masemola",
                            "Motlalaohle High School, Botlokwa",
                            "Motubatse Secondary School, Ga Masemola",
                            "Mphaga secondary School, Tafelkop",
                            "mphaphuli secondary School, Makwarela",
                            "Mphephu High School, Dzanani",
                            "Mphezulu High School, Monsterlus",
                            "Mpilo Secondary School, Marulaneng",
                            "Mulenga Secondary School, Ha-Matsa",
                            "Mushaathoni High School, Nzhelele",
                            "[Ndlovu Primary School, Schiunsrand Ga-ledwaba]",
                            "Nakonkwetlou Secondary, Steiloop-Watervaal",
                            "Ngakana High School, Ga Matlala Madietane",
                            "Ngwanalaka High School, Nobody Ga-Mothiba Thakgalang",
                            "Ngwanamala secondary School, Ga-Nchabeleng",
                            "Ngwana Mohube Secondary School, Ga-Mphahlele",
                            "Nhluvuko High School, Shimange village",
                            "Nkgonyeletse Secondary School, Ga-Mashabela",
                            "Nngweni High School, Dzanani",
                            "Northern Academy Secondary School, Polokwane",
                            "Ntata Secondary School, Ga Madiba",
                            "Ntlakane High School, Mapela",
                            "Nyumbani High School, Ndhambi Village",
                            "O. R. Mabotja Secondary School, Mmotong,Makgofe",
                            "PaePae Senior Secondary School, Mabotsha",
                            "Phiri Kolobe High, Mankweng",
                            "Phorwane Secondary School, Ga Masemola",
                            "Photani High School, Mulamula village",
                            "Phutakwe Secondary School, Mohlaletse Ga-Matsimela",
                            "Puputle Secondary School, Mabitsi",
                            "Mapokwane High School, Mashishimale Phalaborwa",
                            "Lebeko High School, Mashishimale Phalabrwa",
                            "Radira High School, Senwabarwana",
                            "Ramabulana secondary School",
                            "Rammila secondary School, Ga-sedibeng",
                            "Ramphelane Secondary School, Manganeng",
                            "Rantobeng senior secondary School, Diphagane",
                            "Seemole Maraba Secondary School, Ga-Mashashane",
                            "Settlers Agricultural High School, Warmbaths",
                            "Sibhamu High School, Ingwavuma, Emanyiseni",
                            "Harry Oppenheimer Agricultural High School, Limburg",
                            "S J Van Der Merwe School, Zone F Lebowakgomo",
                            "St Mark's College, Jane Furse",
                            "Stanford Lake College, Haenertsburg",
                            "Southern Cross Schools, Hoedspruit",
                            "Thagaetala High School",
                            "Thengwe High School, Mutale, Tshilamba",
                            "Thohoyandou High School, Thohoyandou, P-West",
                            "Thohoyandou Technical High, Thohoyandou, Block-F",
                            "Thubisane High School, Ga Skororo",
                            "Tlakale Ledwaba Secondary School, Ga-Mashashane",
                            "Tseana High School, Ga-mamaila",
                            "Tshabelang Dinoko High School, Leboeng",
                            "Vhulaudzi Secondary School, Vhulaudzi",
                            "Waterval High School",
                            "Weenen Combined School",
                            "Witwatersrand High School, Thohoyandou Block Z",
                            "Chechema High School, Marowe",
                            "Modumo Secondary School, Ga-Maribana",
                            "Radipitsi Secondary School, Ga-Phaudi(Aviot)",
                            "Haggai independent school, maniini",
                            "Thivhileli Secondary School, maiini",
                            "Thohoyandou Secondary School, Thohoyandou block P West",
                            "Liivha Combined School, Thohoyandou Block F",
                            "Makgato Secondary School, Botlokwa Letsatsana",
                            "Mayisha Secondary School",
                            "Kgagatlou Secondary School,GaMphahlele",
                            "Kopano Senior Secondary School,Lebowakgomo zone S",
                            "Mathomomayo High School,Lebowakgomo zone A",
                            "Rusplas Christian Model School,Lebowakgomo",
                            "Derek Kobe Secondary School,Lebowakgomo zone B",
                            "Hoërskool Ben Viljoen,Groblersdal",
                            "Mmaphuti Secondary School, Kalkspruit, Ga-Maraba",
                            "Hoerskool Louis Trichardt Kgabo Senior Secondary School, Mmotong",
                            "Ga-Mokgokong Mohlapetse High School,Mmotong",
                            "Ga-Mabotja Monyong High School",
                            "Belezela Primary School, [(KwaNdebele]) KameelRivier A",
                            "Cyril Clarke High School, Nelspruit, Mpumalanga",
                            "A D Nkosi Secondary School, Bethal",
                            "Allendale High School, eMalahleni",
                            "Amadlelo Aluhlaza Secondary school, (Piet Retief)",
                            "Andries Mugaguli Secondary School, Mayflower",
                            "Barberton High School, Barberton",
                            "Bushbuckridge Secondary School, Bushbuckridge",
                            "Cambridge High School (Witbank)",
                            "Cebisa Secondary School, Ermelo",
                            "Chief Jerry Nkosi Secondary School (Mooiplaas)",
                            "Curro Nelspruit (Nelspruit)",
                            "EastHigh College of Excellence, Nelspruit",
                            "Hierskool Hertzog (Witbank)",
                            "Highveld Park High School, Secunda",
                            "Hoërskool Bergvlam, Nelspruit",
                            "Hoërskool Evander, Evandee",
                            "Hoërskool Hoogenhout, Bethal",
                            "Hoërskool Nelspruit, Nelspruit",
                            "Hoërskool Ogies, Ogies",
                            "Hoërskool Oosterland, Secunda",
                            "Hoërskool Rob Ferreira High School, White River, Mpumalanga",
                            "Hoërskool Secunda, Secunda",
                            "Intuthuko Secondary School, [(KwaNdebele]) Maphotla",
                            "Ithafa Secondary School, Ermelo",
                            "Jandrell Secondary School, Standerton",
                            "Joseph Matsebula secondary School, Driekoppies",
                            "Khamane High School, Marapyane",
                            "Khulasakhile Secondary School, Standerton",
                            "Khunjuliwe High School, Standerton",
                            "Khutsalani Senior Secondary School, Nelspruit",
                            "Kriel High School, Kriel",
                            "Kumana Trust, Thulamahashe",
                            "Langa High School",
                            "Lindile Secondary School, Ermelo",
                            "Lowveld High, Nelspruit",
                            "Mahushe Agricultural High School, Nkomazi, Mpumalanga",
                            "Masitakhe Secondary School, Nelspruit (Matsulu)",
                            "Mayisha Secondary School",
                            "Mkolishi Secondary School, Tjakastad",
                            "Mmametlhake High, Mmametlhake",
                            "Moepi High School, Marapyane",
                            "Mthombo Secondary School, Nelspruit",
                            "Mzila Secondary, Agincourt",
                            "Nalithuba Secondary School, Daggakraal",
                            "Ngodini High School, Nelspruit",
                            "Penryn College, Nelspruit",
                            "Pungutsha High, Katjibane",
                            "Ramoshidi High, Seabe",
                            "Rethabile Primary School, [(KwaNdebele]) KameelRivier A",
                            "Reggie Masuku Secondary School, Ermelo",
                            "Sabane Secondary School, Nelspruit",
                            "Seabe High, Seabe",
                            "Seme Secondary School, Daggakraal",
                            "Sibusisiwe High School, Nelspruit (Matsulu)",
                            "Sjabule Primary School, [(KwaNdebele]) Maphotla",
                            "Sthembiso Primary School, [(KwaNdebele]) Maphotla",
                            "Sivumelene Secondary School, [(KwaNdebele)] KameelRivier A",
                            "Silindile Senior SecondarynSchool, [(Kwandebele)] Maphotla",
                            "Sitfokotile Secondary School, Nelspruit (Matsulu)",
                            "Sithole Walter Jobela, Agincourt",
                            "Standerton Hoërskool, Standerton",
                            "Thembeka Senior Secondary School, Nelspruit (KaNyamazane)",
                            "Thobelani Secondary School, Standerton",
                            "Thufane High, Nokaneng",
                            "Thuto Thebe Secondary School, Standerton",
                            "Tikhuni Senior Secondary School",
                            "Uplands College, [White River, Mpumalanga]",
                            "Adamantia Hoërskool",
                            "Diamantveld High School",
                            "Dr E.P Lekhela Senior Secondary",
                            "Floors High School",
                            "Homevale High School",
                            "HTS Kimberley",
                            "Kimberley Boys' High School",
                            "Kimberley Girls' High School",
                            "Noord-Kaap Hoërskool",
                            "Sol Plaatjies High School",
                            "St. Patrick's CBC",
                            "Tetlanyo High School",
                            "Vuyolwethu Secondary Schools",
                            "St. Boniface High School (C.B.C)",
                            "Hoërskool Hopetown, Hopetown",
                            "Loeriesfontein High School",
                            "N'Wanati High School",
                            "Veritas Senior Secondary School, De Aar",
                            "Okiep High, Okiep",
                            "Namakwaland High School, Springbok",
                            "Hoërskool Duineveld, Upington",
                            "Hoërskool Wolmaransstad, Wolmaransstad",
                            "Alabama Secondary School, Klerksdorp",
                            "Alfred Maubane High School, Temba",
                            "Bafokeng High School, Phokeng",
                            "Bakwena High School, Brits",
                            "Barolong Secondary School, Mafikeng",
                            "Batswana Commercial School, Mmabatho",
                            "Bore High School, Ganyesa",
                            "Brits Hoërskool, Brits",
                            "Eletsa Secondary School, Letlhabile",
                            "Ferdinand Postma High, Baillie Park, Potchefstroom",
                            "Fields College, Rustenburg",
                            "Herman Thebe High School, Mmatau",
                            "Hoërskool Schweizer Reneke, Schweizer-Reneke",
                            "Hoërskool Wesvalia, Klerksdorp",
                            "Holy Family Combined School, Mogwase",
                            "Ikatisong Secondary School, Letlhabile",
                            "Ipelegeng High School, Schweizer-Reneke",
                            "J M Ntsime High School, Rustenburg",
                            "Keipatile Primary School, Morokweng",
                            "Kgamanyane High, Moruleng",
                            "Klersdorp Hoërskool, Klerksdorp",
                            "Langa la Sembo High School, Runstenburg",
                            "Letsatsing High School, Mmabatho",
                            "Lichtenburg High School, Lichtenburg",
                            "Longaneng Primary School, Morokweng",
                            "Mafikeng High School, Mafikeng",
                            "Marubising Secondary School, Taung",
                            "Milner High School, Klerksdorp",
                            "Mmokeng Secondary School, Kanana",
                            "Monchusi Secondary School, Morokweng",
                            "Motswedi High school, Motswedi",
                            "Moreri Secondary School, Tseoge",
                            "Morokweng Primary School, Morokweng",
                            "Ntebogang Secondary School, Dinokana, Zeerust",
                            "Ogodiseng Middle School, Morokweng",
                            "Potchefstroom Gimnasium, Potchefstroom",
                            "Potchefstroom High School for Boys, Potchefstroom",
                            "President Mangope Technical and Commercial High, Tlhabane",
                            "Promosa Secondary School, Potchefstroom",
                            "Reivilo High School, Reivilo",
                            "Rethusegile High School, Lethabong",
                            "Die Hoërskool Rustenburg, Rustenburg",
                            "Sekete IV High School, Kanana",
                            "Selly Park Secondary School, Rustenburg",
                            "Setswakgosing High School, Morokweng",
                            "Sol Plaatje Secondary School, Mmabatho",
                            "St Anne's High School (Girls), Modimong",
                            "Thapama Secondary School, Taung",
                            "Tiger Kloof Combined School, Tiger Kloof",
                            "Tshepagalang Secondary School, Letlhabile",
                            "Tsogo High School, Mmakau",
                            "Vryburg High School, Vryburg",
                            "Wedela Technical High School, Wedela",
                            "Madiba a Toloane Secondary School, Jericho, North West",
                            "Regorogile Combined School, Ventersdorp",
                            "Zinniaville secondary School, Rustenburg",
                            "Grenswag High School, Rustenburg",
                            "Central Secondary school, Brits",
                            "Lighthouse christian college, Brits",
                            "Rustenburg Educational college, Olifantsnek, Rustenburg",
                            "Rabboni Christian School, Brits",
                            "Lebone College II, Phokeng",
                            "Eben Dönges High School, Kraaifontein",
                            "Buren High School, Brooklyn",]





const city_list = [
  
                     [         
                                    "Alice",
                                    "Butterworth",
                                    "East London",
                                    "Graaff-Reinet",
                                    "Grahamstown",
                                    "King William’s Town",
                                    "Mthatha",
                                    "Port Elizabeth",
                                    "Queenstown",
                                    "Uitenhage",
                                    "Zwelitsha",
                            ],
                       
                 
                    [ 
                                    "Bethlehem",
                                    "Bloemfontein",
                                    "Jagersfontein",
                                    "Kroonstad",
                                    "Odendaalsrus",
                                    "Parys",
                                    "Phuthaditjhaba",
                                    "Sasolburg",
                                    "Virginia",
                                    "Welkom",
                            ],
                     
                  
                     [ 
                                "Benoni",
                                "Boksburg",
                                "Brakpan",
                                "Carletonville",
                                "Germiston",
                                "Johannesburg",
                                "Krugersdorp",
                                "Pretoria",
                                "Randburg",
                                "Randfontein",
                                "Roodepoort",
                                "Soweto",
                                "Springs",
                                "Vanderbijlpark",
                                "Vereeniging",
                            ],
                                              
                   
                    [
                                "Durban",
                                "Empangeni",
                                "Ladysmith",
                                "Newcastle",
                                "Pietermaritzburg",
                                "Pinetown",
                                "Ulundi",
                                "Umlazi",
                            ],
            
               
                   [
                                "Giyani",
                                "Lebowakgomo",
                                "Musina",
                                "Phalaborwa",
                                "Polokwane",
                                "Seshego",
                                "Sibasa",
                                "Thabazimbi",
                            ],
                 
                   
                    [
                                "Emalahleni",
                                "Nelspruit",
                                "Secunda",
                            ],
           
                    
                    [
                                    "Klerksdorp",
                                    "Mahikeng",
                                    "Mmabatho",
                                    "Potchefstroom",
                                    "Rustenburg",
                            ],
                    
                   
                   [
                                "Kimberley",
                                "Kuruman",
                                "Port Nolloth",
                            ],
           
                   
                   [
                                "Bellville",
                                "Cape Town",
                                "Constantia",
                                "George",
                                "Hopefield",
                                "Oudtshoorn",
                                "Paarl",
                                "Simon’s Town",
                                "Stellenbosch",
                                "Swellendam",
                                "Worcester",
                            ],
             ]        


const year = ['2019', '2018']


const subject = [['Mathematics', 'mathematics'], ['Mathematical Literacy','mathematical_literacy' ], ['Life Sciences', 'life_sciences'],
                         ['Physical Sciences', 'physical_sciences'], ['Business Studies', 'business_studies'], ['Accounting', 'accounting'],
                              ['Economics', 'economics'], ['Agricultural Science', 'agricultural_science']]
             





const performance_data = {

                                six_months_data :   {
                                        'total_attempts':0,
                                        'correct_attempts':0,
                                        'mathematics':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                }, 
                                        'mathematical_literacy':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                }, 
                              
                                        'life_sciences':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                } ,
                                        'physical_sciences':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                } ,
                                        'business_studies':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                },
                                        'accounting':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                } ,  
                                        'economics':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                } , 
                                        'agricultural_science':{
                                                        'total_attempts':0,
                                                        'correct_attempts':0,
                                                } ,                                           
                              
                                },
                                three_months_data :  {
                                                'total_attempts':0,
                                                'correct_attempts':0,
                                                'mathematics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                                                'mathematical_literacy':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                              
                                                'life_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'physical_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'business_studies':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        },
                                                'accounting':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,  
                                                'economics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } , 
                                                'agricultural_science':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,                                           
                              
                                },
                                one_month_data :   {
                                                'total_attempts':0,
                                                'correct_attempts':0,
                                                'mathematics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                                                'mathematical_literacy':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                              
                                                'life_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'physical_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'business_studies':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        },
                                                'accounting':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,  
                                                'economics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } , 
                                                'agricultural_science':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,                                           
                              
                                },
                                one_week_data :   {
                                                'total_attempts':0,
                                                'correct_attempts':0,
                                                'mathematics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                                                'mathematical_literacy':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        }, 
                              
                                                'life_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'physical_sciences':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,
                                                'business_studies':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        },
                                                'accounting':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,  
                                                'economics':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } , 
                                                'agricultural_science':{
                                                                'total_attempts':0,
                                                                'correct_attempts':0,
                                                        } ,                                           
                              
                                },
                                one_day_data :   {
                              
                                              'total_attempts':0,
                                              'correct_attempts':0,
                                              'mathematics':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      }, 
                                              'mathematical_literacy':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      }, 
                              
                                              'life_sciences':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      } ,
                                              'physical_sciences':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      } ,
                                              'business_studies':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      },
                                              'accounting':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      } ,  
                                              'economics':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      } , 
                                              'agricultural_science':{
                                                              'total_attempts':0,
                                                              'correct_attempts':0,
                                                      } ,                                    
                              
                              
                              
                              
                              
                                }
                }



export { school_district_list, school_list, city_list, year, subject, performance_data,
      
 };