export default (state, action) => {
 
    if(action.type==="save_user_account_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.user_account=action.payload
        
        return new_state
    }

    if(action.type==="save_user_profile_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.user_profile=action.payload
        
        return new_state
    }

    if(action.type==="save_performance_summary_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.performance_summary=action.payload
        
        return new_state
    }

    if(action.type==="save_database_questions_id_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.database_questions_id=action.payload
        
        return new_state
    }

    
    if(action.type==="save_un_done_questions_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.un_done_questions=action.payload
        
        return new_state
    }

    if(action.type==="save_done_questions_id_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.done_questions_id=action.payload
        
        return new_state
    }

    if(action.type==="change_should_show_question_setup_page"){
        let new_state = Object.assign({}, state, ) 
        new_state.should_show_question_setup_page=action.payload
        
        return new_state
    }


    if(action.type==="change_has_watched_tutorial_video_status"){
        let new_state = Object.assign({}, state, ) 
        new_state.has_watched_tutorial_video=action.payload
        
        return new_state
    }

    if(action.type==="set_convert_account_to_permanent_page_data"){
        let new_state = Object.assign({}, state, ) 
        new_state.show_convert_account_page = action.payload
        
        return new_state
    }

    

   
  
    
    else{ return state}
  
  
};